import type { Level, LoggerOptions } from 'pino';
import { datadogLogger, datadogRum } from './datadog';

const getWriter = (level: Exclude<Level, 'fatal' | 'trace'>) => {
  return (o: unknown) => {
    const { message, ...rest } = o as Record<string, unknown>;
    if (datadogLogger && message) {
      datadogLogger?.[level]?.(message as string, rest);
      const error = rest.error || rest.err;
      if (datadogRum && error) {
        datadogRum.addError(error, rest);
      }
    } else {
      // eslint-disable-next-line no-console
      console[level](rest, message);
    }
  };
};

export const browser: LoggerOptions['browser'] = {
  serialize: true,
  write: {
    trace: getWriter('debug'),
    debug: getWriter('debug'),
    info: getWriter('info'),
    warn: getWriter('warn'),
    error: getWriter('error'),
    fatal: getWriter('error'),
  },
};
