import type { RecursivePartial } from '@pepper/types';
import { Theme } from '@chakra-ui/react';

export const Slider: RecursivePartial<Theme['components']['Slider']> = {
  defaultProps: {
    colorScheme: 'blue',
  },
  baseStyle: ({ colorScheme }: { colorScheme: string }) => ({
    track: {
      height: '0',
      overflow: 'visible',
      bg: 'initial',
      borderColor: 'gray.200',
      borderWidth: '2px',
      borderStyle: 'dashed',
    },
    filledTrack: {
      height: '2px',
      borderColor: `${colorScheme}.500`,
      borderWidth: '2px',
    },
    thumb: {
      bg: `${colorScheme}.500`,
      h: 4,
      w: 4,
      _active: {
        '+ .chakra-slider__marker': {
          display: 'block',
        },
        h: 6,
        w: 6,
      },
      _focus: {
        '+ .chakra-slider__marker': {
          display: 'block',
        },
      },
    },
    container: {
      '.chakra-slider__marker': {
        textAlign: 'center',
        bg: 'white',
        color: 'pepperBlack',
        mt: '-14',
        mx: '-14px',
        p: 2,
        h: '9',
        w: 'max-content',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'column',
        boxShadow: 'md',
        borderRadius: 'md',
      },
    },
  }),
};
