import { GetServerSidePropsContext } from 'next';
import { getCookies, getCookie, setCookies, removeCookies } from 'cookies-next';

const clientHost =
  typeof window !== 'undefined'
    ? window?.location?.host?.split(':')[0]
    : undefined;

export const getDomainFromHost = (serverHost?: string): string | undefined => {
  const host = serverHost || clientHost;
  let domain = host;
  if (host && !host?.startsWith('localhost')) {
    const domainParts = host.split('.');
    if (domainParts.length > 3) {
      // If nested subdomain then store cookie on root subdomain to be shared b/w all
      domain = domainParts.slice(1).join('.');
    } else if (host.endsWith('.localhost') && domainParts.length > 2) {
      // Handle local domains like gks.gfs.localhost
      domain = domainParts.slice(1).join('.');
    }
  }
  return domain;
};

/**
 * Based on local-storage-fallback but with SSR support
 * @see https://github.com/ripeworks/local-storage-fallback/blob/master/src/CookieStorage.js
 */
export class CookieStorage {
  private cookieOptions = {};

  private prefix = 'gks_';

  private ctx?: GetServerSidePropsContext;

  constructor(
    options: {
      path?: string;
      domain?: string;
      maxAge?: number;
      sameSite?: string;
    } = {},
    ctx?: GetServerSidePropsContext,
  ) {
    this.ctx = ctx;
    this.cookieOptions = {
      path: '/',
      ...options,
      domain: options.domain || getDomainFromHost(this.ctx?.req?.headers?.host),
      req: this.ctx?.req,
      res: this.ctx?.res,
    };
  }

  getItem(key: string): string | null {
    return getCookie(this.prefix + key, this.cookieOptions)?.toString() ?? null;
  }

  setItem(key: string, value: string): void {
    setCookies(this.prefix + key, value, this.cookieOptions);
  }

  removeItem(key: string): void {
    removeCookies(this.prefix + key, this.cookieOptions);
  }

  clear(): void {
    const cookies = getCookies(this.cookieOptions);
    // eslint-disable-next-line no-restricted-syntax
    for (const key in cookies) {
      if (key.indexOf(this.prefix) === 0) {
        this.removeItem(key.substring(this.prefix.length));
      }
    }
  }
}
