import type { RecursivePartial } from '@pepper/types';
import { Theme } from '@chakra-ui/react';
import type { PartsStyleInterpolation } from '@chakra-ui/styled-system';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Tag as ChakraTagTheme } from '@chakra-ui/theme/components';

const variants: Record<
  string,
  PartsStyleInterpolation<{ keys: typeof ChakraTagTheme.parts }>
> = {
  // This is the default, used for Pepper Admin - stall schedules and any other tag that conveys positive/negative trends
  subtle: (props) => {
    // @ts-expect-error Chakra types don't match actual exports
    const base = ChakraTagTheme.variants.subtle(props);
    return {
      ...base,
      container: {
        ...base.container,
        borderWidth: 1,
        borderColor:
          props.colorScheme === 'red'
            ? `${props.colorScheme}.200`
            : `${props.colorScheme}.400`,
        color:
          props.colorScheme === 'gray'
            ? `${props.colorScheme}.900`
            : `${props.colorScheme}.600`,
        backgroundColor:
          props.colorScheme === 'red'
            ? `${props.colorScheme}.50`
            : `${props.colorScheme}.100`,
        borderRadius: 'sm',
        my: 0,
        minHeight: '20px',
        fontSize: 12,
        lineHeight: 4,
        textAlign: 'center',
      },
    };
  },
  // This is used for Sales category and status tags
  status: (props) => {
    // @ts-expect-error Chakra types don't match actual exports
    const base = ChakraTagTheme.variants.subtle(props);

    return {
      ...base,
    };
  },
  // This is cooking app specific variant for the buttons on the footer
  pill: {
    container: {
      bg: 'gray.900',
      color: 'white',
      paddingX: '4',
      paddingY: '2',
      size: 'sm',
      borderRadius: '52',
      fontSize: 'md',
      fontWeight: 'bold',
      lineHeight: '6',
    },
  },
};

export const Tag: RecursivePartial<Theme['components']['Tag']> = {
  baseStyle: {
    container: {
      fontWeight: 400,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
  variants,
};
