import React, { useCallback, useContext } from 'react';
import { FormSelect, useForm } from '@deliveryhero/gfs-ui';
import { Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import * as yup from 'yup';
import { useAccess, useGtm } from '../hooks';
import { useFormOptions } from '../hooks/useFormOptions';
import { UserStateContext } from '../providers/userStateProvider/userStateProvider';
import { groupStyles } from './Sidebar/SidebarItem';

const schema = yup
  .object({
    activeCountry: yup.string().required(),
  })
  .required();

export const CountrySelector: React.FC<{
  viewOnly?: boolean;
}> = ({ viewOnly }) => {
  const { pushGtm } = useGtm();
  const { t } = useTranslation();
  const { country, setCountryId } = useContext(UserStateContext);
  const hasAccessToMultipleCountries = useAccess('countries.view', {
    entity: {
      facilityType: 'some',
      countryId: 'many',
    },
  });
  const { options, loading } = useFormOptions({
    countries: true,
    skip: !hasAccessToMultipleCountries,
  });
  const { control } = useForm({
    schema,
  });

  const onChangeCountries = useCallback(
    (selected: string | null) => {
      if (!selected || selected === country._id) {
        return;
      }

      const selectedCountryCode = options.countries?.find(
        (c) => c.value === selected,
      )?.countryCode;
      pushGtm('changed', {
        label: 'Country Selector',
        info: selectedCountryCode || '',
      });
      setCountryId(selected);
      // We want to reset all filter query params as the values will be different for the new country
      window.location.replace(window.location.pathname);
    },
    [country?._id, options, setCountryId, pushGtm],
  );

  if (viewOnly) {
    return (
      <Flex
        minH={9}
        w={9}
        role="group"
        sx={{
          ...groupStyles,
          marginStart: 1.5,
          _hover: undefined,
          cursor: 'default',
        }}
      >
        <Text lineHeight={5} marginStart={2} textTransform="uppercase">
          <strong>{country.countryCode}</strong>
        </Text>
      </Flex>
    );
  }

  return (
    <Flex
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        marginStart: 3.5,
        marginEnd: '18px',
        marginY: 1.5,
        minH: 9,
      }}
    >
      <FormSelect
        data-testid="country-selector"
        label={t('common:labels.country', 'Country')}
        name="activeCountry"
        isLabelInline
        isReadOnly={!hasAccessToMultipleCountries}
        isLoading={loading}
        options={
          options.countries ?? [{ label: country.name, value: country._id }]
        }
        defaultValue={country._id}
        control={control}
        onChange={onChangeCountries}
        menuPosition="fixed"
      />
    </Flex>
  );
};
