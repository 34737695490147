/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useMemo } from 'react';
import type { NextRouter } from 'next/router';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'next-i18next';
import {
  FieldValues,
  SubmitErrorHandler,
  useForm as useReactHookForm,
  UseFormProps as UseReactHookFormProps,
  UseFormReturn as UseReactHookFormReturn,
} from 'react-hook-form';
import type { InferType } from 'yup';
import type { RequiredObjectSchema } from 'yup/lib/object';
import { useToast } from '../useToast';
import { SkipConfirmLeaveHandler, useConfirmLeave } from './useConfirmLeave';
import {
  DefaultValuesProps,
  useDefaultValues,
  useWatchDefaults,
} from './useDefaultValues';

type SubmitHandler<TFieldValues extends FieldValues> = (
  data: TFieldValues,
  event?: React.BaseSyntheticEvent,
  /**
   * Disable confirm leave, can be used before route change
   */
  skipConfirmLeave?: SkipConfirmLeaveHandler,
) => any | Promise<any>;

export type UseFormHandleSubmit<TFieldValues extends FieldValues> = (
  onValid: SubmitHandler<TFieldValues>,
  onInvalid?: SubmitErrorHandler<TFieldValues>,
) => (e?: React.BaseSyntheticEvent) => Promise<void>;

export type UseFormProps<
  TSchema extends RequiredObjectSchema<any, any, any>,
  TContext = any,
> = Omit<
  UseReactHookFormProps<InferType<TSchema>, TContext>,
  'resolver' | 'defaultValues'
> &
  DefaultValuesProps<TSchema> & {
    watchDefaults?: boolean;
    /**
     * Asks user confirmation when user tries to close tab or clicks "back" if form is dirty.
     * Please provide router from `useRouter()` if you use it in your application (SPA app only)
     */
    confirmLeave?: { warningText?: string; router?: NextRouter };
    initialValues?: 'router';
  };

export type UseFormReturn<
  TFieldValues extends FieldValues,
  TContext = any,
> = UseReactHookFormReturn<TFieldValues, TContext> & {
  skipConfirmLeave: () => void;
  handleSubmit: UseFormHandleSubmit<TFieldValues>;
  isLoading: boolean;
};

/**
 * @see https://react-hook-form.com/advanced-usage/#CustomHookwithResolver
 */
export const useForm = <
  TSchema extends RequiredObjectSchema<any, any, any>,
  TContext = any,
  TFieldValues extends FieldValues = InferType<TSchema>,
>({
  schema,
  defaultValues,
  watchDefaults = true,
  router,
  confirmLeave,
  initialValues: initialValuesOption,
  ...options
}: UseFormProps<TSchema, TContext>): UseFormReturn<TFieldValues, TContext> => {
  const toast = useToast();
  const { t } = useTranslation();
  const resolver = useMemo(
    () => yupResolver(schema, { stripUnknown: true }),
    [schema],
  );

  const { defaultValues: finalDefaultValues, initialValues } = useDefaultValues(
    {
      router,
      schema,
      defaultValues,
      initialValuesOption,
    },
  );

  const { handleSubmit, formState, reset, ...rest } = useReactHookForm<
    TFieldValues,
    TContext
  >({
    ...options,
    defaultValues: finalDefaultValues,
    resolver,
  });

  const isLoading = useWatchDefaults({
    reset,
    router,
    initialValues,
    defaultValues: finalDefaultValues,
    watchDefaults: watchDefaults && !!defaultValues,
    resetOptions: options.resetOptions,
  });

  const { isDirty, isSubmitSuccessful } = formState;
  const skipConfirmLeaveHandler = useConfirmLeave(
    !!confirmLeave && isDirty && !isSubmitSuccessful,
    confirmLeave?.warningText,
    confirmLeave?.router,
  );

  const handleSubmitWrapper = useCallback<UseFormHandleSubmit<TFieldValues>>(
    (onValid, ...args) => {
      return handleSubmit(async (...onValidArgs) => {
        try {
          await onValid(...onValidArgs, skipConfirmLeaveHandler);
        } catch (err) {
          toast({
            status: 'error',
            title: t('common:form.submit_error', 'Error while submitting'),
            description: (err as Error).message,
          });
        }
      }, ...args);
    },
    [handleSubmit, toast, t, skipConfirmLeaveHandler],
  );

  return {
    ...rest,
    formState,
    reset,
    isLoading,
    // @ts-ignore remove after order-management has TS strict mode enabled
    handleSubmit: handleSubmitWrapper,
    skipConfirmLeave: skipConfirmLeaveHandler,
  };
};
