import { NextRouter } from 'next/router';
import { urlSearchParamsToUrl } from '@pepper/client/helpers';
import {
  flattenKeys,
  getParamsFromRouter,
  updatePrefixedParamsWithData,
} from '@deliveryhero/gfs-ui';
import { get } from 'lodash';

export const MODAL_URL_PARAMS_PREFIX = 'm_';

export const getFlatModalUrlParams = (
  modalId: string,
  additionalParams: Record<string, unknown>,
) => {
  const modalUrlParams = {
    modal: {
      id: modalId,
      ...additionalParams,
    },
  };

  return Object.fromEntries(
    flattenKeys(modalUrlParams).map((key: string) => {
      const val = get(modalUrlParams, key);
      return [key, val];
    }),
  );
};

export const unsafeParsePrefixedParam = (param: string | null) =>
  JSON.parse(param || 'null');

export const getRawModalParamKey = (key: string) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, ...rest] = key.split('.');
  return rest.join('.');
};

export const stringifyModalParamsToJSON = (urlParams: URLSearchParams) => {
  const safeAdditionalParams = new URLSearchParams();
  urlParams.forEach((value, key) => {
    if (key.startsWith(MODAL_URL_PARAMS_PREFIX)) {
      const json = JSON.stringify(value);

      safeAdditionalParams.set(key, json);
    } else {
      safeAdditionalParams.set(key, value);
    }
  });

  return safeAdditionalParams;
};

export const getModalParams = <Data extends Record<string, unknown>>(
  routerParams: URLSearchParams,
  onParseError: () => void,
  refineKey?: (key: string) => string,
): Data => {
  const modalParams = {} as Data;
  try {
    routerParams.forEach((value, key) => {
      if (key.startsWith(MODAL_URL_PARAMS_PREFIX)) {
        const refinedKey = refineKey ? refineKey(key) : key;
        (modalParams as Record<string, unknown>)[refinedKey] =
          unsafeParsePrefixedParam(value);
      }
    });
    return modalParams;
  } catch (error) {
    onParseError();
    return {} as Data;
  }
};

export const getModalUrlWithUpdatedParams = (
  paramsData: Record<string, unknown>,
  router: NextRouter,
  modalId: string,
) => {
  const flatModalUrlParams = getFlatModalUrlParams(modalId, paramsData);
  const params = getParamsFromRouter(router);
  const updatedParams = updatePrefixedParamsWithData(
    params,
    flatModalUrlParams,
    MODAL_URL_PARAMS_PREFIX,
  );
  return urlSearchParamsToUrl(updatedParams);
};
