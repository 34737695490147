import type { RecursivePartial } from '@pepper/types';
import { CSSObject, Theme } from '@chakra-ui/react';
import type {
  PartsStyleFunction,
  StyleFunctionProps,
} from '@chakra-ui/styled-system';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Tabs as ChakraTabsTheme } from '@chakra-ui/theme/components';

export const Tabs: RecursivePartial<Theme['components']['Tabs']> = {
  baseStyle: (
    props: StyleFunctionProps,
  ): ReturnType<PartsStyleFunction<{ keys: typeof ChakraTabsTheme.parts }>> => {
    const { orientation } = props;

    return {
      root: ((): CSSObject | undefined => {
        if (orientation === 'vertical') {
          return {
            // To make tabs flush with the drawer
            ml: '-22px',
          };
        }
      })(),
      tabpanel: ((): CSSObject | undefined => {
        if (orientation === 'vertical') {
          return {
            mt: 4,
            pt: 0,
            ps: 3,
            pe: 0,
          };
        }
      })(),
      tablist: ((): CSSObject | undefined => {
        if (orientation === 'vertical') {
          return {
            mt: 4,
            width: '240px',
            minWidth: '240px',
          };
        }
      })(),
      tab: ((): CSSObject | undefined => {
        const common: CSSObject = {
          _selected: {
            fontWeight: 600,
          },
        };
        if (orientation === 'vertical') {
          return {
            ...common,
            h: '12',
            justifyContent: 'start',
          };
        }

        return common;
      })(),
    };
  },
  variants: {
    line: (props: StyleFunctionProps) => {
      const { orientation, align = 'start', colorScheme } = props;
      return {
        tablist: ((): CSSObject | undefined => {
          if (orientation === 'vertical') {
            return {
              borderStart: 'none',
            };
          }
        })(),
        tab: ((): CSSObject | undefined => {
          const common: CSSObject = {
            fontSize: '14px',
            lineHeight: 5,
          };
          if (orientation === 'vertical') {
            return {
              ...common,
              p: 1.5,
              '> div': {
                p: 2,
                w: '100%',
                textAlign: align,
              },
              _selected: {
                color: `${colorScheme}.500`,
                '> div': {
                  borderRadius: 'md',
                  backgroundColor: 'gray.200',
                },
              },
            };
          }
          return common;
        })(),
      };
    },
  },
};
