import type { RecursivePartial } from '@pepper/types';
import { Theme } from '@chakra-ui/react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Button as ChakraButtonTheme } from '@chakra-ui/theme/components';

const pepperLinkVariant: Theme['components']['Button']['variants']['unstyled'] =
  {
    paddingInlineStart: 0,
    paddingInlineEnd: 0,
  };

export const Button: RecursivePartial<Theme['components']['Button']> = {
  baseStyle: {
    p: 0,
  },
  variants: {
    link: {
      ...pepperLinkVariant,
    },
    // FIXME: Add custom variant to types
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    cancel: {
      ...ChakraButtonTheme.variants.link,
      ...pepperLinkVariant,
      color: 'gray.900',
      mr: 3,
      paddingX: '16px',
    },
    outline: ({ colorScheme }: { colorScheme?: string }) => {
      const isRegular =
        !colorScheme ||
        [
          'black',
          'blackAlpha',
          'gray',
          // FIXME: Remove this and add colorScheme="gray" to places which are currently using default color scheme
          'blue',
        ].includes(colorScheme);

      return {
        background: 'white',
        color: isRegular ? 'pepperBlack' : `${colorScheme}.500`,
        borderColor: isRegular ? 'gray.500' : `${colorScheme}.500`,
        _hover: {
          background: 'gray.100',
        },
        _pressed: {
          background: 'gray.200',
        },
        _active: {
          background: 'gray.200',
        },
        _focus: {
          border: '3px solid blue.300',
        },
      };
    },
  },
};
