import { useMemo } from 'react';
import { Auth } from '@pepper/common';
import { useUser } from './useUser';

/**
 * See the docs of the underlying [`Auth.hasAccess`](../../../common/src/auth/access.ts) function for examples and different scenarios
 */
export function useAccess(
  permissions: Auth.AccessToCheck | Auth.AccessToCheck[],
  opts?: Auth.HasAccessOptions,
): boolean {
  const user = useUser();

  return useMemo(() => {
    if (Array.isArray(permissions)) {
      return Auth.hasAccess(user, permissions, opts);
    }
    return Auth.hasAccess(user, [permissions], opts);
  }, [user, permissions, opts]);
}
