import { useContext } from 'react';
import { UserDetailsFragment } from '../../accounts';
import { UserStateContext } from '../../providers/userStateProvider/userStateProvider';

export function useUser(): Omit<UserDetailsFragment, 'impersonator'> {
  const { user } = useContext(UserStateContext);

  // This will not be null since auth check at config level will handle that scenario
  // And SSR preloads the data before rendering
  // And client state adds default dummy user so error pages and other static pages can render without errors
  return user;
}

export const useUserContext = () => {
  const { user, impersonateUser, isImpersonated, logout } =
    useContext(UserStateContext);

  return {
    user,
    impersonateUser,
    isImpersonated,
    logout,
    impersonator: user?.impersonator,
  };
};
