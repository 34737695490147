/* eslint-disable */
/* This is a generated file (see scripts/schema.js), don't edit manually */
export const partnerSchemaFields = [
  {
    "name": "id",
    "type": "STRING",
    "description": "Pepper Partner ID"
  },
  {
    "name": "name",
    "type": "STRING",
    "description": "Name of the partner"
  },
  {
    "name": "facilityType",
    "type": "STRING",
    "description": "Enum: 'Store'/'Kitchen'"
  },
  {
    "name": "status",
    "type": "STRING",
    "description": "Enum: 'ACTIVE'/'INACTIVE'"
  },
  {
    "name": "events",
    "type": "RECORD",
    "mode": "REPEATED",
    "fields": [
      {
        "name": "type",
        "type": "STRING",
        "description": "Enum: 'Onboarding'/'Offboarding'"
      },
      {
        "name": "stallId",
        "type": "STRING",
        "description": "Pepper Stall ID"
      },
      {
        "mode": "NULLABLE",
        "name": "comment",
        "type": "STRING",
        "description": "Comment"
      },
      {
        "name": "reasons",
        "type": "STRING",
        "mode": "REPEATED",
        "description": "Reasons for Offboarding. Enum:'ChurnInOnboarding'/CuisineNotFit'/'ForceChurn'/'NegativePnl/'PartnerHasOperationalIssue'/'PartnerOpeningOwnStore'/'PartnerMovingFacility'/'PartnerMovingStall'/'SwitchingStallWrongOnboarding'/'AccidentalOffboarding'/'Other'. Notes for BI team: AccidentalOffboarding is a case when you can ignore the offboarding and next onboarding event. SwitchingStallWrongOnboarding is a case when you can ignore prev onboarding and the current offboarding, the next onboarding event should be considered as a correct one."
      },
      {
        "name": "createdAt",
        "type": "TIMESTAMP",
        "description": "Time of event"
      }
    ]
  },
  {
    "name": "assignees",
    "type": "RECORD",
    "mode": "REPEATED",
    "fields": [
      {
        "name": "type",
        "type": "STRING",
        "description": "Enum: 'GrowthManager'"
      },
      {
        "name": "countryCode",
        "type": "STRING",
        "description": "The country where this assignee is responsible for handling growth"
      },
      {
        "name": "userId",
        "type": "STRING",
        "description": "The user assigned to partner"
      }
    ]
  },
  {
    "name": "createdAt",
    "type": "TIMESTAMP",
    "description": "When partner was added in Pepper"
  },
  {
    "name": "updatedAt",
    "type": "TIMESTAMP",
    "description": "When partner was last updated in Pepper. Is the offboarding date if status is INACTIVE"
  },
  {
    "name": "exportedAt",
    "type": "TIMESTAMP",
    "description": "When partner was last exported from Pepper"
  }
] as const;
export type PartnerSchema = typeof partnerSchemaFields;