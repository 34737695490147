import { OrderStatus } from '@pepper/common';
import { logger } from '@pepper/logger';
import { CustomTheme } from '@pepper/theme';
import { RecursivePartial } from '@pepper/types';
import { Order } from '@pepper/types/graphql';
import { isNaN } from 'lodash';
import moment from 'moment';
import { getTargetTimeDifference } from './datetime';

// Gets the order remaining preparation time in percentage
// based on the time the order was expected and the target time limit for the order to be ready
export function getOrderRemainingPrepTimePercentage(
  targetTime: Date | string,
  orderAcceptedAt?: Date | string,
  defaultAvailableTime = 600,
) {
  const target = new Date(targetTime);
  const acceptedAt = orderAcceptedAt ? new Date(orderAcceptedAt) : undefined;

  // return 100% of remaining time left not to prioritize orders with invalid timestamps
  if (isNaN(target.getTime()) || (acceptedAt && isNaN(acceptedAt.getTime()))) {
    logger.error(
      {
        err: 'Invalid targetTime or orderAcceptedAt',
      },
      'getOrderRemainingPrepTimePercentage',
    );
    return 100;
  }

  const { diffInSeconds: remainingTime } = getTargetTimeDifference(target);
  const { diffInSeconds: availableTime } = getTargetTimeDifference(
    target,
    acceptedAt,
  );
  return acceptedAt
    ? (remainingTime / availableTime) * 100
    : (remainingTime / defaultAvailableTime) * 100;
}

/**
 * Gets card color name, value and contrasting text color based on order status, rider nearby, and time remaining
 * @see https://www.figma.com/file/EscFuvpfddqEMuFnF5O01c/Visibility-on-Rider%E2%80%99s-location?node-id=438%3A4513&t=knA324JkZzH7crNV-0
 */
export const getPriorityColorsByOrderStatus = (
  theme: CustomTheme,
  status: OrderStatus,
  showRider: boolean,
  showCustomer: boolean,
  targetTime?: Date | string | null,
  acceptedAt?: Date,
  vendorDefaultPrepTime?: number,
  customColorMap: Record<string, string> = {},
): {
  name: string;
  color: string;
  textColor?: string;
} => {
  if (status === OrderStatus.PickedUp || status === OrderStatus.Delivered) {
    return {
      name: 'complete',
      color: customColorMap?.COMPLETE ?? theme.colors.green[700],
      textColor: 'white',
    };
  }

  if (status === OrderStatus.Received) {
    return {
      name: 'complete',
      color: customColorMap?.INFO ?? theme.colors.cyan[300],
    };
  }

  if (status === OrderStatus.ReadyForPickup) {
    return {
      name: 'green',
      color: customColorMap?.SUCCESS ?? theme.colors.green[400],
    };
  }

  if (!targetTime) {
    return {
      name: 'gray',
      color: customColorMap?.NEUTRAL ?? theme.colors.gray[200],
    };
  }
  // Consider default of 10 mins
  const percent = getOrderRemainingPrepTimePercentage(
    targetTime,
    acceptedAt,
    vendorDefaultPrepTime,
  );

  // for pickup
  if (showCustomer) {
    if (percent <= 33) {
      return {
        name: 'red',
        color: customColorMap?.DANGER ?? theme.colors.red[300],
        textColor: 'white',
      };
    }
    if (percent < 67) {
      return {
        name: 'orange',
        color: customColorMap?.WARN ?? theme.colors.orange[500],
      };
    }
    return {
      name: 'gray',
      color: customColorMap?.NEUTRAL ?? theme.colors.gray[200],
    };
  }

  // for platform_delivery
  if (showRider) {
    if (percent < 67) {
      return {
        name: 'red',
        color: customColorMap?.DANGER ?? theme.colors.red[300],
        textColor: 'white',
      };
    }
    return {
      name: 'orange',
      color: customColorMap?.WARN ?? theme.colors.orange[500],
    };
  }

  // TODO: handle case when delayed order headers should be displayed in red

  if (percent <= 33) {
    return {
      name: 'orange',
      color: customColorMap?.WARN ?? theme.colors.orange[500],
    };
  }
  return {
    name: 'gray',
    color: customColorMap?.NEUTRAL ?? theme.colors.gray[200],
  };
};

const RIDER_NEARBY_IN_MINS = 3;

export const getOrderTimestampsForUrgencyPrioritization = (
  order: RecursivePartial<Order>,
) => {
  const acceptedAt = order.timestamps?.acceptedAt
    ? moment(order.timestamps?.acceptedAt).toDate()
    : undefined;
  const estimatedPickupAt = order.deliveries?.[0]?.estimatedPickupAt;
  const isCustomerPickup =
    order.content?.delivery?.provider === 'pickup' || false;
  const isRiderNearby = estimatedPickupAt
    ? // diff rounds down so comparing with seconds
      moment(estimatedPickupAt).diff(moment(), 'seconds') <=
      RIDER_NEARBY_IN_MINS * 60
    : false;

  return {
    acceptedAt,
    estimatedPickupAt,
    isCustomerPickup,
    isRiderNearby,
  };
};

export const getPrepTime = <
  T extends Pick<Order, 'preparationTimeEstimate' | 'preparedAt'>,
>(
  order: T,
) => {
  return order.preparationTimeEstimate ?? order.preparedAt ?? undefined;
};

const reduceGroupToMaxWaitTimeOrder = <
  T extends Pick<Order, 'preparationTimeEstimate' | 'preparedAt'>,
>(
  maxWaitTimeOrder: T,
  ord: T,
) => {
  const orderPrepTime = getPrepTime(ord);
  if (
    orderPrepTime &&
    moment(orderPrepTime).isAfter(getPrepTime(maxWaitTimeOrder))
  ) {
    return ord;
  }

  return maxWaitTimeOrder;
};

export const getOrderFromGroupWithMaxPrepTime = <
  T extends Pick<Order, 'status' | 'preparationTimeEstimate'>,
>(
  group: T[],
) => {
  const inPreparationOrders = group.filter(
    (order) => order.status === OrderStatus.InPreparation,
  );
  if (!inPreparationOrders.length) {
    return group.reduce(reduceGroupToMaxWaitTimeOrder, group[0]);
  }

  return inPreparationOrders.reduce(
    reduceGroupToMaxWaitTimeOrder,
    inPreparationOrders[0],
  );
};
