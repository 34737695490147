import React from 'react';
import { Auth } from '@pepper/common';
import { AlertMessage } from '@deliveryhero/gfs-ui';
import { useTranslation } from 'next-i18next';
import { useAccess } from '../hooks/useAccess';

export interface Props
  extends Omit<Auth.HasAccessOptions, 'entity'>,
    Partial<Auth.AccessEntity> {
  children:
    | ((props: { hasAccess: boolean }) => JSX.Element | null)
    | React.ReactNode;
  /**
   * Check if user has access to these permission (if array then checks for either one of them)
   */
  permissions: Auth.AccessToCheck | Auth.AccessToCheck[];
  /**
   * By default this component shows nothing if user doesn't have access
   * Show a fallback "No Access" Alert if `true` else shows the fallback element itself if passed
   */
  fallback?: JSX.Element | true | null;
}

/**
 * Check if user has the specified permissions.
 * The Access component should be used as a wrapper component for any element/component that needs to be hidden or disabled based on user's permissions.
 *
 * See the docs of the underlying [`Auth.hasAccess`](../../../common/src/auth/access.ts) function for examples and different scenarios
 *
 * @example Wrap component with access check
 * ```tsx
 *
 * const ArchiveBrand = ({ brand }) => { return <>Render Something Here</> };
 *
 * const ArchiveBrandWithAccess = ({ brand }) => {
 *  return (
 *    <Access
 *     permissions="brands.manage"
 *     facilityType={brand?.country?.facilityType}
 *     countryId={brand?.countryId}
 *    >
 *      <ArchiveBrand brand={brand} />
 *    </Access>
 *   );
 * }
 *
 * export { ArchiveBrandWithAccess as ArchiveBrand };
 *
 * ```
 *
 * @example Hide something (like a column header) based on user's permissions
 * ```tsx
 * <Access permissions={["users.edit", "users.manage"]}>
 *   <Th>
 *     {t('user.actions')}
 *   </Th>
 * </Access>
 * ```
 *
 * @example Render different things based on user's permissions
 * ```tsx
 * <Access permissions="users.manage">
 *   {({ hasAccess }) => (
 *     <Th minW={hasAccess ? '200px' : '100px'} w={hasAccess ? '200px' : '100px'}>
 *       {t('user.actions')}
 *     </Th>
 *   )}
 * </Access>
 * ```
 */
export const Access: React.FC<Props> = ({
  permissions,
  children,
  fallback = null,
  selfId,
  ...entity
}) => {
  const { t } = useTranslation();
  const check = useAccess(permissions, {
    selfId,
    entity: {
      facilityType: null,
      countryId: null,
      facilityId: null,
      partnerId: null,
      ...entity,
    },
  });

  if (typeof children === 'function') {
    return children({ hasAccess: check });
  }

  if (check) {
    return <>{children}</>;
  }

  if (fallback === true) {
    return (
      <AlertMessage status="error">
        {t('common:access.unauthorized_error')}
      </AlertMessage>
    );
  }
  return fallback;
};
