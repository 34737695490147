import React from 'react';
import { useRouter } from 'next/router';
import { Box, Divider, Flex, Image, Tooltip } from '@chakra-ui/react';
import { motion, MotionValue } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import { withGtm } from '../../hooks/gtm';
import { CountrySelector } from '../CountrySelector';
import { AppSwitcher } from './AppSwitcher';
import { AppVersion } from './AppVersion';
import { ProfileLinks } from './ProfileLink';
import {
  SidebarItem,
  SectionTitle,
  groupStyles,
  sidebarItemAnimations,
} from './SidebarItem';

type TFunction = ReturnType<typeof useTranslation>['t'];

export type ISidebarSectionItems = {
  name: string | (({ t }: { t: TFunction }) => string);
  icon: {
    normal: string;
    active?: string;
  };
} & (
  | { href: string; isActive?: (pathname: string) => boolean }
  | { onClick: () => void; isActive: (pathname: string) => boolean }
);

export interface ISidebarSection<Extra = unknown> {
  key: string;
  title?: string | (({ t }: { t: TFunction }) => string);
  items: (ISidebarSectionItems & Extra)[];
}

export interface SidebarProps {
  onToggle: () => void;
  isOpen: boolean;
  width: number;
  motionWidth: MotionValue<number>;
  sections: ISidebarSection[];
  showCountrySelector?: boolean;
}

const Sidebar = ({
  isOpen,
  onToggle,
  width,
  motionWidth,
  sections,
  showCountrySelector,
}: SidebarProps) => {
  const { t } = useTranslation();
  const { pathname } = useRouter();

  return (
    <Box
      as={motion.nav}
      initial={{ width: 48 }}
      animate={{ width: motionWidth.get() }}
      display="flex"
      bg="gray.100"
      borderRight="1px"
      borderRightColor="gray.400"
      zIndex="overlay"
      pos="sticky"
      top="0"
      left="0"
      h="100vh"
      flexDirection="column"
    >
      <AppSwitcher isOpen={isOpen} />
      <Divider borderColor="gray.400" />
      {sections.map(({ key, title, items: sectionItems }) => {
        const sectionName = typeof title === 'function' ? title({ t }) : title;

        return (
          <Flex
            as="section"
            direction="column"
            mt={sectionName ? undefined : 4}
            key={key}
          >
            {sectionName ? (
              <SectionTitle name={sectionName} isOpen={isOpen} />
            ) : null}
            {sectionItems.map((item) => {
              const isActive =
                'href' in item
                  ? item.isActive?.(pathname) ?? pathname.startsWith(item.href)
                  : item.isActive(pathname);

              const name =
                typeof item.name === 'string' ? item.name : item.name({ t });

              return (
                <SidebarItem
                  key={name}
                  {...item}
                  name={name}
                  isActive={isActive}
                  isOpen={isOpen}
                />
              );
            })}
          </Flex>
        );
      })}
      <Box h="inherit" marginY="auto" />
      <AppVersion isOpen={isOpen} />
      <ProfileLinks isOpen={isOpen} />
      {showCountrySelector && <CountrySelector viewOnly={!isOpen} />}
      <Tooltip
        label={isOpen ? '' : t('common:sidebar.expand')}
        placement="right"
        offset={[0, 24]}
      >
        <Flex
          as={motion.div}
          marginBottom={4}
          direction="column"
          data-testid="toggle-box"
          variants={sidebarItemAnimations}
          initial="close"
          animate={isOpen ? 'open' : 'close'}
          sx={{
            flex: 1,
            justifyContent: 'flex-end',
            w: isOpen ? undefined : 9,
            ...groupStyles,
            marginStart: 1.5,
          }}
          onClick={onToggle}
        >
          <Image
            as={motion.img}
            margin={2}
            initial={{
              marginInlineStart: '8px',
              rotate: isOpen ? 180 : 0,
            }}
            animate={{
              marginInlineStart: `${width - 48 + 8}px`,
              rotate: isOpen ? 180 : 0,
            }}
            src="/shared/icons/open.svg"
            alt="Sidebar Toggle"
          />
        </Flex>
      </Tooltip>
    </Box>
  );
};

const SidebarWithGtm = withGtm(Sidebar, { category: 'sidebar' });
export { SidebarWithGtm as Sidebar };
