import type { RecursivePartial } from '@pepper/types';
import { Theme } from '@chakra-ui/react';
import { Input } from './Input';

export const NumberInput: RecursivePartial<Theme['components']['NumberInput']> =
  {
    baseStyle: {
      field: {
        ...Input.baseStyle?.field,
      },
    },
    variants: {
      outline: {
        field: {
          // @ts-expect-error type mismatches
          ...Input.variants?.outline?.field,
        },
      },
    },
    sizes: {
      sm: {
        field: {
          ...Input.sizes?.sm?.field,
        },
      },
    },
    defaultProps: {
      size: 'sm',
    },
  };
