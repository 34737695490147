import { GetServerSidePropsContext } from 'next';
import { logger } from '@pepper/logger';
import { AccountsClient } from '@accounts/client';
import { AccountsClientPassword } from '@accounts/client-password';
import GraphQLClient from '@accounts/graphql-client';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { getDomainFromHost, CookieStorage } from './cookieStorage';
import { UserFieldsFragmentDoc } from './userFields.fragment.generated';

const domain = getDomainFromHost();

export const clearCookies = (reason: string): void => {
  const clientCookieStorage = new CookieStorage({
    maxAge: 604800, // 7 days
    sameSite: 'strict',
    domain,
  });
  logger.debug({ reason }, 'clear cookies running');
  clientCookieStorage.removeItem('accounts:accessToken');
  clientCookieStorage.removeItem('accounts:refreshToken');
};

let accountsClient: AccountsClient;

let accountsClientPassword: AccountsClientPassword;

export function initAccounts(
  graphQLClient: ApolloClient<NormalizedCacheObject>,
  ctx?: GetServerSidePropsContext,
): {
  accountsClient: AccountsClient;
  accountsClientPassword: AccountsClientPassword;
} {
  const isSSR = typeof window === 'undefined';
  // Reuse existing client for client side
  if (!isSSR && accountsClient && accountsClientPassword) {
    return {
      accountsClient,
      accountsClientPassword,
    };
  }

  const accountsGraphQL = new GraphQLClient({
    graphQLClient,
    userFieldsFragment: UserFieldsFragmentDoc,
  });

  const cookieStorage = new CookieStorage(
    {
      maxAge: 604800, // 7 days
      sameSite: 'lax',
      domain,
    },
    ctx,
  );

  const _accountsClient = new AccountsClient(
    {
      tokenStorage: cookieStorage,
    },
    accountsGraphQL,
  );
  const _accountsClientPassword = new AccountsClientPassword(_accountsClient);

  // Cache the Accounts Client once in the client
  if (!isSSR) {
    accountsClient = _accountsClient;
    accountsClientPassword = _accountsClientPassword;
  }

  return {
    accountsClient: _accountsClient,
    accountsClientPassword: _accountsClientPassword,
  };
}
