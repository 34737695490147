import { useMemo } from 'react';
import { GetServerSidePropsContext } from 'next';
import { NormalizedCacheObject } from '@apollo/client';
import merge from 'deepmerge';
import isEqual from 'lodash/isEqual';
import { APOLLO_STATE_PROP_NAME } from '../constants';
import { createApolloClient, CustomApolloClient } from './apolloClient';

let apolloClient: CustomApolloClient;

export type ApolloClientContext = GetServerSidePropsContext & {
  apolloClient?: CustomApolloClient;
};

/**
 * @see https://github.com/vercel/next.js/blob/7b3cce3f94ef88379f037bf02975996d3cbebaed/examples/with-apollo/lib/apolloClient.js
 */
export const getApolloClient = (
  ctx?: ApolloClientContext,
  initialState: NormalizedCacheObject | null = null,
): CustomApolloClient => {
  const isSSR = typeof window === 'undefined';

  // Request level client for SSR and global client instance otherwise
  const _apolloClient =
    (isSSR ? ctx?.apolloClient : apolloClient) ?? createApolloClient(ctx);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray: unknown[], sourceArray: unknown[]) => [
        ...sourceArray,
        ...destinationArray.filter((d: unknown) =>
          sourceArray.every((s: unknown) => !isEqual(d, s)),
        ),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }

  // Cache the Client at the request level for SSR
  if (ctx) {
    if (!ctx.apolloClient) {
      ctx.apolloClient = _apolloClient;
    }
  }
  // Create the Apollo Client once in the client
  else if (!apolloClient && !isSSR) {
    apolloClient = _apolloClient;
  }

  return _apolloClient;
};

export function useApollo(
  pageProps: Record<string, unknown>,
): CustomApolloClient {
  const state = pageProps?.[APOLLO_STATE_PROP_NAME] as NormalizedCacheObject;
  const store = useMemo(() => getApolloClient(undefined, state), [state]);
  return store;
}
