import { useEffect, useMemo, useRef } from 'react';
import { Auth } from '@pepper/common';
import { logger } from '@pepper/logger';
import camelCase from 'lodash/camelCase';
import upperFirst from 'lodash/upperFirst';
import TagManager from 'react-gtm-module';
import { config } from '../../config';
import { useHotjar } from '../hotjar';
import { useLocale } from '../useLocale';
import { usePageConfig } from '../usePageConfig';
import { useUserContext } from '../useUser';
import { DATA_LAYER_NAME } from './gtm';

/**
 * Initialize GTM and set the dataLayer.
 */
export const useInitGtm = (
  gtmId: string,
  appDataLayer?: Record<string, string | undefined>,
): void => {
  const isFirstRun = useRef(true);
  const { locale } = useLocale();
  const [{ gtm, title }] = usePageConfig();
  const { user, impersonator, isImpersonated } = useUserContext();

  const { pageTitle = title, extra } = gtm ?? {};
  const service = process.env.SERVICE_NAME || undefined;

  const dataLayer = useMemo(
    () => ({
      locale,
      service,
      eventType: undefined,
      ...appDataLayer,
      ...extra,
      // page attributes
      pageTitle,
      // user attributes
      userId: user?._id || undefined,
      userLabel: user?.access.label || undefined,
      // Backwards compatibility
      userRole:
        (user?.access.label
          ? Auth.getOldRole(
              upperFirst(camelCase(user?.access.label)) as Auth.UserRole,
            )
          : undefined) || undefined,
      userIsImpersonated: isImpersonated,
      userImpersonatorId: impersonator?.data?.userId || undefined,
    }),
    [
      extra,
      user,
      impersonator,
      isImpersonated,
      locale,
      pageTitle,
      appDataLayer,
      service,
    ],
  );

  useEffect(() => {
    if (!gtmId) {
      if (config.APP_ENV !== 'local') {
        logger.warn('No GTM id set in useInitGtm. GTM will not be initialized');
      }
      return;
    }

    // initialize once
    if (isFirstRun.current) {
      isFirstRun.current = false;
      TagManager.initialize({
        gtmId,
        dataLayerName: DATA_LAYER_NAME,
        dataLayer: {
          event: 'pageview',
          ...dataLayer,
        },
      });
      /**
       * This is needed since FwF supports only `dataLayer`
       * @see https://github.com/deliveryhero/fwf-events-tracker-js/blob/8e6d97f605b3d3e55e56b7d776e836feb4510660/src/GTMDataLayer/FWFGTMDataLayerEventsTracker.ts#L29
       */
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      globalThis.dataLayer = globalThis[DATA_LAYER_NAME];
    } else {
      // Update for user/facility change
      TagManager.dataLayer({
        dataLayerName: DATA_LAYER_NAME,
        dataLayer,
      });
    }
  }, [dataLayer, gtmId]);

  useHotjar({ ...appDataLayer, ...extra });
};
