import { getDomainFromHost } from '../accounts/cookieStorage';
import { common, REDIRECT_KEYS } from './common';
import { config as development } from './development';
import { config as env } from './env';
import { config as production } from './production';
import { config as staging } from './staging';

// Set according to host in client and according to env in SSR and local
const domain = getDomainFromHost();

// eslint-disable-next-line import/no-mutable-exports
let config = env;

if (domain === 'pepper.deliveryhero.io') {
  config = production;
} else if (domain === 'staging.honestfoodtech.com') {
  config = staging;
} else if (domain === 'dev.honestfoodtech.com') {
  config = development;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore custom global
globalThis.APP_ENV = config.APP_ENV;

const exportedConfig = { ...common, ...config };

export { exportedConfig as config, REDIRECT_KEYS };
