import React, { useCallback, useContext } from 'react';
import { FormSelect, useForm } from '@deliveryhero/gfs-ui';
import { Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import * as yup from 'yup';
import { useAccess, useFormOptions, useGtm } from '../hooks';
import { UserStateContext } from '../providers/userStateProvider/userStateProvider';

const schema = yup
  .object({
    activeFacility: yup.string().required(),
  })
  .required();

export const FacilitySelector: React.FC<{
  viewOnly?: boolean;
}> = ({ viewOnly }) => {
  const { pushGtm } = useGtm();
  const { t } = useTranslation();
  const { facility, setFacilityId } = useContext(UserStateContext);
  const hasAccessToMultipleFacilities = useAccess('facilities.view', {
    entity: {
      facilityType: 'some',
      countryId: 'some',
      facilityId: 'many',
    },
  });
  const { options, loading } = useFormOptions({
    facilities: true,
    skip: !hasAccessToMultipleFacilities,
  });
  const { control } = useForm({
    schema,
  });

  const onChangeFacilities = useCallback(
    (selected: string | null) => {
      if (!selected || selected === facility?._id) {
        return;
      }

      const selectedFacility = options.facilities?.find(
        (c) => c.value === selected,
      );
      pushGtm('changed', {
        label: 'Facility Selector',
        id: selectedFacility?._id,
        entity: 'Facility',
        info: selectedFacility?.name || '',
      });
      setFacilityId(selected);
    },
    [facility?._id, options, setFacilityId, pushGtm],
  );

  if (!hasAccessToMultipleFacilities) {
    return null;
  }

  return (
    <Flex
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        marginX: 5,
        marginY: 1.5,
        minH: 8,
      }}
    >
      {viewOnly ? (
        <Text textTransform="uppercase">
          <strong>{facility?.name}</strong>
        </Text>
      ) : (
        <FormSelect
          data-testid="facility-selector"
          label={t('common:labels.facility', 'Facility')}
          // FIXME: remove aria-label once we find a fix to deal with the e2e dropdown
          aria-label={t('common:labels.facility')}
          name="activeFacility"
          isLabelInline
          isLoading={loading}
          options={options.facilities}
          defaultValue={facility?._id}
          control={control}
          onChange={onChangeFacilities}
          menuPosition="fixed"
        />
      )}
    </Flex>
  );
};
