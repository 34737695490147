import type { RecursivePartial } from '@pepper/types';
import { Theme } from '@chakra-ui/react';
import type { CustomTheme } from '../theme';

export const Drawer: RecursivePartial<Theme['components']['Drawer']> = {
  sizes: {
    sm: { dialog: { maxW: '360px' } },
    md: { dialog: { maxW: '540px' } },
  },
  baseStyle: ({ theme }: { size: string; theme: CustomTheme }) => {
    return {
      dialog: {
        background: 'gray.50',
      },
      header: {
        background: 'gray.50',
        borderBottom: '1px solid',
        borderColor: 'gray.400',
        color: 'pepperBlack',
        zIndex: '5',
      },
      footer: {
        borderTopWidth: '1px',
        height: '68px',
      },
      body: {
        /**
         * So that scrollbar doesn't change width
         */
        scrollbarGutter: 'stable',
        /**
         * Scroll Shadows
         * @see https://css-tricks.com/thats-just-how-i-scroll/
         * @see https://css-tricks.com/books/greatest-css-tricks/scroll-shadows/
         */
        background:
          /* Shadow Cover TOP */
          `linear-gradient(
            ${theme.colors.gray[50]} 30%,
            rgba(255, 255, 255, 0)
          ) center top,
          
          /* Shadow Cover BOTTOM */
          linear-gradient(
            rgba(255, 255, 255, 0), 
            ${theme.colors.gray[50]} 70%
          ) center bottom,
          
          /* Shadow TOP */
          radial-gradient(
            farthest-side at 50% 0,
            ${theme.colors.pepperBlack}20,
            rgba(0, 0, 0, 0)
          ) center top,
          
          /* Shadow BOTTOM */
          radial-gradient(
            farthest-side at 50% 100%,
            ${theme.colors.pepperBlack}20,
            rgba(0, 0, 0, 0)
          ) center bottom`,

        backgroundRepeat: `no-repeat`,
        backgroundSize: `100% 40px, 100% 40px, 100% 14px, 100% 14px`,
        backgroundAttachment: `local, local, scroll, scroll`,
        /**
         * Scroll Shadows End
         */
      },
    };
  },
};
