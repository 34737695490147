import React, { createContext, useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import { getCountryFromData } from '@pepper/client/hooks/useCountry';
import { getFacilityFromData } from '@pepper/client/hooks/useFacility';
import { Auth } from '@pepper/common';
import { datadogRum } from '@pepper/logger';
import { useToast } from '@deliveryhero/gfs-ui';
import { useTranslation } from 'next-i18next';
import { useAccounts, UserDetailsFragment } from '../../accounts';
import { config } from '../../config';
import {
  COUNTRY_COOKIE_NAME,
  INVALID_MONGO_ID,
  FACILITY_COOKIE_NAME,
  ACCOUNTS_ACCESS_TOKEN_COOKIE,
} from '../../constants';
import { useCookie } from '../../hooks/useCookie';
import { Pages } from '../../pages';
import type { UserStateMaybe } from '../../ssr/withPageProps';
import { getDummyProps } from './dummyValues';
import { useUserStateFallbackQuery } from './graphql/userState.query.generated';

interface IUserStateContext {
  user: UserDetailsFragment;
  country: NonNullable<UserStateMaybe['country']>;
  setCountryId: (countryId: string) => void;
  facility: NonNullable<UserStateMaybe['facility']>;
  setFacilityId: (facilityId: string) => void;
  logout: () => Promise<void>;
  isImpersonated: boolean;
  impersonateUser: (userId: string) => Promise<void>;
}

const defaultProps = getDummyProps().props;

export const UserStateContext = createContext<IUserStateContext>({
  user: defaultProps.user,
  country: defaultProps.country,
  setCountryId() {
    throw new Error('Default context');
  },
  facility: defaultProps.facility,
  setFacilityId() {
    throw new Error('Default context');
  },
  logout() {
    throw new Error('Default context');
  },
  isImpersonated: false,
  impersonateUser() {
    throw new Error('Default context');
  },
});

/**
 * SSR first user provider, provides the user state set in `getServerSideProps` globally
 * Use via `useUser` hook
 */
export const UserStateProvider: React.FC<
  UserStateMaybe & {
    children: React.ReactElement;
  }
> = ({
  children,
  user: propsUser,
  facility: propsFacility,
  country: propsCountry,
}) => {
  const toast = useToast();
  const router = useRouter();
  const { t } = useTranslation();
  const { accountsClient } = useAccounts();

  const userCookieExists = !!useCookie(ACCOUNTS_ACCESS_TOKEN_COOKIE);
  const userFallbackNeeded = !propsUser?._id && userCookieExists;

  const [activeFacilityId, setFacilityId] = useCookie(FACILITY_COOKIE_NAME);
  const facilityFallbackNeeded =
    !propsFacility?._id ||
    (activeFacilityId !== propsFacility?._id && !!activeFacilityId);

  const [activeCountryId, setCountryId] = useCookie(COUNTRY_COOKIE_NAME);
  // Country defaults to facility.country so if facility is present then no fallback needed
  const countryFallbackNeeded =
    !propsCountry?._id ||
    (activeCountryId === propsCountry?._id && !!activeCountryId);

  // Ideally this would be skipped on the first load as page props would be sent by SSR
  const { data } = useUserStateFallbackQuery({
    skip:
      !userFallbackNeeded && !countryFallbackNeeded && !facilityFallbackNeeded,
    errorPolicy: 'all',
    variables: {
      includeMe: userFallbackNeeded,
      countryId: activeCountryId || INVALID_MONGO_ID,
      includeCountry: !!activeCountryId && countryFallbackNeeded,
      includeDefaultCountry: countryFallbackNeeded,
      facilityId: activeFacilityId || INVALID_MONGO_ID,
      includeFacility: !!activeFacilityId && facilityFallbackNeeded,
      includeDefaultFacility: facilityFallbackNeeded,
    },
  });

  // memo needed so we don't create a new object every time
  const value = useMemo(() => {
    const dummy = getDummyProps();
    const user: UserDetailsFragment = data?.me ?? propsUser ?? dummy.props.user;
    const country = getCountryFromData(
      data,
      propsCountry?._id ? propsCountry : dummy.props.country,
    );
    const facility = getFacilityFromData(
      data,
      propsFacility ?? dummy.props.facility,
    );

    const impersonator = propsUser?.impersonator;
    const isImpersonated = !!impersonator?.data;

    const impersonateUser = async (userId: string): Promise<void> => {
      const canImpersonate = propsUser
        ? Auth.hasAccess(propsUser, ['users.admin'])
        : false;
      if (!canImpersonate) {
        throw new Error("You don't have permission to impersonate anyone");
      }
      await accountsClient.impersonate({ userId });
      await accountsClient.refreshSession(true);
      window.location.assign(config.ADMIN_CLIENT_URL);
    };

    const stopImpersonation = async (): Promise<void> => {
      if (!isImpersonated) {
        throw new Error('User not impersonating anyone');
      }
      toast({
        title: t(`common:user.stopping_impersonation`),
        description: t(`common:user.stopping_impersonation_description`),
      });
      await accountsClient.stopImpersonation();
      await accountsClient.refreshSession(true);
      window.location.assign(`${config.OMS_CLIENT_URL}/users`);
    };

    const logout = async (): Promise<void> => {
      if (isImpersonated) {
        return stopImpersonation();
      }
      await accountsClient.logout();
      await router.push(Pages.Auth.redirectToLogin());
    };

    return {
      user,
      country,
      setCountryId,
      facility,
      setFacilityId,
      logout,
      isImpersonated,
      impersonateUser,
      stopImpersonation,
    };
  }, [
    data,
    propsUser,
    setFacilityId,
    propsFacility,
    setCountryId,
    propsCountry,
    accountsClient,
    toast,
    router,
    t,
  ]);

  useEffect(() => {
    if (!value.user?._id) {
      return;
    }

    const { impersonator } = value.user;

    datadogRum?.setUser({
      id: value.user._id,
      name: value.user.fullName,
      role: value.user.access.label,
      facilityId: value.facility?._id,
      countryId: value.country?._id,
      countryCode: value.country?.countryCode,
      facilityType: value.country?.facilityType,
      userIsImpersonated: !!impersonator?.data,
      userImpersonatorId: impersonator?.data?.userId || undefined,
    });
  }, [value]);

  return (
    <UserStateContext.Provider value={value}>
      {children}
    </UserStateContext.Provider>
  );
};
