/* eslint-disable */
/* This is a generated file (see scripts/schema.js), don't edit manually */
export const userSchemaFields = [
  {
    "name": "id",
    "type": "STRING",
    "description": "Pepper User ID"
  },
  {
    "name": "name",
    "type": "STRING",
    "description": "Full name of the user"
  },
  {
    "name": "email",
    "type": "STRING",
    "description": "Email of user"
  },
  {
    "name": "deactivated",
    "type": "BOOLEAN",
    "mode": "NULLABLE",
    "description": "True if account disabled"
  },
  {
    "name": "createdAt",
    "type": "TIMESTAMP",
    "description": "When user was added in Pepper"
  },
  {
    "name": "updatedAt",
    "type": "TIMESTAMP",
    "description": "When user was last updated in Pepper"
  },
  {
    "name": "exportedAt",
    "type": "TIMESTAMP",
    "description": "When user was last exported from Pepper"
  }
] as const;
export type UserSchema = typeof userSchemaFields;