// NOTE: Load config first to set global env vars
import { config } from './config';
// eslint-disable-next-line import/order
import { logger } from '@pepper/logger';

export * from './accounts';
export * from './apollo';
export * from './components';
export { config, REDIRECT_KEYS } from './config';
export * from './hooks';
export * from './pages';
export * from './i18n';
export * from './providers';
export * from './helpers';

logger.debug({ config }, 'Debug Config');
