import React from 'react';
import { UserError } from '@pepper/client';
import { logger } from '@pepper/logger';

interface State {
  error: Error | null;
}

interface Props {
  children: React.ReactNode;
}

/**
 * @see https://reactjs.org/docs/concurrent-mode-suspense.html#handling-errors
 */
class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: Error): State {
    return {
      error,
    };
  }

  componentDidCatch(err: Error, errorInfo: unknown): void {
    logger.error({ err, payload: errorInfo }, `Error boundary`);
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;

    if (error) {
      return <UserError error={error} statusCode={500} />;
    }
    return children;
  }
}

export { ErrorBoundary };
