import { FacilityType, Auth } from '@pepper/common';
import { FacilityStatus } from '@pepper/types/graphql';
import type { AccessProps, CountryProps, FacilityProps } from '../../ssr';

export function getDummyProps(): {
  props: AccessProps & CountryProps & FacilityProps;
} {
  const dummyCountry = {
    _id: '',
    name: 'N/A',
    globalEntityIds: [],
    countryCode: 'NA',
    facilityType: FacilityType.Kitchen,
    locales: ['en'],
  };

  const dummyFacility = {
    _id: '',
    facilityType: dummyCountry.facilityType,
    name: 'N/A',
    ordersManagement: {},
    status: FacilityStatus.Inactive,
    timezone: '',
    country: dummyCountry,
  };

  const dummyUser = {
    _id: '',
    email: '',
    firstName: '',
    lastName: '',
    fullName: '',
    deactivated: false,
    access: {
      label: 'N/A',
      isSSO: false,
      permissions: {} as Auth.AccessPermissions,
      roles: {},
    },
    country: dummyCountry,
    facility: dummyFacility,
  };

  return {
    props: {
      user: dummyUser,
      country: dummyCountry,
      facility: dummyFacility,
    },
  };
}
