import React from 'react';
import { CSSReset, ChakraProvider } from '@chakra-ui/react';
import { theme, baseTheme } from './theme';

export const BaseThemeProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <ChakraProvider theme={baseTheme}>
      <CSSReset />
      {children}
    </ChakraProvider>
  );
};

export const CustomThemeProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <ChakraProvider theme={theme}>
      <CSSReset />
      {children}
    </ChakraProvider>
  );
};
