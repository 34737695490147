export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__' as const;

export const INVALID_MONGO_ID = '653b02407a9a267802eb011d';

export const ACCOUNTS_ACCESS_TOKEN_COOKIE = 'gks_accounts:accessToken';

export const ACCOUNTS_REFRESH_TOKEN_COOKIE = 'gks_accounts:refreshToken';

export const COUNTRY_COOKIE_NAME = 'active-country-id';

export const FACILITY_COOKIE_NAME = 'active-facility-id';

export const APP_VERSION =
  process.env.APP_VERSION || process.env.npm_package_version || 'unknown';

export const REQUESTED_WITH = `${
  process.env.SERVICE_NAME || process.env.DD_SERVICE
}@${process.env.APP_VERSION}`;
