import { useEffect, useMemo } from 'react';
import { useUserContext } from '../useUser';

/**
 * Set Hotjar user attributes and dataLayer is exists.
 */
export const useHotjar = (
  dataLayer?: Record<string, string | undefined>,
): void => {
  const { user, impersonator, isImpersonated } = useUserContext();
  const impersonatorUserId = impersonator?.data?.userId;
  const userId = user._id;
  const userAttributes = useMemo(
    () => ({
      userLabel: user?.access.label || undefined,
      userIsImpersonated: isImpersonated,
      userImpersonatorId: impersonatorUserId || undefined,
      countryCode: dataLayer?.countryCode,
      facilityId: dataLayer?.facilityId,
      facilityType: dataLayer?.facilityType,
    }),
    [
      dataLayer?.countryCode,
      dataLayer?.facilityId,
      dataLayer?.facilityType,
      impersonatorUserId,
      isImpersonated,
      user?.access.label,
    ],
  );
  useEffect(() => {
    // @ts-expect-error https://help.hotjar.com/hc/en-us/articles/360033640653-Identify-API-Reference#01G2A279ZXW4JT60NKXKX69G49
    window.hj =
      // @ts-expect-error check 1st ts-expect-error
      window.hj ||
      // eslint-disable-next-line func-names
      function () {
        // @ts-expect-error check 1st ts-expect-error
        // eslint-disable-next-line prefer-rest-params
        (hj.q = hj.q || []).push(arguments);
      };
    if (userId) {
      // @ts-expect-error check 1st ts-expect-error
      window.hj('identify', userId, userAttributes);
    }
  }, [userId, userAttributes]);
};
