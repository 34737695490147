import { ThemeComponents } from '@chakra-ui/react';
import { Accordion } from './Accordion';
import { Alert } from './Alert';
import { Breadcrumb } from './Breadcrumb';
import { Button } from './Button';
import { Checkbox } from './Checkbox';
import { Divider } from './Divider';
import { Drawer } from './Drawer';
import { Form } from './Form';
import { FormError } from './FormError';
import { FormLabel } from './FormLabel';
import { Heading } from './Heading';
import { Input } from './Input';
import { Modal } from './Modal';
import { NumberInput } from './NumberInput';
import { Popover } from './Popover';
import { Radio } from './Radio';
import { Slider } from './Slider';
import { Table } from './Table';
import { Tabs } from './Tabs';
import { Tag } from './Tag';
import { Text } from './Text';
import { Textarea } from './Textarea';

export const components = {
  Accordion,
  Alert,
  Breadcrumb,
  Button,
  Checkbox,
  Divider,
  Drawer,
  Form,
  FormError,
  FormLabel,
  Heading,
  Input,
  Modal,
  NumberInput,
  Popover,
  Radio,
  Slider,
  Table,
  Tabs,
  Tag,
  Text,
  Textarea,
} as ThemeComponents;
