import React from 'react';
import { Image } from '@chakra-ui/react';

export const Logo = () => {
  return (
    <Image
      boxSize="100px"
      src="/shared/logos/pepper-logo.png"
      alt="Pepper Logo"
    />
  );
};
