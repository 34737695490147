import { useMemo } from 'react';
import { useRouter } from 'next/router';

/**
 * NOTE: These use next/router query as the base so it will also include the path parameters used for dynamic routes
 */
export const useQueryParams = () => {
  const router = useRouter();
  return useMemo(() => {
    const urlSearchParams = new URLSearchParams();
    Object.entries(router.query).forEach(([key, values]) => {
      if (Array.isArray(values)) {
        values.forEach((v) => urlSearchParams.append(key, v));
      } else if (values != null) {
        urlSearchParams.set(key, values);
      }
    });
    return urlSearchParams;
  }, [router.query]);
};

/**
 * NOTE: This doesn't include path parameters but just simple search parameters.
 * So use this when wanting to delete parameters that might be either but only should be removed if query params.
 * Example: `stallId` in StallSchedulesDrawer
 *
 * NOTE: Doesn't work with SSR
 */
export const getSearchParams = () => {
  if (typeof window === undefined) {
    return new URLSearchParams();
  }
  return new URLSearchParams(window.location.search);
};
