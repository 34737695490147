/* eslint-disable */

export const namedOperations = {
  Query: {
    formOptions: 'formOptions',
    userStateFallback: 'userStateFallback'
  },
  Fragment: {
    userFields: 'userFields',
    UserDetails: 'UserDetails',
    CountrySelector: 'CountrySelector',
    FacilitySelector: 'FacilitySelector'
  }
}