/**
 * NOTE: Also update `helm/common/production.yaml` if changing file
 */

export const config = {
  APP_ENV: 'prod' as const,
  DD_CLIENT_ENV: 'prod' as const,
  DISABLE_PASSWORD_LOGIN: true,
  FWF_ENV_TOKEN: '284a98f3-52a3-4936-ba3a-365409745c5f',
  GTM_ID: 'GTM-N5XZ85N',
  IAM_PORTAL_PLUGIN_URL: 'https://portal.deliveryhero.io/de/p/dhh-iam',
  ADMIN_CLIENT_URL: 'https://admin.pepper.deliveryhero.io',
  AUTH_CLIENT_URL: 'https://pepper.deliveryhero.io',
  COOK_CLIENT_URL: 'https://cook.pepper.deliveryhero.io',
  SALES_CLIENT_URL: 'https://sales.pepper.deliveryhero.io',
  OMS_CLIENT_URL: 'https://orders.pepper.deliveryhero.io',
  GRAPHQL_SERVER: '/graphql',
  GRAPHQL_WEBSOCKET_SERVER:
    'wss://kitchen-screens.pepper.deliveryhero.io/graphql',
  SERVER_URL: 'https://kitchen-screens.pepper.deliveryhero.io',
};
