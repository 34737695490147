import { useContext } from 'react';
import { namedOperations } from '../helpers/operations';
import { CountrySelectorFragment } from '../providers/userStateProvider/graphql/countrySelector.fragment.generated';
import { UserStateFallbackQuery } from '../providers/userStateProvider/graphql/userState.query.generated';
import { UserStateContext } from '../providers/userStateProvider/userStateProvider';

/**
 * @package
 */
export const getCountryFromData = (
  data: UserStateFallbackQuery | undefined,
  defaultValue?: CountrySelectorFragment | null,
): CountrySelectorFragment => {
  const country =
    data?.country ?? data?.defaultCountryList?.nodes[0] ?? defaultValue;

  if (country) {
    return {
      ...country,
      salesAppSettings: {
        ...country.salesAppSettings,
        // Default true
        partnerContactEmailRequired:
          country?.salesAppSettings?.partnerContactEmailRequired ?? true,
      },
    };
  }

  throw new Error(
    `You don't have any country assigned to you, please contact support.`,
  );
};

/**
 * Get the selected country globally, ONLY works if using `getCountryServerSide` in SSR
 *
 * @example Usage in pages/components
 * ```ts
 * const country = useCountry();
 * ```
 */
export const useCountry = () => {
  const { country } = useContext(UserStateContext);
  return country;
};

useCountry.queryName = namedOperations.Query.userStateFallback;
