/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useMemo, useRef } from 'react';
import type { NextRouter } from 'next/router';
import type { RecursiveNullable } from '@pepper/types';
import { set, UseFormProps, UseFormReset } from 'react-hook-form';
import { InferType } from 'yup';
import { RequiredObjectSchema } from 'yup/lib/object';
import { getParamsFromRouter } from '../../utils';
import { FORM_URL_PARAMS_PREFIX } from '../useFilters/useChangedFields';

export interface DefaultValuesProps<
  TSchema extends RequiredObjectSchema<any, any, any>,
> {
  /**
   * If you pass router and set defaultValues as `router` we will try to read the default values from the query params
   */
  router?: NextRouter;
  /**
   * yup object schema for form data
   */
  schema: TSchema;
  /**
   * If you don't provide then we get defaults from the yup schema
   */
  defaultValues?: RecursiveNullable<InferType<TSchema>> | null;
  initialValuesOption?: 'router';
}

/**
 * @package internal hook, don't export out of the package
 */
export const useDefaultValues = <
  TSchema extends RequiredObjectSchema<any, any, any>,
>({
  router,
  schema,
  defaultValues,
  initialValuesOption,
}: DefaultValuesProps<TSchema>) => {
  /**
   * @see https://github.com/react-hook-form/react-hook-form/discussions/1936
   */
  const schemaDefaultValues = useMemo(() => {
    return schema.cast(defaultValues || {}, {
      stripUnknown: true,
    });
  }, [schema, defaultValues]);

  const routerDefaultValues = useMemo(() => {
    if (initialValuesOption !== 'router' || !router) {
      return null;
    }

    const params = getParamsFromRouter(router);

    const values = {};
    params.forEach((val, key) => {
      if (!key.startsWith(FORM_URL_PARAMS_PREFIX)) {
        return;
      }
      let safeVal = null;
      try {
        safeVal = JSON.parse(val);
      } catch (_err) {
        //
      }
      if (safeVal == null) {
        return;
      }

      set(values, key.substring(FORM_URL_PARAMS_PREFIX.length), safeVal);
    });

    return schema.cast(values, { stripUnknown: true });
  }, [router, schema, initialValuesOption]);

  return {
    initialValues: routerDefaultValues,
    defaultValues: schemaDefaultValues,
  };
};

interface WatchDefaultsProps<
  TSchema extends RequiredObjectSchema<any, any, any>,
  TContext = any,
> {
  reset: UseFormReset<InferType<TSchema>>;
  watchDefaults?: boolean;
  router?: NextRouter;
  initialValues?: UseFormProps<InferType<TSchema>, TContext>['defaultValues'];
  defaultValues?: UseFormProps<InferType<TSchema>, TContext>['defaultValues'];
}

/**
 * @package internal hook, don't export out of the package
 */
export const useWatchDefaults = <
  TSchema extends RequiredObjectSchema<any, any, any>,
>({
  reset,
  router,
  watchDefaults: watchDefaultsOption,
  initialValues,
  defaultValues,
  resetOptions,
}: WatchDefaultsProps<TSchema> & {
  resetOptions?: UseFormProps<InferType<TSchema>, any>['resetOptions'];
}) => {
  const watchDefaults = !!watchDefaultsOption && !!defaultValues;
  const loadingDefaultsFromUrl = useRef(!!initialValues);
  const loadingDefaults = useRef(watchDefaults);

  useEffect(() => {
    if (!loadingDefaultsFromUrl.current) {
      return;
    }
    loadingDefaultsFromUrl.current = false;

    /**
     * We are not using router query param in default value directly because that
     * removes those fields from "dirtyFields" and removes them from params
     */
    reset(initialValues, {
      keepDefaultValues: true,
    });

    // Allow setting values from url on going back via browser nav
    router?.beforePopState(() => {
      loadingDefaultsFromUrl.current = true;
      return true;
    });
  }, [initialValues, reset, router]);

  useEffect(() => {
    if (!watchDefaults) {
      return;
    }
    if (loadingDefaults.current) {
      loadingDefaults.current = false;
      return;
    }
    reset(defaultValues, resetOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchDefaults, defaultValues, reset]);

  return !!loadingDefaultsFromUrl.current || !!loadingDefaults.current;
};
