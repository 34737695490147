import type { RecursivePartial } from '@pepper/types';
import { Theme } from '@chakra-ui/react';
import type { CustomTheme } from '../theme';

export const Modal: RecursivePartial<Theme['components']['Modal']> = {
  baseStyle: ({ size, theme }: { size: string; theme: CustomTheme }) => {
    return {
      dialog: {
        borderRadius: size === 'full' ? 0 : '0.5rem',
        background: 'gray.50',
      },
      closeButton: {
        top: 3,
        right: 3,
        padding: 4,
        borderRadius: 'full',
        _hover: {
          backgroundColor: 'gray.200',
        },
        '.chakra-icon': {
          width: 3,
          height: 3,
          margin: 1.5,
        },
      },
      header: {
        padding: 4,
        pl: 6,
        fontSize: 'lg',
        fontWeight: 'bold',
        display: 'inline-block',
        color: 'pepperBlack',
        borderBottom: '1px gray solid',
        borderColor: 'gray.200',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
      footer: {
        borderTop: '1px gray solid',
        borderColor: 'gray.200',
      },
      body: {
        /**
         * So that scrollbar doesn't change width
         */
        scrollbarGutter: 'stable',
        /**
         * Scroll Shadows
         * @see https://css-tricks.com/thats-just-how-i-scroll/
         * @see https://css-tricks.com/books/greatest-css-tricks/scroll-shadows/
         */
        background:
          /* Shadow Cover TOP */
          `linear-gradient(
            ${theme.colors.gray[50]} 30%,
            rgba(255, 255, 255, 0)
          ) center top,
          
          /* Shadow Cover BOTTOM */
          linear-gradient(
            rgba(255, 255, 255, 0), 
            ${theme.colors.gray[50]} 70%
          ) center bottom,
          
          /* Shadow TOP */
          radial-gradient(
            farthest-side at 50% 0,
            ${theme.colors.pepperBlack}20,
            rgba(0, 0, 0, 0)
          ) center top,
          
          /* Shadow BOTTOM */
          radial-gradient(
            farthest-side at 50% 100%,
            ${theme.colors.pepperBlack}20,
            rgba(0, 0, 0, 0)
          ) center bottom`,

        backgroundRepeat: `no-repeat`,
        backgroundSize: `100% 40px, 100% 40px, 100% 14px, 100% 14px`,
        backgroundAttachment: `local, local, scroll, scroll`,
        /**
         * Scroll Shadows End
         */
      },
    };
  },
  sizes: {
    md: {
      dialog: {
        width: '400px',
      },
    },
    '2xl': {
      dialog: {
        width: '608px',
      },
    },
  },
};
