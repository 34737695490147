import React from 'react';
import { Icon } from '@deliveryhero/gfs-ui';
import { Link } from '@chakra-ui/next-js';
import {
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import { config } from '../../config';
import { APP_LOGOS, ClientServices } from '../../config/common';
import { useGtm } from '../../hooks/gtm';
import { Access } from '../Access';
import { groupStyles } from './SidebarItem';

const AppLink = ({
  url,
  serviceName,
}: {
  url: string;
  serviceName: ClientServices;
}) => {
  const { pushGtm } = useGtm();
  const { t } = useTranslation();
  return (
    <Box px={2}>
      <Link
        href={url}
        w="100%"
        onClick={() => {
          pushGtm('clicked', { label: 'App Switcher', info: serviceName });
        }}
      >
        <MenuItem
          mx={0}
          my={1}
          w="100%"
          borderRadius="md"
          bgColor={config.SERVICE_NAME === serviceName ? 'blue.100' : undefined}
        >
          <Image
            h={6}
            src={APP_LOGOS[serviceName]}
            alt={t(`common:sidebar.${serviceName}`)}
          />
          {config.SERVICE_NAME === serviceName && (
            <Icon ms="1.5" image="check-medium" />
          )}
        </MenuItem>
      </Link>
    </Box>
  );
};

const AppSwitcher = ({ isOpen }: { isOpen: boolean }) => {
  const height = isOpen ? '24px' : '20px';
  const { pushGtm } = useGtm();

  return (
    <Menu>
      <MenuButton
        h={9}
        minH={9}
        w={isOpen ? undefined : 10}
        sx={groupStyles}
        onChange={() => {
          pushGtm('opened', { label: 'App Switcher' });
        }}
      >
        <Flex direction="row" aria-label="Change Pepper apps">
          <Image
            key="small-logo"
            as={motion.img}
            aria-hidden
            // This logo is always visible but is overlapped by the full logo when open
            position="absolute"
            src="/shared/icons/pepper.svg"
            h="20px"
            initial={{ height: 20, marginInlineStart: '0px' }}
            animate={{ height, marginInlineStart: isOpen ? '10px' : '0px' }}
          />
          {isOpen ? (
            <Image
              key="large-logo"
              as={motion.img}
              aria-hidden
              src={config.APP_LOGO}
              h="20px"
              initial={{ height: 20, opacity: 0, marginInlineStart: '0px' }}
              animate={{ height, opacity: 1, marginInlineStart: '10px' }}
              exit={{ height: 20, opacity: 0, marginInlineStart: '0px' }}
            />
          ) : null}
          <Icon ms={isOpen ? 1.5 : 5} my="auto" image="down" aria-hidden />
        </Flex>
      </MenuButton>
      <MenuList
        py={1}
        minWidth="200px"
        maxWidth="fit-content"
        borderRadius="lg"
        position="relative"
      >
        {/* NOTE: If you change access conditions here, then update them in the auth app landing page too */}
        <Access
          permissions={[
            'facilities.view',
            'countries.view',
            'partners.view',
            'users.view',
          ]}
          facilityType="some"
          countryId="some"
          facilityId="many"
        >
          {({ hasAccess: hasViewAccessForMultiple }) => (
            <Access
              permissions={[
                'facilities.edit',
                'countries.edit',
                'partners.edit',
                'users.edit',
              ]}
              facilityType="some"
              countryId="some"
              facilityId="some"
            >
              {({ hasAccess: hasEditAccessForSome }) =>
                hasViewAccessForMultiple || hasEditAccessForSome ? (
                  <AppLink
                    url={config.ADMIN_CLIENT_URL}
                    serviceName="gfs-pepper-admin"
                  />
                ) : null
              }
            </Access>
          )}
        </Access>
        <Access permissions="brands.*">
          <AppLink
            url={config.SALES_CLIENT_URL}
            serviceName="gfs-sales-dashboard"
          />
        </Access>
        <Access permissions="orders.*">
          <AppLink
            url={config.OMS_CLIENT_URL}
            serviceName="gfs-order-management"
          />
          <Access permissions="stalls.view">
            <AppLink
              url={config.COOK_CLIENT_URL}
              serviceName="gfs-cooking-app"
            />
          </Access>
        </Access>
      </MenuList>
    </Menu>
  );
};

const MemoisedAppSwitcher = React.memo(AppSwitcher);
export { MemoisedAppSwitcher as AppSwitcher };
