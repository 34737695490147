import type { RecursivePartial } from '@pepper/types';
import { Theme } from '@chakra-ui/react';

export const Checkbox: RecursivePartial<Theme['components']['Checkbox']> = {
  defaultProps: {
    size: 'md',
  },
  sizes: {
    lg: {
      label: {
        fontSize: 'md',
        fontWeight: 'medium',
      },
    },
  },
};
