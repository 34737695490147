import type { RecursivePartial } from '@pepper/types';
import { AlertProps, Theme } from '@chakra-ui/react';

export const Alert: RecursivePartial<Theme['components']['Alert']> = {
  baseStyle: {
    title: {
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '20px',
    },
    description: {
      fontSize: '14px',
      lineHeight: '20px',
      flex: '1 0 0',
      marginRight: 7,
    },
    container: {
      padding: '8px',
      borderRadius: '4px',
      alignItems: 'flex-start',
      gap: '6px',
      '> button[aria-label="Close alert"]': {
        width: 5,
        height: 5,
        margin: 1.5,
        position: 'absolute',
        top: 0.5,
        right: 0.5,
      },
    },
  },
  variants: {
    subtle: (props: AlertProps) => {
      if (props.status === 'success') {
        return {
          icon: {
            color: 'green.700',
          },
          container: {
            color: 'green.700',
            backgroundColor: 'green.100',
          },
        };
      }
      if (props.status === 'info') {
        return {
          icon: {
            color: 'blue.700',
          },
          container: {
            color: 'blue.700',
            backgroundColor: 'blue.100',
          },
        };
      }
      if (props.status === 'warning') {
        return {
          icon: {
            color: 'orange.900',
          },
          container: {
            color: 'orange.900',
            backgroundColor: 'orange.200',
          },
        };
      }
      if (props.status === 'error') {
        return {
          icon: {
            color: 'red.700',
          },
          container: {
            color: 'red.700',
            backgroundColor: 'red.50',
          },
        };
      }
    },
  },
};
