import React, { useCallback } from 'react';
import { Text } from '@chakra-ui/react';
import { SingleValueProps, chakraComponents } from 'chakra-react-select';
import isEqual from 'lodash/isEqual';
import {
  BaseFormSelect,
  BaseFormSelectProps,
  SelectOption,
} from './BaseSelect';

export type FormSelectProps<Options = string> = Omit<
  BaseFormSelectProps<Options, false>,
  'valueMapper' | 'onChangeMapper' | 'isMulti' | 'components'
>;

export const SingleValueWithLabel = ({
  fieldId,
  label,
  isLabelInline,
  children,
  ...props
}: {
  isLabelInline?: boolean;
  fieldId?: string;
  label?: string;
} & SingleValueProps<any>) => (
  <chakraComponents.SingleValue {...props}>
    {isLabelInline && label ? (
      <Text as="label" htmlFor={fieldId} mr={1.5} color="gray.700">
        {label}
      </Text>
    ) : null}
    {children}
  </chakraComponents.SingleValue>
);

const components = {
  SingleValue: SingleValueWithLabel,
};

// eslint-disable-next-line react/function-component-definition
function FormSelect<Options = string>({
  options,
  isCreatable,
  ...props
}: FormSelectProps<Options>): JSX.Element {
  const valueMapper = useCallback(
    (selected: any) => {
      if (!selected) return null;
      return (
        (options as SelectOption<Options>[])?.find((o) =>
          isEqual(o.value, selected),
        ) ??
        (isCreatable
          ? { label: selected as unknown as string, value: selected }
          : null)
      );
    },
    [isCreatable, options],
  );
  const onChangeMapper = useCallback(
    (selected: SelectOption<Options> | null) => {
      return selected?.value ?? null;
    },
    [],
  );

  return (
    <BaseFormSelect<Options, false>
      {...props}
      isMulti={false}
      isCreatable={isCreatable}
      options={options}
      data-testid={`select-${props.name}`}
      components={components}
      valueMapper={valueMapper}
      onChangeMapper={onChangeMapper}
    />
  );
}

const MemoizedSelect = React.memo(FormSelect) as typeof FormSelect;

export { MemoizedSelect as FormSelect };
