import type { RecursivePartial } from '@pepper/types';
import { Theme } from '@chakra-ui/react';
import { colorFilters } from '../colors';

export const FormError: RecursivePartial<Theme['components']['FormError']> = {
  baseStyle: {
    text: {
      mt: 1,
      wordWrap: 'anywhere',
      lineHeight: 5,
    },
    icon: {
      filter: colorFilters.red[500],
      w: 4,
      h: 4,
      mr: 1.5,
    },
  },
};
