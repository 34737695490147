import countryToCurrency from 'country-to-currency';
import getSymbolFromCurrency from 'currency-symbol-map';
import durationPlugin from 'moment-duration-format';
import * as Moment from 'moment-timezone';
import moment from 'moment-timezone';

durationPlugin(Moment);

const getCurrencySymbolByCountryCode = (countryCode?: string) =>
  countryCode
    ? getSymbolFromCurrency(
        countryToCurrency[countryCode as keyof typeof countryToCurrency],
      )
    : '';

/**
 * @returns `3.40` or `13,432` or `43,122,312` depending on user's client
 */
export function formatNumber(
  input: number | undefined | null,
  options?: Intl.NumberFormatOptions,
): string | undefined {
  if (input == null) {
    return '-';
  }
  // undefined = pick user's locale
  return Intl.NumberFormat(undefined, options).format(input);
}

/**
 * @returns `6 months` or `1 year` or `3 years` depending on input
 */
export function formatMonths(
  input: number | undefined | null,
): string | undefined {
  if (input == null) {
    return '-';
  }
  return moment.duration({ months: input }).format();
}

/**
 * @returns 3.4% or 23%
 */
export function formatPercentage(input: number | undefined | null): string {
  if (input == null) {
    return '-';
  }

  return `${formatNumber(input)}%`;
}

formatPercentage.symbol = '%';

/**
 * @see https://stackoverflow.com/a/40353238
 */
export const isRtl = (text: string | undefined) =>
  text && /[\u0590-\u06FF]/.test(text);

type CurrencyOptions = {
  countryCode?: string;
  currency?: string;
};

const getCurrencySymbol = ({ countryCode, currency }: CurrencyOptions) => {
  if (!countryCode && !currency) {
    return undefined;
  }
  const symbol = currency
    ? getSymbolFromCurrency(currency)
    : getCurrencySymbolByCountryCode(countryCode);

  return symbol;
};

/**
 * @example
 * With brands
 *
 * ```ts
 * const format = getCurrencyFormatter(brand.country);
 * return <>{format(brand.stats.averageOrderValue)}</>;
 * ```
 * @returns A format function
 */
export function getCurrencyFormatter(opts: CurrencyOptions | null = {}) {
  const { countryCode, currency } = opts || {};
  const symbol = getCurrencySymbol({ countryCode, currency });

  if (!symbol) {
    return formatNumber;
  }

  const formatFn = (
    input: number | undefined | null,
    options: Intl.NumberFormatOptions = {},
  ) => {
    if (input == null) {
      return formatNumber(input, options);
    }

    if (isRtl(symbol)) {
      return `${formatNumber(input, options)} ${symbol}`;
    }
    return `${symbol} ${formatNumber(input, options)}`;
  };

  formatFn.symbol = symbol;

  return formatFn;
}

/**
 * @returns formatted number with currency symbol of country
 */
export function formatCurrency(
  input: number | undefined | null,
  opts: (CurrencyOptions & Intl.NumberFormatOptions) | null = {},
) {
  const { countryCode, currency, ...options } = opts || {};
  return getCurrencyFormatter({ countryCode, currency })(input, options);
}

/**
 * @example 2021.05.24
 */
export function formatDate(
  input: string | Date | Moment.Moment,
  dateFormat = 'YYYY.MM.DD',
): string {
  return moment(input).format(dateFormat);
}

/**
 * @param dateString
 * @returns "2 days ago" type of relative string
 */
export function formatDateRelative(dateString: string): string {
  const now = new Date();
  const date = new Date(dateString);
  const diffTime = now.getTime() - date.getTime();
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

  if (diffDays <= 7) {
    return moment(dateString).fromNow();
  }
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${`0${day}`.slice(-2)}/${`0${month}`.slice(-2)}/${year}`;
}
