import type { RecursivePartial } from '@pepper/types';
import { Theme } from '@chakra-ui/react';

export const Breadcrumb: RecursivePartial<Theme['components']['Breadcrumb']> = {
  baseStyle: {
    container: {
      height: 9,
      lineHeight: 5,
      fontSize: '14px',
      color: 'pepperBlack',
    },
    link: {
      color: 'blue.500',
      mr: 2,
    },
    separator: {
      ml: 0,
    },
  },
};
