/**
 * @deprecated Use new `access` enums
 */
export enum Namespaces {
  Orders = 'orders',
  Kitchens = 'kitchens',
  Users = 'users',
  Countries = 'countries',
  Leads = 'leads',
}

/**
 * @deprecated Use new `access` enums
 */
export enum Permissions {
  Self = 'self',
  SelfEdit = 'self-edit',
  View = 'view',
  Edit = 'edit',
}

/**
 * @deprecated Use new `access` enums
 */
export type NamespacedPermission =
  | `${Namespaces}.${Permissions}`
  // These 2 come from dhh-iam application
  | 'gfs-kitchen-screens.admin'
  | 'gfs-kitchen-screens.app-admin';
