import type { NextRouter } from 'next/router';

export const urlSearchParamsToUrl = (
  params: URLSearchParams,
): string | null => {
  if (params.toString().length === 0) {
    return null;
  }
  params.sort();
  return `?${params.toString()}`;
};

export const getParamsFromRouter = (router: NextRouter) => {
  return new URLSearchParams(router.asPath.split('?')[1]);
};

export const deleteAllParamsWithPrefix = (
  router: NextRouter,
  prefix: string,
) => {
  const params = getParamsFromRouter(router);
  [...params.keys()].forEach((k) => {
    if (!k.startsWith(prefix)) {
      return;
    }

    params.delete(k);
  });

  return params;
};

export const updatePrefixedParamsWithData = (
  params: URLSearchParams,
  data: Record<string, unknown>,
  prefix: string,
) => {
  Object.entries(data).forEach(([key, val]) => {
    if (val === undefined) {
      params.delete(`${prefix}${key}`);
    } else {
      params.set(`${prefix}${key}`, JSON.stringify(val));
    }
  });

  return params;
};

/**
 * Replaces the existing prefixed URL parameters with new parameters.
 *
 * This function will remove any existing parameters that start with the given prefix
 * and replace them with the new parameters provided in the urlParamsObj.
 *
 * @param {NextRouter} router - The Next.js router object.
 * @param {Record<string, unknown>} urlParamsObj - An object containing the new URL parameters to be added.
 * @param {string} prefix - The prefix to be applied to the new URL parameters.
 * @returns {URLSearchParams} - The updated URLSearchParams object.
 */
export const replaceExistingParamsWithPrefixedParams = (
  router: NextRouter,
  urlParamsObj: Record<string, unknown>,
  prefix: string,
): URLSearchParams => {
  const params = deleteAllParamsWithPrefix(router, prefix);

  //  update the specific params with provided values
  return updatePrefixedParamsWithData(params, urlParamsObj, prefix);
};
