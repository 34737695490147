import type { RecursivePartial } from '@pepper/types';
import { Theme } from '@chakra-ui/react';

export const Form: RecursivePartial<Theme['components']['Form']> = {
  baseStyle: {
    helperText: {
      mt: 1,
      color: 'gray.700',
    },
  },
};
