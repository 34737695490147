import { useMemo } from 'react';
import { LabelValueObj } from '@deliveryhero/gfs-ui';
import { allOptions } from '../../helpers/constants';
import { namedOperations } from '../../helpers/operations';
import {
  FormOptionsQuery,
  FormOptionsQueryVariables,
  useFormOptionsQuery,
} from './graphql/formOptions.query.generated';

export type CountryOption = LabelValueObj &
  Partial<NonNullable<FormOptionsQuery['allCountries']>['nodes'][number]>;

export type FacilityOption = LabelValueObj &
  Partial<NonNullable<FormOptionsQuery['allFacilities']>['nodes'][number]>;

export type PartnerOption = LabelValueObj &
  Partial<NonNullable<FormOptionsQuery['allPartners']>['nodes'][number]>;

export type UserOption = LabelValueObj &
  Partial<NonNullable<FormOptionsQuery['allUsers']>['nodes'][number]>;

/**
 * Get label-value objects for form selects
 */
export const useFormOptions = ({
  skip,
  addAllOption = false,
  addAllUser = addAllOption,
  addAllCountry = addAllOption,
  addAllFacility = addAllOption,
  addAllPartner = addAllOption,
  extraOptions,
  ...variables
}: {
  skip?: boolean;
  extraOptions?: {
    partner?: { _id: string; name: string } | null;
    facility?: { _id: string; name: string } | null;
    user?: { _id: string; fullName: string } | null;
  };
  addAllUser?: boolean;
  addAllCountry?: boolean;
  addAllFacility?: boolean;
  addAllPartner?: boolean;
  /**
   * Will add `all` to all the results
   */
  addAllOption?: boolean;
} & FormOptionsQueryVariables) => {
  const { data, loading } = useFormOptionsQuery({
    variables: {
      ...variables,
    },
    skip,
  });

  const {
    facility: extraFacility,
    partner: extraPartner,
    user: extraUser,
  } = extraOptions ?? {};

  return useMemo(() => {
    if (loading || !data) {
      return {
        loading,
        options: {
          users: undefined,
          countries: undefined,
          facilities: undefined,
          partners: undefined,
        },
      };
    }
    const { allCountries, allFacilities, allPartners, allUsers } = data;

    const countryOptions: CountryOption[] = [...(allCountries?.nodes ?? [])];
    if (addAllCountry) {
      countryOptions.unshift(allOptions);
    }

    const facilityOptions: FacilityOption[] = [...(allFacilities?.nodes ?? [])];
    if (addAllFacility) {
      facilityOptions.unshift(allOptions);
    }
    if (
      extraFacility &&
      facilityOptions.every((f) => f.value !== extraFacility._id)
    ) {
      facilityOptions.unshift({
        label: extraFacility.name,
        value: extraFacility._id,
      });
    }

    const partnerOptions: PartnerOption[] = [...(allPartners?.nodes ?? [])];
    if (addAllPartner) {
      partnerOptions.unshift(allOptions);
    }
    if (
      extraPartner &&
      partnerOptions.every((p) => p.value !== extraPartner._id)
    ) {
      partnerOptions.unshift({
        label: extraPartner.name,
        value: extraPartner._id,
      });
    }

    const userOptions: UserOption[] = [...(allUsers?.nodes ?? [])];
    if (addAllUser) {
      userOptions.unshift(allOptions);
    }
    if (extraUser && userOptions.every((u) => u.value !== extraUser._id)) {
      userOptions.unshift({ label: extraUser.fullName, value: extraUser._id });
    }

    return {
      loading: false,
      options: {
        countries: countryOptions,
        facilities: facilityOptions,
        partners: partnerOptions,
        users: userOptions,
      },
    };
  }, [
    loading,
    data,
    addAllCountry,
    addAllFacility,
    extraFacility,
    addAllPartner,
    extraPartner,
    addAllUser,
    extraUser,
  ]);
};

useFormOptions.queryName = namedOperations.Query.formOptions;
