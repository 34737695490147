/* eslint-disable */

import * as Types from '@pepper/types/graphql';

import { gql } from '@apollo/client';
export type UserFieldsFragment = { facilityId: any, _id: string, email: string, deactivated?: boolean | null, fullName: string, firstName: string, lastName: string, id: string, access: { isSSO?: boolean | null, label: string, roles: Types.Scalars['AccessRoles'], permissions: Types.Scalars['AccessPermissions'] } };

export type UserDetailsFragment = { _id: string, email: string, deactivated?: boolean | null, fullName: string, firstName: string, lastName: string, access: { isSSO?: boolean | null, label: string, roles: Types.Scalars['AccessRoles'], permissions: Types.Scalars['AccessPermissions'] } };

export const UserDetailsFragmentDoc = gql`
    fragment UserDetails on User {
  _id
  email
  deactivated
  fullName
  firstName
  lastName
  access {
    isSSO
    label
    roles
    permissions
  }
}
    `;
export const UserFieldsFragmentDoc = gql`
    fragment userFields on User {
  id: _id
  facilityId
  ...UserDetails
}
    ${UserDetailsFragmentDoc}`;