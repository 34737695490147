import { config } from '../config';
import { urlSearchParamsToHref } from '../helpers';

/**
 * If on client side then we will compare current host and add it to pages if not matched
 * On server always generate full url
 */
const host = (() => {
  const isBrowser = typeof window !== 'undefined';
  const url = config.OMS_CLIENT_URL;
  const configHostname = new URL(url).hostname;
  if (
    (isBrowser && globalThis.location.hostname === configHostname) ||
    (!isBrowser && config.SERVICE_NAME === 'gfs-order-management')
  ) {
    // Use relative url
    return '';
  }
  return url;
})();

export const OrderManagement = {
  DASHBOARD: `${host}/dashboard`,
  FACILITY_OVERVIEW: `${host}/kitchen-overview`,
  ORDER_HISTORY: `${host}/order-history`,
  ORDER_HISTORY_DETAILS: ({
    orderId,
    params = new URLSearchParams(),
  }: {
    orderId: string;
    params?: URLSearchParams;
  }) => {
    params.set('details', orderId);
    return urlSearchParamsToHref(params);
  },
  RUNNER_DASHBOARD: `${host}/runner-dashboard`,
  STATUS_PAGE: `${host}/status`,
};
