import { GetServerSidePropsContext } from 'next';
import { ApolloLink } from '@apollo/client';
import { getCookie } from 'cookies-next';
import { getDomainFromHost } from '../accounts/cookieStorage';
import { LANGUAGE_COOKIE } from '../hooks/useLocale';
import { i18nConfig } from '../i18n/next-i18next.config';

const { i18n } = i18nConfig;

export const getLanguageHeader = (
  ctx?: GetServerSidePropsContext,
): string | null => {
  const language =
    getCookie(LANGUAGE_COOKIE, {
      path: '/',
      domain: getDomainFromHost(ctx?.req?.headers?.host),
      // @ts-ignore
      req: ctx?.req,
      res: ctx?.res,
    }) ?? i18n.defaultLocale;

  if (!language) {
    return null;
  }

  return `${language};q=1, ${
    ctx
      ? ctx.req.headers['accept-language']
      : navigator.languages
          .map((locale, index) => {
            return `${locale};${index >= 9 ? 'q=0' : `q=0.${9 - index}`}`;
          })
          .join(', ')
  }`;
};

export const languageLink = (ctx?: GetServerSidePropsContext): ApolloLink => {
  return new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => {
      const languageHeader = getLanguageHeader(ctx);
      if (!languageHeader) {
        return {
          headers,
        };
      }

      return {
        headers: {
          ...headers,
          'Accept-Language': languageHeader,
        },
      };
    });

    return forward(operation);
  });
};
