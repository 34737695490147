import React from 'react';
import { config } from '../../config';
import { useGtm } from '../../hooks/gtm';
import { SidebarItem } from './SidebarItem';

export const AppVersion = React.memo(({ isOpen }: { isOpen: boolean }) => {
  const { pushGtm } = useGtm();
  if (config.APP_ENV === 'prod') {
    return null;
  }

  const jira = config.APP_VERSION.match(/([A-Z]+)-(\d+)/g)?.[0];
  const href = jira
    ? `https://jira.deliveryhero.com/browse/${jira}`
    : undefined;

  return (
    <SidebarItem
      name={`${config.APP_ENV}: ${config.APP_VERSION}`}
      isActive={false}
      isOpen={isOpen}
      href={href}
      onClick={() => {
        pushGtm(`clicked`, { label: 'Version Link' });
      }}
      icon={{
        normal: '/shared/icons/git.svg',
      }}
    />
  );
});
