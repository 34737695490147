import { config } from '../config';

/**
 * If on client side then we will compare current host and add it to pages if not matched
 * On server always generate full url
 */
const host = (() => {
  const isBrowser = typeof window !== 'undefined';
  const url = config.AUTH_CLIENT_URL;
  const configHostname = new URL(url).hostname;
  if (
    (isBrowser && globalThis.location.hostname === configHostname) ||
    (!isBrowser && config.SERVICE_NAME === 'gfs-pepper-auth')
  ) {
    // Use relative url
    return '';
  }
  return url;
})();

export const Auth = {
  FORGOT: `${host}/forgot`,
  LOGIN: `${host}/login`,
  LOGIN_SSO: `${host}/login-sso`,
  RESET_PASSWORD: ({ token }: { token: string }) =>
    `${host}/reset-password/${token}`,
  redirectToLogin: ({
    path,
    errMessage,
  }: { path?: string; errMessage?: string } = {}) =>
    `${host}/login?redirect=${config.APP_REDIRECT}&path=${encodeURIComponent(
      path || '/',
    )}${errMessage ? `&err=${errMessage}` : ''}`,
};
