import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { pushGtmTrackEvent, config, Pages } from '@pepper/client';
import { ApolloError } from '@apollo/client';
import { Link } from '@chakra-ui/next-js';
import { Flex, Heading, Text } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

export const supportLink = (
  <Link
    as="span"
    color="blue.500"
    target="_blank"
    rel="noopener noreferrer"
    href={config.SLACK_SUPPORT_LINK}
  />
);

export const faqLink = (
  <Link
    target="_blank"
    rel="noopener noreferrer"
    href={config.FAQ_LINK}
    textDecoration="underline"
  />
);

export const UserError: React.FC<{ error?: Error; statusCode?: number }> = ({
  error,
  statusCode,
}) => {
  const { t } = useTranslation();
  const router = useRouter();

  const { exception: serverError, code: serverCode } =
    (error as ApolloError | null)?.graphQLErrors?.[0]?.extensions ?? {};
  let code =
    Number(
      statusCode || serverCode || (error as NodeJS.ErrnoException | null)?.code,
    ) || 500;
  if (Number.isNaN(code)) {
    code = 500;
  }

  const serverStack =
    (error as ApolloError | null)?.networkError?.stack ??
    serverError?.stacktrace ??
    error?.cause?.stack ??
    null;

  useEffect(() => {
    pushGtmTrackEvent('error.shown', {
      label: String(code),
      error,
    });
  }, [code, error]);

  return (
    <Flex
      style={{ height: '95vh' }}
      alignItems="center"
      flexDirection="column"
      width="100%"
      mt="12"
      data-testid="error-page"
    >
      <Heading size="lg">
        <Link
          rel="noreferrer"
          target="_self"
          onClick={() => {
            pushGtmTrackEvent('error.clicked', {
              label: 'Retry login',
            });
          }}
          href={Pages.Auth.redirectToLogin({ path: router.asPath })}
        >
          {code === 400 && t('common:errors.heading')}
          {code === 401 && t('common:errors.login_needed')}
          {code === 403 && t('common:errors.unauthorized_description')}
          {code === 404 && t('common:errors.404')}
          {![400, 401, 403, 404].includes(code) &&
            t('common:errors.login_retry')}
        </Link>
        &nbsp;
      </Heading>

      <Text fontWeight="semibold">
        {`${
          error?.name ||
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (error as any)?.type ||
          'Error'
        }${code ? ` [${code}]` : ''}${
          error?.message ? ` : ${error?.message}` : ''
        }`}
      </Text>

      {config.APP_ENV !== 'prod' ? (
        <Text fontSize="xs" maxW="lg">
          <br />
          {serverStack ? (
            <>
              {serverStack}
              <br />
              <br />
            </>
          ) : null}
          {error?.stack}
        </Text>
      ) : null}
    </Flex>
  );
};
