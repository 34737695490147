/* eslint-disable */
/* This is a generated file (see scripts/schema.js), don't edit manually */
export const stallSchemaFields = [
  {
    "name": "id",
    "type": "STRING",
    "description": "Pepper Stall ID"
  },
  {
    "name": "facilityId",
    "type": "STRING",
    "description": "Pepper Facility ID"
  },
  {
    "name": "facilityType",
    "type": "STRING",
    "description": "Facility Type Enum: 'Store'/'Kitchen'"
  },
  {
    "name": "partnerId",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Pepper Partner ID, is NULL when stall is empty"
  },
  {
    "name": "number",
    "type": "INTEGER",
    "description": "Number of the stall, is unique in combination with 'type'"
  },
  {
    "name": "status",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Enum: 'Active'/'Inactive'"
  },
  {
    "name": "type",
    "type": "STRING",
    "description": "Enum: 'PRIMARY'/'INSULATOR'/'UNASSIGNED'"
  },
  {
    "name": "schedule",
    "type": "RECORD",
    "mode": "NULLABLE",
    "fields": [
      {
        "name": "onboarding",
        "type": "RECORD",
        "mode": "NULLABLE",
        "description": "onboarding details",
        "fields": [
          {
            "name": "partnerId",
            "type": "STRING",
            "description": "Pepper Partner ID"
          },
          {
            "mode": "NULLABLE",
            "name": "comment",
            "type": "STRING",
            "description": "Comment"
          },
          {
            "name": "scheduledAt",
            "type": "TIMESTAMP",
            "description": "Date of onboarding"
          },
          {
            "name": "createdBy",
            "type": "STRING",
            "description": "User who created the schedule"
          },
          {
            "name": "updatedBy",
            "type": "STRING",
            "description": "User who updated the schedule"
          }
        ]
      },
      {
        "name": "offboarding",
        "type": "RECORD",
        "mode": "NULLABLE",
        "description": "offboarding details",
        "fields": [
          {
            "name": "partnerId",
            "type": "STRING",
            "description": "Pepper Partner ID"
          },
          {
            "mode": "NULLABLE",
            "name": "comment",
            "type": "STRING",
            "description": "Comment"
          },
          {
            "name": "scheduledAt",
            "type": "TIMESTAMP",
            "description": "Date of offboarding"
          },
          {
            "name": "reasons",
            "type": "STRING",
            "mode": "REPEATED",
            "description": "reasons for offboarding. Enum:'CuisineNotFit'/'ForceChurn'/'NegativePnl/'PartnerHasOperationalIssue'/'PartnerOpeningOwnStore'/'Other'"
          },
          {
            "name": "createdBy",
            "type": "STRING",
            "description": "User who created the schedule"
          },
          {
            "name": "updatedBy",
            "type": "STRING",
            "description": "User who updated the schedule"
          }
        ]
      }
    ]
  },
  {
    "name": "createdAt",
    "type": "TIMESTAMP",
    "description": "When stall was added in Pepper"
  },
  {
    "name": "updatedAt",
    "type": "TIMESTAMP",
    "description": "When stall was last updated in Pepper"
  },
  {
    "name": "exportedAt",
    "type": "TIMESTAMP",
    "description": "When stall was last exported from Pepper"
  }
] as const;
export type StallSchema = typeof stallSchemaFields;