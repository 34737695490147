import { logger } from '@pepper/logger';
import { onError } from '@apollo/client/link/error';

/**
 * @see https://www.apollographql.com/docs/react/data/error-handling/#on-graphql-errors
 */
export const errorLink = onError(
  ({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) {
      for (let i = 0; i < graphQLErrors.length; i++) {
        logger.warn(
          {
            err: graphQLErrors[i],
            graphql: {
              operationName: operation.operationName,
            },
          },
          'GraphQL error iteration',
        );
      }
    }
    if (networkError) {
      logger.warn(
        {
          err: networkError,
          graphql: {
            operationName: operation.operationName,
          },
        },
        'GraphQL network error',
      );
    }
  },
);
