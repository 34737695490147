import React, { createContext, useMemo, useState } from 'react';
import { InferGetServerSidePropsType, NextPage } from 'next';
import { RecursivePartial, InferFullType, Nullable } from '@pepper/types';
import merge from 'deepmerge';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { useTranslation } from 'next-i18next';
import { config as envConfig } from '../config';
import type { GtmCategory } from '../hooks/gtm/gtm';

export interface PageConfigFull {
  title: string;
  titleSuffix: string;
  description: string;
  gtm?: {
    /**
     * This can be overridden at component level using [GtmProvider](../hooks/gtm/useGtm.tsx)
     */
    category?: GtmCategory;
    pageTitle?: string;
    /**
     * Any extra attributes to send to DataLayer
     */
    extra?: Record<string, string | undefined>;
  };
}

export type PageConfig = RecursivePartial<PageConfigFull>;

export const defaultPageConfig: PageConfigFull = {
  title: 'GFS Pepper',
  titleSuffix: 'GFS Pepper',
  description: 'In-Kitchen Solutions',
};

export type InferProps<P> =
  // @ts-expect-error type fix
  // eslint-disable-next-line @typescript-eslint/ban-types
  P extends Function ? InferGetServerSidePropsType<P> : P;

export type NextPageCustom<
  P = unknown,
  C extends PageConfig = PageConfig,
> = NextPage<InferProps<P>> & {
  config?:
    | ((
        pageProps: InferProps<P>,
        t: ReturnType<typeof useTranslation>['t'],
      ) => C)
    | C;
};

export interface IPageConfigContext<C extends PageConfig = PageConfig> {
  config: InferFullType<C>;
  setConfig: (config: C) => void;
}

export const PageConfigContext = createContext<IPageConfigContext>({
  config: defaultPageConfig,
  setConfig: (_config: PageConfig) => {},
});

const mergeConfig = <C extends PageConfigFull = PageConfigFull>(
  currentConfig: C,
  configOverride: RecursivePartial<C>,
): C => {
  if (isEmpty(configOverride)) {
    return currentConfig;
  }
  return merge(currentConfig, configOverride as Partial<C>) as C;
};

/**
 * Provides page specific configs for each app, including:
 * - meta tags (title, description)
 * - tracking (gtm)
 * - layout, etc
 *
 * Use with `usePageConfig` hook to get/update page level config dynamically
 */
export const PageConfigProvider = <C extends PageConfigFull = PageConfigFull>({
  children,
  pageProps,
  defaultConfig = defaultPageConfig as C,
  overrides,
}: {
  pageProps: Record<string, unknown>;
  children: React.ReactElement;
  defaultConfig?: C;
  overrides: Nullable<
    | RecursivePartial<C>
    | ((
        pageProps: any,
        t: ReturnType<typeof useTranslation>['t'],
      ) => RecursivePartial<C>)
  >;
}): JSX.Element => {
  const { t } = useTranslation();
  const [dynamicConfigOverride, setConfig] = useState<PageConfig>({});

  const value = useMemo(() => {
    let pageLevelOverrides: PageConfig = {};
    if (typeof overrides === 'function') {
      pageLevelOverrides = overrides(pageProps, t);
    } else if (overrides) {
      pageLevelOverrides = overrides;
    }

    let config: PageConfigFull = mergeConfig(
      mergeConfig(defaultConfig, pageLevelOverrides),
      dynamicConfigOverride,
    );

    if (envConfig.APP_ENV !== 'prod') {
      config = {
        ...config,
        title: `(${envConfig.APP_ENV}) ${config.title}`,
      };
    }

    return {
      config,
      setConfig: (configOverride: PageConfig) => {
        if (isEqual(dynamicConfigOverride, configOverride)) {
          return;
        }

        setConfig(configOverride);
      },
    };
  }, [overrides, defaultConfig, dynamicConfigOverride, pageProps, t]);

  return (
    <PageConfigContext.Provider value={value}>
      {children}
    </PageConfigContext.Provider>
  );
};
