import { APP_VERSION } from '../constants';

const SERVICE_NAME = (process.env.SERVICE_NAME || '') as ClientServices;

export type ClientServices =
  | 'gfs-cooking-app'
  | 'gfs-order-management'
  | 'gfs-pepper-admin'
  | 'gfs-pepper-auth'
  | 'gfs-sales-dashboard';

const APP_REDIRECT_MAP = {
  'gfs-pepper-admin': 'admin',
  'gfs-cooking-app': 'cook',
  'gfs-sales-dashboard': 'sales',
  'gfs-order-management': 'orders',
} as const;

export const REDIRECT_KEYS = Object.values(APP_REDIRECT_MAP);

const SUPPORT_CHANNELS: Partial<Record<ClientServices, string>> = {
  'gfs-sales-dashboard': 'gfs-pepper-sales-support',
};

export const APP_LOGOS: Record<ClientServices, string> = {
  'gfs-pepper-auth': '/shared/logos/pepper/horizontal.svg',
  'gfs-pepper-admin': '/shared/logos/pepper/admin/horizontal.svg',
  'gfs-cooking-app': '/shared/logos/pepper/cook/horizontal.svg',
  'gfs-order-management': '/shared/logos/pepper/orders/horizontal.svg',
  'gfs-sales-dashboard': '/shared/logos/pepper/sales/horizontal.svg',
};

const SUPPORT_CHANNEL = SUPPORT_CHANNELS[SERVICE_NAME] || 'gfs-pepper';

export const common = {
  APP_VERSION,
  APP_LOGO: APP_LOGOS[SERVICE_NAME] || '',
  APP_REDIRECT:
    (APP_REDIRECT_MAP as Partial<Record<ClientServices, string>>)[
      SERVICE_NAME
    ] || '',
  FAQ_LINK:
    'https://docs.google.com/document/d/e/2PACX-1vQQRj6uIjV5KsQP6a9nHGarZDz4k9vnXpdqCALGj8M6eGAslwSHvc2COkOamYDIa2TG5Jct1Fw5mlAg/pub',
  /**
   * Headway
   * @see https://headwayapp.co/gfs-pepper-sales-release-notes
   * Username : rohit.gohri@honestfoodcompany.de
   * Password : Check Vault
   * @see https://vault.deliveryhero.io/ui/vault/secrets/dh%2Fgfstech/kv/Dev_Tools/details?version=12
   */
  HW_CONFIG_ACCOUNT: '72YZRx',
  IAM_PORTAL_PLUGIN_URL:
    'https://portal-dh-euw-stg-vt.deliveryhero.io/de/p/dhh-iam',
  SERVICE_NAME,
  SLACK_SUPPORT_CHANNEL: `#${SUPPORT_CHANNEL}`,
  SLACK_SUPPORT_LINK: `https://deliveryhero.slack.com/app_redirect?channel=${SUPPORT_CHANNEL}`,
};
