/**
 * This file is used for local development and SSR
 */

const env = (process.env.DD_CLIENT_ENV || process.env.APP_ENV || 'local') as
  | 'local'
  | 'dev'
  | 'staging'
  | 'prod';

export const config = {
  APP_ENV: env,
  DD_CLIENT_ENV: env,
  DISABLE_PASSWORD_LOGIN: process.env.DISABLE_PASSWORD_LOGIN !== 'false',
  FWF_ENV_TOKEN: process.env.FWF_ENV_TOKEN || '',
  GTM_ID: process.env.GTM_ID || process.env.PEPPER_GTM_ID || '',
  AUTH_CLIENT_URL:
    process.env.AUTH_CLIENT_URL || 'http://pepper.gfs.localhost:4000',
  ADMIN_CLIENT_URL:
    process.env.ADMIN_CLIENT_URL || 'http://admin.gfs.localhost:4000',
  COOK_CLIENT_URL:
    process.env.COOK_CLIENT_URL || 'http://cook.gfs.localhost:4000',
  SALES_CLIENT_URL:
    process.env.SALES_CLIENT_URL || 'http://sales.gfs.localhost:4000',
  OMS_CLIENT_URL:
    process.env.OMS_CLIENT_URL || 'http://orders.gfs.localhost:4000',
  GRAPHQL_SERVER:
    process.env.GRAPHQL_SERVER || 'http://gks.gfs.localhost:4000/graphql',
  GRAPHQL_WEBSOCKET_SERVER:
    process.env.GRAPHQL_WEBSOCKET_SERVER ||
    'ws://gks.gfs.localhost:4000/graphql',
  SERVER_URL: process.env.SERVER_URL || 'http://gks.gfs.localhost:4000',
};
