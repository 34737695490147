import { useMemo } from 'react';
import { i18nConfig } from '../i18n/next-i18next.config';
import { useCookie } from './useCookie';

const { i18n } = i18nConfig;

const rtlLangs = ['ar', 'he', 'ur'];
export const LANGUAGE_COOKIE = 'language';

export function getLocaleFromCookies(
  cookies: Record<string, string | undefined>,
) {
  const cookieLang = cookies?.[LANGUAGE_COOKIE];
  const locale: string =
    cookieLang && i18n.locales.includes(cookieLang)
      ? cookieLang
      : i18n.defaultLocale;

  return {
    locale,
    locales: (i18n.locales ?? [locale]) as string[],
    isRtl: rtlLangs.includes(locale),
  };
}

/**
 * @description hook to get locale data and available locales
 * @param returnOptions - if true, will return locales options(for select)
 * @returns {Object} localeObject
 * @returns {string} localeObject.locale - current locale
 * @returns {string[]} localeObject.locales - available locales list from i18n config
 * @returns {boolean} localeObject.isRtl - is current locale rtl (@see https://developer.mozilla.org/en-US/docs/Glossary/RTL)
 * @returns {Object[]} localeObject.localeOptions - locales options (for select)
 */
export function useLocale({ returnOptions }: { returnOptions?: boolean } = {}) {
  const [localeCookie] = useCookie(LANGUAGE_COOKIE);

  return useMemo(() => {
    const { locale, locales, isRtl } = getLocaleFromCookies({
      [LANGUAGE_COOKIE]: localeCookie,
    });

    let localeOptions;
    if (returnOptions) {
      localeOptions =
        locales?.map((l) => ({
          label: `${l} - ${new Intl.DisplayNames([locale, 'en'], {
            type: 'language',
          }).of(l)}`,
          value: l,
        })) ?? [];
    }

    return {
      isRtl,
      locale,
      localeOptions,
      locales,
    };
  }, [localeCookie, returnOptions]);
}
