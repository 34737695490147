/* eslint-disable @typescript-eslint/ban-ts-comment */
import { isBrowser } from './browser';
import { logger } from './logger';

// @ts-ignore custom global
if (!globalThis.__logger_warning_registered__) {
  if (isBrowser) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore need to separate bundles for browser/server
    window.addEventListener('unhandledrejection', (event) => {
      logger.error(
        {
          err: event.reason,
        },
        'Unhandled Promise Rejection',
      );
    });
  } else if (typeof process !== 'undefined') {
    process.on('warning', (err) => {
      logger.warn({ err }, 'Process Warning');
    });
  }

  // @ts-ignore custom global
  globalThis.__logger_warning_registered__ = true;
}
