const env =
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore custom global
  globalThis.APP_ENV ||
  process.env.DD_CLIENT_ENV ||
  process.env.APP_ENV ||
  'local';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore separate browser/server bundles
export const isBrowser = typeof window !== 'undefined';

const clientToken = process.env.DD_CLIENT_TOKEN;
/**
 * NOTE: This is only available in browser env if process.env.DD_CLIENT_TOKEN is defined
 */
// eslint-disable-next-line import/no-mutable-exports
let datadogLogger: import('@datadog/browser-logs').Logger | undefined;
/**
 * NOTE: This is only available in browser env if
 * process.env.DD_CLIENT_TOKEN and process.env.DD_RUM_APPLICATION_ID
 * is defined
 */
// eslint-disable-next-line import/no-mutable-exports
let datadogRum: import('@datadog/browser-rum').RumGlobal | undefined;

/**
 * NOTE: We are doing this to avoid loading RUM when doing SSR
 */
if (clientToken && isBrowser) {
  // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
  const { datadogLogs } = require('@datadog/browser-logs');

  datadogLogger = datadogLogs.logger;

  datadogLogs.init({
    clientToken,
    env,
    site: 'datadoghq.eu',
    service: process.env.SERVICE_NAME,
    version: process.env.APP_VERSION || '0.0.0-default',
    forwardErrorsToLogs: true,
    sampleRate: 100,
    trackSessionAcrossSubdomains: true,
  });

  // https://docs.datadoghq.com/logs/log_collection/javascript/#change-the-destination
  // This will log errors in the console and not to datadog in local env
  if (env === 'local') {
    datadogLogs.logger.setHandler?.('console');
  } else {
    datadogLogs.logger.setHandler?.('http');
  }

  if (process.env.DD_RUM_APPLICATION_ID) {
    // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
    const browserRum = require('@datadog/browser-rum');

    datadogRum = browserRum.datadogRum;

    datadogRum?.init({
      applicationId: process.env.DD_RUM_APPLICATION_ID,
      clientToken,
      site: 'datadoghq.eu',
      service: process.env.SERVICE_NAME,
      env,
      version: process.env.APP_VERSION || '0.0.0-default',
      sampleRate: 100,
      trackInteractions: true,
      defaultPrivacyLevel: 'mask-user-input',
      replaySampleRate: 10,
      trackSessionAcrossSubdomains: true,
      allowedTracingOrigins: [
        /https:\/\/.*\.pepper\.deliveryhero\.io/,
        /https:\/\/[\w-]+\.\w+\.honestfoodtech\.com/,
      ],
    });

    datadogRum?.startSessionReplayRecording();
  }
}

export { datadogRum, datadogLogger };
