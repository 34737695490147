import { useCallback, useEffect, useRef } from 'react';
import type { NextRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

/**
 *
 * Ask for confirmation before changing page or leaving page (browser tab close or back button)
 *
 * @example
 * ```typescript
 * import { useConfirmLeave } from 'hooks/useConfirmLeave';
 *
 * const ExampleNotSPA = (props) => {
 *   const [value, setValue] = useState('');
 *
 *   useConfirmLeave(
 *    value !== '',
 *    'Discard changes? Unsaved changes will be discarded.'
 *   );
 *
 *   return (
 *     <input onChange={(event) => setValue(event.target.value)} value={value} />
 *   );
 * };
 * ```
 * * @example
 * ```typescript
 * // Example for SPA with client-side rendering
 * import { useConfirmLeave } from 'hooks/useConfirmLeave';
 * import { useRouter } from 'next/router';
 *
 * const ExampleSPA = (props) => {
 *   const [value, setValue] = useState('');
 *
 *   const router = useRouter();
 *   useConfirmLeave(
 *    value !== '',
 *    'Discard changes? Unsaved changes will be discarded.',
 *    router
 *   );
 *
 *   return (
 *     <input onChange={(event) => setValue(event.target.value)} value={value} />
 *   );
 * };
 * ```
 *
 * * @example
 * ```typescript
 * // Cancel button doesn't ask for confirmation
 * import { useConfirmLeave } from 'hooks/useConfirmLeave';
 * import { useRouter } from 'next/router';
 *
 * const ExampleSPA = (props) => {
 *   const [value, setValue] = useState('');
 *
 *   const router = useRouter();
 *   skipConfirmLeave = useConfirmLeave(
 *    value !== '',
 *    'Discard changes? Unsaved changes will be discarded.',
 *    router
 *   );
 *
 *   return (
 *     <input onChange={(event) => setValue(event.target.value)} value={value} />
 *     <button onClick={() => {
 *        skipConfirmLeave();
 *        router.push('/some-page');
 *      }}>Cancel</button>
 *   );
 * };
 * ```
 */
export type SkipConfirmLeaveHandler = () => void;

export const useConfirmLeave = (
  isEnabled: boolean,
  warningTextOverride?: string,
  router?: NextRouter,
): SkipConfirmLeaveHandler => {
  const { t } = useTranslation();
  const warningText =
    warningTextOverride ||
    t(
      'common:forms.discard_changes',
      'Discard changes? Unsaved changes will be discarded.',
    );

  const isSkippedRef = useRef(false);
  const skipConfirmLeaveHandler = useCallback(() => {
    isSkippedRef.current = true;
  }, [isSkippedRef]);

  useEffect(() => {
    if (!isEnabled || isSkippedRef.current) return;

    const handleWindowClose = (e: BeforeUnloadEvent): string | undefined => {
      if (!isEnabled || isSkippedRef.current) return;

      e.preventDefault();
      e.returnValue = warningText;

      skipConfirmLeaveHandler();
      return e.returnValue;
    };

    const handleBrowseAway = (path: string): void => {
      if (!isEnabled || !router || isSkippedRef.current) return;

      // Query parameter change are also triggers `routeChangeStart` event
      // We want to ignore those
      const clearedNewAndOldPathAreEqual =
        router.asPath.split('?')[0] === path.split('?')[0];
      if (clearedNewAndOldPathAreEqual) {
        return;
      }

      // eslint-disable-next-line no-alert
      if (window.confirm(warningText)) return;
      router.events.emit('routeChangeError');

      // Push history state, because browser back action changes link and changes history state
      // but we stay on the same page
      if (router && router.asPath !== window.location.pathname) {
        window.history.pushState('', '', router.asPath);
      }

      skipConfirmLeaveHandler();

      // The only way to cancel route change is throwing an error
      // @see https://github.com/vercel/next.js/discussions/32231
      // Please don't not change this to `new Error()`.
      // @next/react-dev-overlay will catch it and show it to user. It is very annoying
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw 'Abort route change. Please ignore this error.';
    };

    window.addEventListener('beforeunload', handleWindowClose);
    if (router) router.events.on('routeChangeStart', handleBrowseAway);

    return () => {
      window.removeEventListener('beforeunload', handleWindowClose);
      if (router) router.events.off('routeChangeStart', handleBrowseAway);
    };
  }, [isEnabled, router, skipConfirmLeaveHandler, warningText]);

  return skipConfirmLeaveHandler;
};
