import type { RecursivePartial } from '@pepper/types';
import { Theme } from '@chakra-ui/react';

export const Popover: RecursivePartial<Theme['components']['Popover']> = {
  baseStyle: {
    content: {
      padding: '0.4rem',
      borderRadius: '1rem',
      boxShadow:
        '0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)',
    },
    header: {
      px: 3,
      fontWeight: 'semibold',
    },
    closeButton: {
      m: 2,
      borderRadius: 'full',
      _hover: {
        backgroundColor: 'gray.200',
      },
      '.chakra-icon': {
        width: 3,
        height: 3,
      },
    },
  },
};
