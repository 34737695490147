import type { RecursivePartial } from '@pepper/types';
import { Theme } from '@chakra-ui/react';
import { colorFilters } from '../colors';

export const Input: RecursivePartial<Theme['components']['Input']> = {
  baseStyle: {
    element: {
      '> img': {
        w: 5,
        minW: 5,
      },
    },
    field: {
      p: 0,
      h: 9,
      maxH: 9,
      minH: 9,
      color: 'pepperBlack',
      _placeholder: {
        color: 'gray.700',
      },
      lineHeight: 5,
      _readOnly: {
        userSelect: 'text',
        outline: 'none',
        border: 'none',
        cursor: 'not-allowed',
        background: 'gray.100',
        _focus: {
          outline: 'none',
          border: 'none',
        },
      },
      _disabled: {
        outline: 'none',
        border: 'none',
        cursor: 'not-allowed',
        background: 'gray.100',
        _focus: {
          outline: 'none',
          border: 'none',
        },
      },
    },
  },
  variants: {
    outline: {
      field: {
        bg: 'white',
        border: '1px solid',
        borderRadius: 'md',
        borderColor: 'gray.500',
        '~ div > img': {
          filter: colorFilters.gray[900],
        },
        _placeholder: {
          bg: 'white',
        },
        _hover: {
          borderColor: 'gray.900',
        },
        _focus: {
          boxShadow: '0px',
          outline: '2px solid',
          outlineOffset: '1px',
          outlineColor: 'blue.300',
          border: '1px solid',
          borderColor: 'gray.900',
        },
        _disabled: {
          opacity: 1,
          border: '0px',
          fontWeight: 500,
          bg: 'gray.100',
          color: 'gray.600',
          '~ div > img': {
            filter: colorFilters.gray[600],
          },
          '::placeholder': {
            bg: 'gray.100',
            color: 'gray.500',
          },
        },
        _invalid: {
          boxShadow: '0px',
        },
        _readOnly: {
          border: '0px',
          bg: 'gray.100',
          userSelect: 'text',
          '::placeholder': {
            bg: 'gray.100',
            color: 'gray.700',
          },
        },
      },
    },
  },
  sizes: {
    sm: {
      field: {
        py: 2,
        paddingInlineStart: '13px',
      },
    },
  },
  defaultProps: {
    size: 'sm',
  },
};
