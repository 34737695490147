import type { RecursivePartial } from '@pepper/types';
import { Theme } from '@chakra-ui/react';

export const Accordion: RecursivePartial<Theme['components']['Accordion']> = {
  baseStyle: {
    root: {
      color: 'pepperBlack',
    },
    container: {
      marginBottom: 1,
      borderRadius: 'md',
      borderWidth: '1px',
      borderColor: 'gray.500',
      overflow: 'hidden',
      /**
       * Chakra's `_focus` works only for the button and panel, not the container.
       * NOTE: This hack doesn't work in firefox at the time of committing
       * @see https://developer.mozilla.org/en-US/docs/Web/CSS/:has
       */
      ':has(.chakra-accordion__button:focus)': {
        outlineWidth: '2px',
        outlineStyle: 'solid',
        outlineOffset: '1px',
        outlineColor: 'blue.300',
      },
      /**
       * Custom data attributes set in "DataTable" component
       */
      '&[data-disabled="true"]': {
        border: 0,
        background: 'gray.100',
        color: 'gray.700',
        '&[data-empty="true"]': {
          color: 'pepperBlack',
          border: '1px dashed',
          background: 'transparent',
          borderColor: 'gray.500',
          _hover: {
            borderColor: 'gray.900',
          },
        },
      },
      _hover: {
        borderColor: 'gray.900',
      },
    },
    panel: {
      padding: 0,
    },
  },
};
