import { logger } from '@pepper/logger';
import { setContext } from '@apollo/client/link/context';
import { APP_VERSION, REQUESTED_WITH } from '../constants';

let lastChecked = Date.now();

function versionCheck(header?: string | null): void {
  // Skip on SSR
  if (typeof window === 'undefined') {
    return;
  }
  const backendVersion = header || 'unknown';
  if (backendVersion === 'unknown' || APP_VERSION === 'unknown') {
    return;
  }

  lastChecked = Date.now();

  if (backendVersion !== APP_VERSION) {
    logger.info(
      {
        backendVersion,
        appVersion: APP_VERSION,
      },
      'Mismatch b/w versions. Reloading',
    );
    window.location.reload();
  }
}

export function createPeriodicVersionCheck(period: number): void {
  if (typeof window === 'undefined') return;
  setInterval(() => {
    // Only call health endpoint if no other API request in last time period
    if (new Date(lastChecked + period).getTime() > Date.now()) return;

    logger.debug('Calling API to check for version mismatch');
    fetch('/api/healthz')
      .then((response) => {
        versionCheck(response.headers.get('x-version'));
      })
      .catch(() => {});
  }, period);
}

export const versionLink = setContext((_, { headers }) => {
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      'X-Requested-With': REQUESTED_WITH,
    },
  };
});
