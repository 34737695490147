import { useMemo } from 'react';
import { useGtm } from '../gtm';
import { GtmProps, PushGtmParameters } from '../gtm/useGtm';

export type ModalGtmConfig = {
  props?: GtmProps;
} & PushGtmParameters[number];

export const useGtmModalActions = ({ props, ...rest }: ModalGtmConfig = {}) => {
  const { pushGtm } = useGtm(props);

  const gtmActions = useMemo(() => {
    return {
      onOpen: () => {
        pushGtm('opened', rest);
      },
      onClose: () => {
        pushGtm('closed', rest);
      },
      onSubmit: () => {
        pushGtm('submitted', rest);
      },
      onError: (error: Error) => {
        pushGtm('errored', { ...rest, error });
      },
    };
  }, [pushGtm, rest]);

  return gtmActions;
};
