/* eslint-disable */
/* This is a generated file (see scripts/schema.js), don't edit manually */
export const brandSchemaFields = [
  {
    "name": "id",
    "type": "STRING",
    "description": "Pepper Brand ID"
  },
  {
    "name": "name",
    "type": "STRING",
    "description": "Brand name"
  },
  {
    "name": "brandName",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "**DEPRECATED** use 'name' instead"
  },
  {
    "name": "countryCode",
    "type": "STRING",
    "description": "ISO 2 letter Country Code"
  },
  {
    "name": "assigneeId",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Brand's latest assignee user id in Pepper"
  },
  {
    "name": "partnerId",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Pepper Partner ID"
  },
  {
    "name": "status",
    "type": "STRING",
    "description": "Brand current status (History): New / InPipeline / Live / Approached"
  },
  {
    "name": "pipelineStatus",
    "type": "STRING",
    "description": "Brand's pipeline status: None (i.e. in brand pool) / ColdLead / InTraction / InProgress (i.e. Negotiation, VerballyAgreed, ContractSent, WaitingList) "
  },
  {
    "name": "flag",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Brand flag: None / AmSupportNeeded / AmSupportResolved"
  },
  {
    "name": "mainCuisine",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Main cuisine of lead brand"
  },
  {
    "name": "category",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Brand category: Gold,Silver,Bronze,N/A,"
  },
  {
    "name": "notes",
    "type": "RECORD",
    "mode": "REPEATED",
    "fields": [
      {
        "name": "comment",
        "type": "STRING",
        "description": "Comment body"
      },
      {
        "name": "flag",
        "type": "STRING",
        "mode": "NULLABLE",
        "description": "Brand flag: None / AmSupportNeeded / AmSupportResolved"
      },
      {
        "name": "createdBy",
        "type": "STRING",
        "mode": "NULLABLE",
        "description": "Pepper user ID of who added note"
      },
      {
        "name": "createdAt",
        "type": "TIMESTAMP",
        "description": "When note was added"
      }
    ]
  },
  {
    "name": "predictions",
    "type": "RECORD",
    "fields": [
      {
        "name": "averageBrandScore",
        "type": "NUMERIC",
        "mode": "NULLABLE",
        "description": "Average score of eligible leads of brand"
      }
    ]
  },
  {
    "name": "recommendation",
    "type": "RECORD",
    "fields": [
      {
        "name": "isRecommended",
        "type": "BOOLEAN",
        "mode": "NULLABLE",
        "description": "True if brand was recommended"
      },
      {
        "name": "isSkipped",
        "type": "BOOLEAN",
        "mode": "NULLABLE",
        "description": "True if brand was skipped from recommendations"
      },
      {
        "name": "skipReason",
        "type": "STRING",
        "mode": "NULLABLE",
        "description": "Why brand was skipped from recommendations"
      },
      {
        "name": "skippedBy",
        "type": "STRING",
        "mode": "NULLABLE",
        "description": "Pepper user ID of who skipped"
      },
      {
        "name": "skippedAt",
        "type": "TIMESTAMP",
        "mode": "NULLABLE",
        "description": "When brand was skipped recommendations"
      },
      {
        "name": "createdAt",
        "type": "TIMESTAMP",
        "mode": "NULLABLE",
        "description": "When brand was recommended"
      }
    ]
  },
  {
    "name": "isArchived",
    "type": "BOOLEAN",
    "mode": "NULLABLE",
    "description": "True if brand is archived"
  },
  {
    "name": "createdAt",
    "type": "TIMESTAMP",
    "description": "When brand was added in Pepper"
  },
  {
    "name": "updatedAt",
    "type": "TIMESTAMP",
    "description": "When brand was last updated in Pepper"
  },
  {
    "name": "exportedAt",
    "type": "TIMESTAMP",
    "description": "When brand was last exported from Pepper"
  }
] as const;
export type BrandSchema = typeof brandSchemaFields;