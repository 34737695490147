import { useEffect, useState } from 'react';
import { logger } from '@pepper/logger';
import { isBrowser } from '../helpers';

const prefixedFullscreenApiKeys = (() => {
  const apiMap = [
    [
      'webkitRequestFullscreen',
      'webkitExitFullscreen',
      'webkitFullscreenElement',
      'webkitfullscreenchange',
    ],
    [
      'mozRequestFullScreen',
      'mozCancelFullScreen',
      'mozFullScreenElement',
      'mozfullscreenchange',
    ],
  ];

  if (isBrowser()) {
    for (const methodList of apiMap) {
      if (methodList[1] in document) {
        return {
          requestFullscreen: methodList[0] as keyof HTMLElement,
          exitFullscreen: methodList[1] as keyof Document,
          fullscreenElement: methodList[2] as keyof Document,
          fullscreenChangeEvent: methodList[3],
        };
      }
    }
  }

  return {
    requestFullscreen: 'requestFullscreen' as keyof HTMLElement,
    exitFullscreen: 'exitFullscreen' as keyof Document,
    fullscreenElement: 'fullscreenElement' as keyof Document,
    fullscreenChangeEvent: 'fullscreenchange' as const,
  };
})();

const {
  fullscreenElement,
  fullscreenChangeEvent,
  requestFullscreen,
  exitFullscreen,
} = prefixedFullscreenApiKeys;

const getFullScreenElement = () => document?.[fullscreenElement];

export const useFullScreen = () => {
  const isClient = isBrowser();
  const fullScreenElement = isClient && getFullScreenElement();
  const [isFullScreen, setIsFullScreen] = useState(!!fullScreenElement);

  const updateState = () => {
    setIsFullScreen(!!getFullScreenElement());
  };

  useEffect(() => {
    document.addEventListener(fullscreenChangeEvent, () => {
      updateState();
    });
    return () => {
      document.removeEventListener(fullscreenChangeEvent, () => {
        updateState();
      });
    };
  }, []);

  const enterFullScreen = async () => {
    try {
      if (isClient && !isFullScreen) {
        const request = document.documentElement?.[
          requestFullscreen
        ] as () => Promise<void>;
        // have to explicitly add the documentElement context to prevent Uncaught TypeError: Illegal invocation
        await request.call(document.documentElement);
      }
    } catch (err) {
      if ((err as Error)?.message !== 'fullscreen error') {
        logger.error({ err }, 'Error while entering fullscreen');
      }
    }
  };

  const exitFullScreen = async () => {
    try {
      if (isClient && isFullScreen) {
        const exit = document[exitFullscreen] as () => Promise<void>;
        // have to explicitly add the document context to prevent Uncaught TypeError: Illegal invocation
        await exit.call(document);
      }
    } catch (err) {
      if ((err as Error)?.message !== 'fullscreen error') {
        logger.error({ err }, 'Error while exiting fullscreen');
      }
    }
  };

  return {
    enterFullScreen,
    exitFullScreen,
    isFullScreen,
  };
};
