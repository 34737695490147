import { logger } from '@pepper/logger';
import { ConnectionParams } from '@pepper/types';
import { AccountsClient } from '@accounts/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { config } from '../config';
import { REQUESTED_WITH } from '../constants';
import { getLanguageHeader } from './languageLink';
import { networkStateVar } from './reactiveVariables';

const webSocketUri = config.GRAPHQL_WEBSOCKET_SERVER;

export const getWebsocketLink = (
  accountsClientFactory: () => AccountsClient,
): {
  link: GraphQLWsLink;
  restart: () => void;
  onReconnected: (cb: () => Promise<void> | void) => void;
} => {
  let restartRequested = false;
  let restart = () => {
    restartRequested = true;
  };

  /**
   * @see https://the-guild.dev/graphql/ws/recipes#client-usage-with-reconnect-listener
   */
  let abruptlyClosed = false;
  const reconnectedCbs: (() => Promise<void> | void)[] = [];

  const link = new GraphQLWsLink(
    createClient({
      url: `${webSocketUri}`,
      connectionParams: async (): Promise<ConnectionParams> => {
        const tokens = await accountsClientFactory().refreshSession();
        const languageHeader = getLanguageHeader();

        return {
          headers: {
            Authorization: `Bearer ${tokens?.accessToken}`,
            'X-Requested-With': REQUESTED_WITH,
            ...(languageHeader
              ? {
                  'Accept-Language': languageHeader,
                }
              : {}),
          },
        };
      },
      /**
       * @see https://github.com/enisdenjo/graphql-ws/issues/122#issue-813850625
       */
      isFatalConnectionProblem: () => {
        return false;
      },
      retryAttempts: 10,
      on: {
        opened: (socket: unknown) => {
          restart = () => {
            if ((socket as WebSocket).readyState === WebSocket.OPEN) {
              // if the socket is still open for the restart, do the restart
              (socket as WebSocket).close(4205, 'Client Restart');
            } else {
              // otherwise the socket might've closed, indicate that you want
              // a restart on the next opened event
              restartRequested = true;
            }
          };

          // just in case you were eager to restart
          if (restartRequested) {
            restartRequested = false;
            restart();
          }
        },
        connected: async () => {
          networkStateVar({
            isConnected: true,
            type: 'socket',
          });

          if (abruptlyClosed) {
            abruptlyClosed = false;
            await Promise.all(reconnectedCbs.map((cb) => cb()));
          }
        },
        message: () => {
          networkStateVar({
            isConnected: true,
            type: 'socket',
          });
        },
        closed: async (event: unknown) => {
          networkStateVar({
            isConnected: false,
            type: 'socket',
          });

          // non-1000 close codes are abrupt closes
          if ((event as CloseEvent).code !== 1000) {
            abruptlyClosed = true;
          }

          // if closed with the `4403: Forbidden` close event
          // the client or the server is communicating that the token
          // is no longer valid and should be therefore refreshed
          if ((event as CloseEvent).code === 4403) {
            const tokens = await accountsClientFactory().refreshSession(true);

            if (!tokens && typeof window !== 'undefined') {
              // Force re-login
              window.location.reload();
            }
          }
        },
        error: async (err: unknown) => {
          logger.error({ err }, `Error occurred in GraphQL subscription`);
        },
      },
    }),
  );

  return {
    link,
    onReconnected: (cb) => {
      reconnectedCbs.push(cb);
      return () => {
        reconnectedCbs.splice(reconnectedCbs.indexOf(cb), 1);
      };
    },
    restart: () => restart(),
  };
};
