import { ComponentSingleStyleConfig } from '@chakra-ui/react';

export const Text: ComponentSingleStyleConfig = {
  variants: {
    help: {
      fontSize: 'sm',
      fontWeight: 'normal',
      color: 'gray.700',
    },
  },
};
