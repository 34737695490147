import { useMemo } from 'react';

export const useTableauLink = (
  path: string,
  params?: {
    Country?: string;
    countryName?: string;
    brandName?: string;
    facilityName?: string;
  },
) => {
  return useMemo(() => {
    const host = `https://tableau.deliveryhero.net/#/site/GlobalStandardReporting/views`;
    const paramObj: Record<string, string> = {};

    if (params?.Country) {
      paramObj.Country = params.Country || '';
    }

    if (params?.countryName) {
      paramObj.country_name = params.countryName || '';
    }

    if (params?.brandName) {
      paramObj.Brand = params.brandName || '';
    }

    if (params?.facilityName) {
      paramObj.kitchen_name = params.facilityName || '';
    }

    const urlParams = new URLSearchParams(paramObj);
    return `${host}/${path}?${urlParams.toString()}`;
  }, [path, params]);
};
