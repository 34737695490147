import type { RecursivePartial } from '@pepper/types';
import { Theme } from '@chakra-ui/react';

export const FormLabel: RecursivePartial<Theme['components']['FormLabel']> = {
  baseStyle: {
    color: 'pepperBlack',
    fontWeight: 600,
    fontSize: 'sm',
    lineHeight: 5,
    _disabled: {
      opacity: 1,
    },
  },
};
