import moment from 'moment';

// Gets the time difference by substracting targetTime with now.
// If reference time is passed, difference is calculated by subtracting target time and reference time
export function getTargetTimeDifference(
  targetTime: Date,
  referenceTime?: Date,
): {
  minutes: number;
  diffInSeconds: number;
  absoluteDiff: number;
  secs: number;
} {
  const diffInSeconds = moment(targetTime).diff(
    referenceTime ? moment(referenceTime) : moment(),
    'seconds',
  );
  const absoluteDiff = Math.abs(diffInSeconds);
  const duration = moment.duration(absoluteDiff, 'seconds');
  const minutes = Math.floor(duration.asMinutes());
  const secs = duration.seconds();

  return {
    diffInSeconds,
    minutes,
    secs,
    absoluteDiff,
  };
}
