import type { RecursivePartial } from '@pepper/types';
import { Theme } from '@chakra-ui/react';

export const Radio: RecursivePartial<Theme['components']['Radio']> = {
  baseStyle: {
    label: {
      color: 'pepperBlack',
      my: 1,
      _disabled: {
        fontWeight: 'medium',
      },
    },
    container: {
      alignItems: 'start',
    },
    control: {
      mt: 1,
      background: 'white',
      border: '2px solid',
      borderColor: 'gray.500',
      _hover: {
        borderColor: 'gray.900',
      },
      _invalid: {
        borderColor: 'gray.500',
        _hover: {
          borderColor: 'gray.900',
        },
        _checked: {
          color: 'blue.500',
          borderColor: 'blue.500',
          background: 'white',
          _focus: {
            boxShadow: 'unset',
            outline: '2px solid',
            outlineOffset: '1.5px',
            outlineColor: 'blue.300',
          },
          _hover: {
            background: 'white',
            color: 'blue.600',
          },
        },
      },
      _disabled: {
        color: 'gray.100',
        borderColor: 'gray.500',
        _hover: {
          borderColor: 'gray.500',
        },
        _checked: {
          color: 'white',
          background: 'gray.500',
          borderColor: 'gray.500',
        },
      },
      _checked: {
        color: 'blue.500',
        background: 'white',
        _focus: {
          boxShadow: 'unset',
          outline: '2px solid',
          outlineOffset: '1.5px',
          outlineColor: 'blue.300',
        },
        _hover: {
          background: 'white',
          color: 'blue.600',
        },
      },
    },
  },
  sizes: {
    md: {
      label: { fontSize: 'sm', lineHeight: 5 },
      control: {
        w: 5,
        h: 5,
      },
    },
  },
};
