import React from 'react';
import { useRouter } from 'next/router';
import { Icon } from '@deliveryhero/gfs-ui';
import { Link } from '@chakra-ui/next-js';
import { Text, Flex, Tooltip } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import { useGtm } from '../../hooks/gtm';
import { useUserContext } from '../../hooks/useUser';
import { Pages } from '../../pages';
import {
  activePageStyles,
  groupStyles,
  overflowEllipses,
  sidebarTextAnimations,
  sidebarItemAnimations,
  SidebarItem,
} from './SidebarItem';

const SignOutItem = {
  icon: {
    normal: '/shared/icons/logout.svg',
  },
};

export const ProfileLinks = ({ isOpen }: { isOpen: boolean }) => {
  const { pathname } = useRouter();
  const { pushGtm } = useGtm();
  const { user, logout, isImpersonated } = useUserContext();
  const { t } = useTranslation();
  const isProfileActive = pathname === Pages.Admin.USER_PROFILE;
  const signOutLabel = isImpersonated
    ? t('common:user.stop_impersonation')
    : t('common:user.sign_out');

  return (
    <>
      <Link
        href={Pages.Admin.USER_PROFILE}
        shallow
        target={Pages.Admin.USER_PROFILE.startsWith('/') ? undefined : '_blank'}
        textDecoration="none !important"
        boxShadow="none !important"
        paddingStart={0.5}
        marginBottom={isOpen ? undefined : 4}
        aria-current={isProfileActive ? 'page' : undefined}
        _activeLink={activePageStyles}
        onClick={() => pushGtm('clicked', { label: 'Profile' })}
      >
        <Tooltip
          label={
            // eslint-disable-next-line no-nested-ternary
            isImpersonated
              ? t('common:user.impersonation_notice')
              : isOpen
              ? ''
              : t('common:menu.profile')
          }
          placement="right"
          offset={[0, 24]}
        >
          <Flex
            as={motion.div}
            variants={sidebarItemAnimations}
            initial="close"
            animate={isOpen ? 'open' : 'close'}
            role="group"
            sx={{ ...groupStyles, alignItems: 'start' }}
            h={isOpen ? '52px' : 9}
            data-active={isProfileActive || undefined}
          >
            <Icon
              image={
                isImpersonated
                  ? 'change-user'
                  : `user-profile-${isProfileActive ? 'solid' : 'outline'}`
              }
              color={
                // eslint-disable-next-line no-nested-ternary
                isImpersonated
                  ? 'red.500'
                  : isProfileActive
                  ? 'blue.500'
                  : 'gray.900'
              }
              aria-hidden
              margin={2}
            />
            {isOpen && (
              <Flex
                as={motion.div}
                variants={sidebarTextAnimations}
                initial="close"
                animate="open"
                exit="close"
                mt={1}
                direction="column"
                fontSize={14}
                sx={overflowEllipses}
              >
                <Text sx={overflowEllipses}>{user?.fullName}</Text>
                <Text
                  sx={overflowEllipses}
                  fontWeight="normal"
                  color="gray.700"
                >
                  {user?.access.label}
                  {user.access.isSSO ? ` (SSO 🔒)` : ''}
                </Text>
              </Flex>
            )}
          </Flex>
        </Tooltip>
      </Link>
      <SidebarItem
        data-testid="logout-box"
        name={signOutLabel}
        isActive={false}
        isOpen={isOpen}
        href={Pages.Auth.LOGIN}
        onClick={() => {
          pushGtm('clicked', {
            label: isImpersonated ? 'Stop Impersonation' : 'Sign Out',
          });
          return logout();
        }}
        icon={SignOutItem.icon}
      />
    </>
  );
};
