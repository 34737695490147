import { FormControlOptions } from '@chakra-ui/react';
import { shouldForwardProp, ChakraProps } from '@chakra-ui/system';

const ignoredProps = new Set([
  'isMulti',
  'format',
  'isLoading',
  'isRangeVisible',
  'isLabelInline',
  'defaultValue',
  'closeMenuOnSelect',
  'autoComplete',
  'maxLength',
  'data-testid',
]);

const customProps = new Set([
  'isRequired',
  'isDisabled',
  'isInvalid',
  'isReadOnly',
  'className',
]);

/**
 * Form control also needs props like disabled/required/etc to apply stylings to label
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const formForwardProps = (props: any) => {
  const formControlProps: Record<string, unknown> = {};
  const rest: Record<string, unknown> = {};
  const custom: Record<string, unknown> = {};
  Object.entries(props).forEach(
    ([propKey, val]: [propKey: string, val: unknown]) => {
      if (ignoredProps.has(propKey)) {
        rest[propKey] = val;
      } else if (!shouldForwardProp(propKey) || customProps.has(propKey)) {
        formControlProps[propKey] = val;
        if (customProps.has(propKey)) {
          custom[propKey] = val;
        }
      } else {
        rest[propKey] = val;
      }
    },
  );
  return {
    formControlProps,
    rest,
    custom,
  };
};

export interface FormCommonProps extends ChakraProps, FormControlOptions {
  label?: string;
  isLoading?: boolean;
  /**
   * Render the component with inline label. Used for filters
   */
  isLabelInline?: boolean;
  /**
   * Show help text
   */
  help?: React.ReactElement | string;
  'data-testid'?: string;
}
