import { GetServerSidePropsContext } from 'next';
import { AccountsClient } from '@accounts/client';
import { AccountsClientPassword } from '@accounts/client-password';
import { useApolloClient } from '@apollo/client';
import decode from 'jwt-decode';
import { getApolloClient, CustomApolloClient } from '../apollo';
import { initAccounts } from './accountsClient';
import type { UserDetailsFragment as UserDetailsGraphQL } from './userFields.fragment.generated';

export type UserDetailsFragment = UserDetailsGraphQL & {
  impersonator?: AccountsPayload | null;
};

export interface AccountsPayload {
  data?: {
    token: string;
    userId: string;
  };
  exp: number;
  iat: number;
}

export async function getUser(
  ctx: GetServerSidePropsContext,
): Promise<UserDetailsFragment | null> {
  const { accountsClient } = getApolloClient(ctx);
  const user =
    (await accountsClient.getUser()) as unknown as UserDetailsFragment;

  if (!user) {
    return user;
  }

  const originalTokens = await accountsClient.getTokens(true);
  if (originalTokens) {
    return {
      ...user,
      impersonator: decode(originalTokens.accessToken),
    };
  }

  return user;
}

export function useAccounts(): {
  accountsClient: AccountsClient;
  accountsClientPassword: AccountsClientPassword;
} {
  const apollo = useApolloClient() as CustomApolloClient;
  if (apollo.accountsClient && apollo.accountsClientPassword) {
    return {
      accountsClient: apollo.accountsClient,
      accountsClientPassword: apollo.accountsClientPassword,
    };
  }

  const accounts = initAccounts(apollo);

  return accounts;
}
