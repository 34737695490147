import { UrlObject } from 'url';

/**
 * Use this for Next Link
 */
export const urlSearchParamsToUrl = (params: URLSearchParams): UrlObject => {
  if (params.toString().length === 0) {
    return {};
  }
  params.sort();
  return { query: Object.fromEntries(params.entries()) };
};

/**
 * Use this when creating absolute urls
 */
export const urlSearchParamsToHref = (params: URLSearchParams): string => {
  if (params.toString().length === 0) {
    return '';
  }
  params.sort();
  return `?${params.toString()}`;
};
