export * from './constants';
export * from './formatters';
export * from './url';
export * from './datetime';
export * from './orders';

export function isObjectId(idOrNot: string): boolean {
  return /^[a-f\d]{24}$/i.test(idOrNot);
}

export function isBrowser() {
  return typeof window !== 'undefined';
}
