import { useCallback } from 'react';
import { NextRouter } from 'next/router';
import { useDisclosure, useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ModalConfig } from './types';
import { useGtmModalActions } from './useGtmModalActions';
import { useUrlParamsModal } from './useUrlParamsModal';

/**
 * Custom single-entrypoint hook for managing modal state and actions.
 * @param config - Initial modal params.
 * @param router - optional NextRouter which if passed controls the modal with url params
 * @returns - Object containing modal state and actions.
 *
 * @note
 * 1. Always use useModal hook for the modals state management.
 *    useUrlParams is only used to encapsulate the URL params management
 * 2. Pass the router object to the useModal if you need the modal to either:
 *     - be shareable via URL
 *     - or be triggered from multiple different places of the application
 *     - or the state of the modal has to be accessible from multiple different places
 * 3. In any other cases simply use the useModal without router
 *
 *  * @note
 * Modal URL params
 * - if you need to change the values of modal URL parameters - use getModalUrlWithUpdatedParams
 * helper exported along with useModal
 *
 * @note
 * Modal URL params format
 * - We use  `m_modal` prefixed flattened parameters to manage the modals
 * (similar to what we have in packages/ui/src/hooks/useFilters/useChangedFields.ts).
 * - Use kebab-case to specify modal ids in configs
 * - All the parameters values must be in JSON format
 *
 * @note
 * GTM tracking:
 * useModal hook automatically pushes the gtm events on modal open, close, submit and error.
 * Make sure your component is wrapped with `withGtm` hook or a page has GTM category set in page config
 */
export const useModal = (
  config: ModalConfig,
  router?: NextRouter,
): {
  isOpen: boolean;
  actions: {
    openModal: (modalData?: Record<string, unknown>) => void;
    closeModal: () => void;
    submit: () => Promise<void>;
    getModalData?: <Data extends Record<string, unknown>>() => Data;
  };
} => {
  const {
    isOpen,
    onOpen: openDisclosure,
    onClose: closeDisclosure,
  } = useDisclosure();
  const { t } = useTranslation();
  const toast = useToast();
  const gtmActions = useGtmModalActions(config.gtm);
  const {
    isOpen: isUrlParamsModalOpen,
    actions: {
      openModal: openWithRouterParams,
      closeModal: closeWithRouterParams,
      getModalData,
    },
  } = useUrlParamsModal(config, router);

  const open = useCallback(() => {
    openDisclosure();

    if (config.onOpen) {
      config.onOpen();
    }

    gtmActions.onOpen();
  }, [openDisclosure, config, gtmActions]);

  const close = useCallback(() => {
    closeDisclosure();

    if (config.onClose) {
      config.onClose();
    }
    gtmActions.onClose();
  }, [closeDisclosure, config, gtmActions]);

  const submit = useCallback(async () => {
    try {
      if (config.onSubmit) {
        await config.onSubmit();
      }

      gtmActions.onSubmit();

      close();
    } catch (error) {
      gtmActions.onError(error as Error);

      if (config.onError) {
        config.onError(error as Error);
      } else {
        toast({
          title: t('common:errors.unknown'),
          description: (error as Error).message,
          status: 'error',
        });
      }
    }
  }, [close, config, gtmActions, t, toast]);

  if (router) {
    return {
      isOpen: isUrlParamsModalOpen,
      actions: {
        openModal: openWithRouterParams,
        closeModal: closeWithRouterParams,
        submit,
        getModalData,
      },
    };
  }

  return {
    isOpen,
    actions: {
      openModal: open,
      closeModal: close,
      submit,
    },
  };
};
