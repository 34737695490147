import React from 'react';
import { NextPage } from 'next';
import type { AppProps } from 'next/app';
import {
  withTranslations,
  NextPageCustom,
  AllProviders,
  UserError,
} from '@pepper/client';
import { CustomThemeProvider } from '@pepper/theme';
import { Layout } from './_app/Layout';

const MyApp: NextPage<
  AppProps & {
    Component: NextPageCustom;
  }
> = ({ Component, pageProps }) => {
  return (
    <CustomThemeProvider>
      <AllProviders pageProps={pageProps} configOverrides={Component.config}>
        <Layout>
          {pageProps.statusCode >= 400 ? (
            <UserError
              error={pageProps.error}
              statusCode={pageProps.statusCode}
            />
          ) : (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <Component {...pageProps} />
          )}
        </Layout>
      </AllProviders>
    </CustomThemeProvider>
  );
};

export default withTranslations(MyApp);
