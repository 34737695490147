import { useToast as useDefaultToast } from '@chakra-ui/react';

/**
 * Chakra's `useToast` with default options
 */
export const useToast: typeof useDefaultToast = (options) => {
  return useDefaultToast({
    duration: 5000,
    isClosable: true,
    status: 'success',
    position: 'top-right',
    ...options,
  });
};
