import type { RecursivePartial } from '@pepper/types';
import { Theme } from '@chakra-ui/react';

export const ellipsisCss = {
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  maxWidth: 0, // needed for ellipsis to work in table cell
};

export const Table: RecursivePartial<Theme['components']['Table']> = {
  defaultProps: {
    colorScheme: 'gray',
  },
  baseStyle: {
    table: {
      color: 'pepperBlack',
    },
    thead: {
      '> tr': {
        background: 'transparent',
      },
    },
    th: {
      textAlign: 'left',
      textTransform: 'none',
      fontWeight: 'semibold',
      letterSpacing: 'unset',
      fontSize: 'xs',
      ...ellipsisCss,
    },
    tbody: {
      _empty: {
        display: 'none',
        visibility: 'hidden',
      },
      '> tr': {
        _hover: {
          background: 'gray.50',
          '&[data-disabled="true"]': {
            background: 'gray.100',
            color: 'gray.700',
          },
        },
      },
    },
    tr: {
      '> td:first-of-type': {
        fontWeight: 'semibold',
      },
      '&[data-disabled="true"]': {
        background: 'gray.100',
        color: 'gray.700',
      },
    },
    td: {
      fontSize: 'sm',
      textAlign: 'left',
      ...ellipsisCss,
    },
  },
  variants: {
    simple: {
      th: {
        py: 2,
        px: 4,
        color: 'gray.900',
        border: '0px',
      },
      tbody: {
        /**
         * FIXME: Background color is not clipped and white corners pop-out of border
         * Probably redo all borders in table
         * @see https://stackoverflow.com/a/2586780
         */
        border: '0px',
        borderBottom: '1px solid',
        borderColor: 'gray.500',
        borderRadius: '0',
        shadow: 'border',
        overflow: 'hidden',
        background: 'white',
        '> tr:last-of-type > td': {
          // Last row doesn't need border since body has it
          borderBottom: 0,
        },
      },
      td: {
        py: 3,
        px: 4,
        borderBottom: '1px solid',
        borderBottomColor: 'gray.500',
      },
      tr: {
        '> td:first-of-type': {
          borderLeft: '1px solid',
          borderColor: 'gray.500',
        },
        '> td:last-of-type': {
          borderRight: '1px solid',
          borderColor: 'gray.500',
        },
      },
    },
  },
};
