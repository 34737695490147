/**
 * NOTE: Also update `helm/common/staging.yaml` if changing file
 */

export const config = {
  APP_ENV: 'staging' as const,
  DD_CLIENT_ENV: 'staging' as const,
  DISABLE_PASSWORD_LOGIN: false,
  FWF_ENV_TOKEN: 'cfedf6d7-a2b9-45aa-8217-2c0e52b6d0f5',
  GTM_ID: 'GTM-NZ8RKC6',
  ADMIN_CLIENT_URL: 'https://admin.staging.honestfoodtech.com',
  AUTH_CLIENT_URL: 'https://pepper.staging.honestfoodtech.com',
  COOK_CLIENT_URL: 'https://cook.staging.honestfoodtech.com',
  SALES_CLIENT_URL: 'https://sales.staging.honestfoodtech.com',
  OMS_CLIENT_URL: 'https://orders.staging.honestfoodtech.com',
  GRAPHQL_SERVER: '/graphql',
  GRAPHQL_WEBSOCKET_SERVER:
    'wss://kitchen-screens.staging.honestfoodtech.com/graphql',
  SERVER_URL: 'https://kitchen-screens.staging.honestfoodtech.com',
};
