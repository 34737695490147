import { RecursiveExtend } from '@pepper/types';
import {
  theme as defaultTheme,
  extendTheme,
  withDefaultColorScheme,
  Theme,
} from '@chakra-ui/react';
import { colors } from './colors';
import { components } from './components';
import { styles } from './global';

interface CustomThemeFields {
  colors: {
    pepperBlack: string;
    filters: typeof colors.filters;
  };
  shadows: {
    border: string;
    borderInset: string;
    hover: string;
  };
}

export type CustomTheme = Theme & CustomThemeFields;

const DEFAULT_FONT = `Inter, 'Source Sans Pro', -apple-system, BlinkMacSystemFont,
Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
Helvetica Neue, sans-serif`;

const colorScheme = 'blue' as const;

const custom: RecursiveExtend<CustomTheme> = {
  colors,
  components,
  config: {
    /**
     * TODO: GKS-1065 Enable dark mode
     */
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
  fonts: {
    heading: DEFAULT_FONT,
    body: DEFAULT_FONT,
  },
  radii: {
    md: '0.25rem',
  },
  /**
   * From Design System
   * @see https://www.figma.com/file/1pTB216ruZ3nLDU83X08YR/Pepper-Design-System?type=design&node-id=1819-774&t=Oh1H7SuBuwzUdZbO-0
   *
   * Percentage alpha values converted to hex using this mapping:
   * @see https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4
   */
  shadows: {
    xs: `0px 2px 8px ${colors.pepperBlack}3D`,
    sm: `0px 4px 16px ${colors.pepperBlack}3D`,
    md: `0px 8px 24px ${colors.pepperBlack}3D`,
    lg: `0px 12px 32px ${colors.pepperBlack}3D`,
    xl: `0px 16px 48px ${colors.pepperBlack}3D`,
    '2xl': `0px 20px 64px ${colors.pepperBlack}3D`,

    border: `0 0 0 1px ${colors.gray?.[500]}`,
    borderInset: `inset 0 0 0 1px ${colors.gray?.[500]}`,
    hover: `0 0 0 1px ${colors.gray?.[900]}`,
    outline: `0 0 0 3px ${
      (colors[colorScheme] || defaultTheme.colors[colorScheme])[400]
    }99`,
  },
};

export const baseTheme = extendTheme(
  withDefaultColorScheme({ colorScheme }),
  custom,
) as CustomTheme;

export const theme = extendTheme(baseTheme, { styles }) as CustomTheme;
