import React from 'react';
import { pushGtmTrackEvent } from '@pepper/client/hooks';
import { Box, useDisclosure } from '@chakra-ui/react';
import { AnimatePresence, motion, useMotionValue } from 'framer-motion';
import { ErrorBoundary } from '../ErrorBoundary';
import { SidebarProps, Sidebar } from './Sidebar';

/**
 * @see https://www.figma.com/file/BooFSSbgWFp17WdKk3dB1v/Facility-and-Partner-Configuration?type=design&node-id=4136-38369&mode=design&t=lwLZIryQSaGr6NVc-4
 */
export const SidebarLayout = ({
  onChange,
  children,
  ...props
}: React.PropsWithChildren<
  Pick<SidebarProps, 'sections' | 'showCountrySelector'> & {
    onChange?: (val: boolean) => void;
  }
>) => {
  const { isOpen, onToggle } = useDisclosure({
    onOpen: () => {
      // NOTE: Not using `useGtm` hook here since we don't want to apply withGtm category to children of Sidebar (i.e. the actual page)
      pushGtmTrackEvent(`sidebar.opened`, { label: 'Sidebar' });
      onChange?.(true);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      motionWidth.set(200);
    },
    onClose: () => {
      // NOTE: Not using `useGtm` hook here since we don't want to apply withGtm category to children of Sidebar (i.e. the actual page)
      pushGtmTrackEvent(`sidebar.closed`, { label: 'Sidebar' });
      onChange?.(false);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      motionWidth.set(48);
    },
  });
  const width = isOpen ? 200 : 48;
  const motionWidth = useMotionValue(width);

  return (
    // @ts-expect-error children prop
    <AnimatePresence>
      <Box h="100vh" w="100vw">
        <Sidebar
          {...props}
          onToggle={onToggle}
          isOpen={isOpen}
          width={width}
          motionWidth={motionWidth}
        />
        <Box
          as={motion.main}
          initial={{
            marginInlineStart: `-${width}px`,
            paddingInlineStart: `${width + 16}px`,
          }}
          animate={{
            marginInlineStart: `-${motionWidth.get()}px`,
            paddingInlineStart: `${motionWidth.get() + 16}px`,
          }}
          overflowY="scroll"
          p="4"
          flexGrow={1}
          w="100vw"
        >
          <ErrorBoundary>{children}</ErrorBoundary>
        </Box>
      </Box>
    </AnimatePresence>
  );
};
