import { Context, useContext } from 'react';
import { InferFullType } from '@pepper/types';
import {
  PageConfig,
  PageConfigContext,
  IPageConfigContext,
} from '../providers/pageConfigProvider';

export function usePageConfig<C extends PageConfig = PageConfig>(): [
  InferFullType<C>,
  (config: C) => void,
] {
  const { config, setConfig } = useContext(
    PageConfigContext as unknown as Context<IPageConfigContext<C>>,
  );

  return [config, setConfig];
}
