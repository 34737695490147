/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useMemo, useRef } from 'react';
import type { NextRouter } from 'next/router';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { FieldValues } from 'react-hook-form';
import { InferType } from 'yup';
import { RequiredObjectSchema } from 'yup/lib/object';
import { flattenKeys, FlattenObjectKeys } from '../../utils/flattenKeys';
import {
  getParamsFromRouter,
  replaceExistingParamsWithPrefixedParams,
  urlSearchParamsToUrl,
} from '../../utils/url';
import type { UseFormReturn } from '../useForm/useForm';
import { PAGINATION_PARAMS } from '../usePagination';

/**
 * This is needed so we can easily remove params, otherwise we have no idea to know which params are from form and which from other components
 * The `p` is for Pepper
 *
 * We also ignore these query params in Google Tag Manager for page views
 * @see https://tagmanager.google.com/#/container/accounts/6004512962/containers/49374613/
 *
 */
export const FORM_URL_PARAMS_PREFIX = 'p_';

export type ChangedFields<TFieldValues extends FieldValues> = Partial<
  Readonly<Record<FlattenObjectKeys<TFieldValues>, unknown>>
>;

/**
 * Returns the changed fields in a form and optionally syncs them to the url
 */
export const useChangedFields = <
  TSchema extends RequiredObjectSchema<any, any, any>,
  TContext = any,
  TFieldValues extends FieldValues = InferType<TSchema>,
>({
  form,
  router,
  onChange,
}: {
  form: UseFormReturn<TFieldValues, TContext>;
  /**
   * If router is passed then the changed fields will be synced with the url query params
   */
  router?: NextRouter;
  onChange?: (changes: {
    added: [key: string, val: unknown][];
    changed: [key: string, val: unknown][];
    removed: [key: string, val: unknown][];
  }) => void;
}) => {
  const {
    watch,
    isLoading,
    formState: { isValidating, defaultValues, dirtyFields },
  } = form;

  const data = watch();
  const isFirstRun = useRef(true);

  const changedFields: ChangedFields<TFieldValues> = useMemo(() => {
    // Just here so eslint doesn't complain, we want to trigger on isValidating but not use it
    if (isValidating) {
      //
    }
    // Double flattening required for nested values like "sort: {sortBy: field, sortOrder: DESC}"
    const dirtyPaths = flattenKeys(
      Object.fromEntries(
        flattenKeys(dirtyFields).map((key) => {
          const val = get(data, key);
          return [key, val];
        }),
      ),
    );

    return Object.fromEntries(
      dirtyPaths
        .map((key) => {
          const defaultVal = get(defaultValues, key);
          const val = get(data, key);
          if (
            val == null ||
            val === '' ||
            val === defaultVal ||
            (Array.isArray(defaultVal) &&
              isEqual([...val].sort?.(), [...defaultVal].sort?.()))
          ) {
            return [null, null];
          }
          return [key, val];
        })
        .filter(([key]) => key != null),
    );
    // isValidating is the field that changes everytime a field is changed
  }, [data, dirtyFields, isValidating, defaultValues]);

  const pastRef = useRef(changedFields);

  useEffect(() => {
    if (isLoading || !onChange) {
      return;
    }
    const pastFields = pastRef.current;
    pastRef.current = changedFields;

    const whatsAdded = Object.entries(changedFields).filter(([key]) => {
      return !pastFields[
        key as FlattenObjectKeys<TFieldValues, keyof TFieldValues>
      ];
    });
    const whatsChanged = Object.entries(changedFields).filter(
      ([key, newVal]) => {
        const oldVal =
          pastFields[
            key as FlattenObjectKeys<TFieldValues, keyof TFieldValues>
          ];
        return !!oldVal && oldVal !== newVal;
      },
    );
    const whatsRemoved = Object.entries(pastFields).filter(([key]) => {
      return !changedFields[
        key as FlattenObjectKeys<TFieldValues, keyof TFieldValues>
      ];
    });

    if (!whatsAdded.length && !whatsChanged.length && !whatsRemoved.length) {
      return;
    }
    onChange({
      added: whatsAdded,
      changed: whatsChanged,
      removed: whatsRemoved,
    });
  }, [changedFields, isLoading, onChange]);

  useEffect(() => {
    if (!router || isLoading) {
      return;
    }
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    const params = replaceExistingParamsWithPrefixedParams(
      router,
      changedFields,
      FORM_URL_PARAMS_PREFIX,
    );
    let updatedParams = urlSearchParamsToUrl(params);

    if (
      // Compare with initial
      updatedParams === urlSearchParamsToUrl(getParamsFromRouter(router))
    ) {
      return;
    }

    if (updatedParams) {
      // When filter changes -> reset the page number
      params.delete(PAGINATION_PARAMS.PAGE_NUMBER);
      updatedParams = urlSearchParamsToUrl(params);
    }

    if (updatedParams) {
      void router.push(updatedParams, undefined, { shallow: true });
    } else {
      void router.push(router.asPath.split('?')[0], undefined, {
        shallow: true,
      });
    }
  }, [changedFields, router, isLoading]);

  return { changedFields, formValues: data };
};
