import React, { useMemo } from 'react';
import { CookiesProvider as ReactCookiesProvider, Cookies } from 'react-cookie';

export const COOKIE_HEADER_PROP = '__COOKIE_HEADER_PROP__';

export const CookiesProvider: React.FC<{
  pageProps: Record<string, unknown>;
  children?: React.ReactNode;
}> = ({ children, pageProps }) => {
  const isSSR = typeof window === 'undefined';
  const cookieHeader = (pageProps?.[COOKIE_HEADER_PROP] || '') as string;
  const cookies = useMemo(() => new Cookies(cookieHeader), [cookieHeader]);

  return (
    <ReactCookiesProvider cookies={isSSR ? cookies : undefined}>
      {children}
    </ReactCookiesProvider>
  );
};
