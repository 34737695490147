/**
 * All "pages" (i.e. a full page, drawer, or modal) should be static or
 * if a dynamic function then should accept only 1 object parameter with route params and/or a url params object
 *
 * @example For static page
 * ```ts
 * DASHBOARD: `/dashboard`,
 * ```
 * @example For dynamic page
 * ```ts
 * FACILITY_DETAILS: ({ facilityId }: { facilityId: string }) =>
 *  `/facilities/${facilityId}`
 * ```
 * @example For drawer
 * ```ts
 * VENDOR_DETAILS_DRAWER: ({
 *   params,
 *    globalEntityId,
 *    vendorId,
 *  }: {
 *    params?: URLSearchParams;
 *    globalEntityId: string;
 *    vendorId: string;
 * }) => {
 *   params.set(
 *     'vendor',
 *     `${vendorFilter.globalEntityId};${vendorFilter.vendorId}`,
 *   );
 *   return urlSearchParamsToHref(params);
 * }
 * ```
 */
import { Admin } from './adminPages';
import { Auth } from './authPages';
import { OrderManagement } from './orderPages';

// TODO: Move pages from all apps here
export const Pages = {
  Admin,
  Auth,
  OrderManagement,
};

// Re-exporting the variable as also a type allows us to import it into cypress types
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Pages = typeof Pages;
