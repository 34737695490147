import { useContext, useEffect } from 'react';
import {
  FwFContext,
  Flags,
  AbTestFlagVariations,
  FlagValue,
  fwfClient,
} from '../providers/fwfProvider';

/**
 * @see https://management.fwf.deliveryhero.net/developers/docs?sdk=react&file=readme
 */
export function useFlag<T extends FlagValue = boolean>(
  flagName: Flags,
  defaultValue: T,
): T {
  const flags = useContext(FwFContext);
  const variation = flags?.[flagName]?.variation;

  useEffect(() => {
    if (variation == null) {
      return;
    }

    /**
     * We track usage manually here since we load all flags at once during SSR
     */
    fwfClient.trackFeature(flagName);
  }, [variation, flagName]);

  return (variation ?? defaultValue) as T;
}

export { Flags };
export type { AbTestFlagVariations, FlagValue };
