import React from 'react';
import { Inter } from 'next/font/google';
import { Nullable, RecursivePartial } from '@pepper/types';
import { useTranslation } from 'react-i18next';
import { UnauthenticatedPageProps, UserStateMaybe } from '../ssr/withPageProps';
import { ApolloClientProvider } from './apolloProvider';
import { CookiesProvider } from './cookieProvider';
import { FwFClientProvider } from './fwfProvider';
import {
  PageConfig,
  PageConfigFull,
  PageConfigProvider,
} from './pageConfigProvider';
import { UserStateProvider } from './userStateProvider/userStateProvider';

const inter = Inter({ subsets: ['latin'], weight: 'variable' });

export const AllProviders: React.FC<{
  pageProps: UnauthenticatedPageProps &
    UserStateMaybe &
    Record<string, unknown>;
  configOverrides: Nullable<
    | RecursivePartial<PageConfig>
    | ((
        pageProps: any,
        t: ReturnType<typeof useTranslation>['t'],
      ) => RecursivePartial<PageConfig>)
  >;
  defaultPageConfig?: PageConfigFull;
  children?: React.ReactNode;
}> = ({ children, pageProps, configOverrides, defaultPageConfig }) => {
  return (
    <>
      <style jsx global>{`
        html {
          font-family: ${inter.style.fontFamily};
        }
      `}</style>
      <CookiesProvider pageProps={pageProps}>
        <ApolloClientProvider pageProps={pageProps}>
          <UserStateProvider
            user={pageProps.user}
            country={pageProps.country}
            facility={pageProps.facility}
            stall={pageProps.stall}
          >
            <PageConfigProvider
              pageProps={pageProps}
              overrides={configOverrides}
              defaultConfig={defaultPageConfig}
            >
              <FwFClientProvider
                stall={pageProps.stall}
                flags={pageProps.flags}
                user={pageProps.user}
                country={pageProps.country}
                facility={pageProps.facility}
              >
                {children}
              </FwFClientProvider>
            </PageConfigProvider>
          </UserStateProvider>
        </ApolloClientProvider>
      </CookiesProvider>
    </>
  );
};
