import type { RecursivePartial } from '@pepper/types';
import { Theme } from '@chakra-ui/react';

export const Textarea: RecursivePartial<Theme['components']['Textarea']> = {
  defaultProps: {
    size: 'sm',
  },
  baseStyle: {
    m: 0,
    _readOnly: {
      resize: 'none',
    },
    _disabled: {
      resize: 'none',
    },
  },
  variants: {
    outline: {
      borderRadius: 'md',
      borderColor: 'gray.500',
      backgroundColor: 'white',
      _readOnly: {
        color: 'pepperBlack',
        backgroundColor: 'gray.100',
        border: '0px',
      },
      _placeholder: {
        color: 'gray.700',
      },
      _focus: {
        borderColor: 'pepperBlack',
        boxShadow: '0 0 0 1px pepperBlack',
      },
    },
  },
};
