import React from 'react';
import { Box, BoxProps, ComponentWithAs } from '@chakra-ui/react';
import { config } from '../config';
import { useUser } from '../hooks/useUser/useUser';

interface Props extends BoxProps {
  ssoOnly?: boolean;
}

/**
 * @see https://help.hotjar.com/hc/en-us/articles/115012439167-How-to-Suppress-Text-Images-and-User-Input-from-Collected-Data#page_content
 * @see https://docs.datadoghq.com/real_user_monitoring/session_replay/privacy_options/?tab=maskuserinput#override-an-html-element
 */
export const PII: ComponentWithAs<'div', React.PropsWithChildren<Props>> = ({
  children,
  ssoOnly = false,
  ...props
}) => {
  const user = useUser();
  if (ssoOnly && !user.access?.isSSO && config.DISABLE_PASSWORD_LOGIN) {
    return <>{null}</>;
  }
  return (
    <Box {...props} data-hj-suppress data-dd-privacy="hidden">
      {children}
    </Box>
  );
};
