import React, { memo } from 'react';
import {
  useDisclosure,
  Alert,
  AlertProps,
  AlertDescription,
  As,
  CloseButton,
  Button,
} from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { Icon } from '../Icon';

export interface AlertMessageProps extends Omit<AlertProps, 'content'> {
  status?: AlertProps['status'];
  /**
   * Show closed state for alert
   */
  closable?: As | null | boolean;
  showCloseButton?: boolean;
  children: React.HTMLAttributes<Element>['children'];
}

const statusIconMap: Record<NonNullable<AlertProps['status']>, string> = {
  info: 'circle-info-blue-700',
  warning: 'triangle-exclamation-orange-950',
  error: 'rhombus-info-red-700',
  success: 'check-medium-green-700',
};

/**
 * @see https://www.figma.com/design/1pTB216ruZ3nLDU83X08YR/Pepper-Design-System?node-id=5800-5499&m=dev
 */
export const AlertMessage: React.FC<AlertMessageProps> = memo(
  ({
    children,
    status = 'error',
    closable = null,
    showCloseButton = true,
    ...rest
  }) => {
    const { t } = useTranslation();
    const {
      isOpen: isVisible,
      onClose,
      onOpen,
    } = useDisclosure({ defaultIsOpen: true });

    let Component: As | null = null;
    if (closable) {
      if (typeof closable === 'boolean') {
        Component = Button;
      } else {
        Component = closable;
      }
    }

    if (!isVisible) {
      return Component ? (
        <Component size="xs" w="fit-content" onClick={onOpen}>
          {t('common:alert.show_alert', {
            defaultValue: 'Show alert',
          })}
        </Component>
      ) : null;
    }

    return (
      <Alert {...rest} status={status}>
        <Icon image={statusIconMap[status]} />
        <AlertDescription>{children}</AlertDescription>
        {showCloseButton ? (
          <CloseButton
            aria-label={t('common:alert.close_alert', {
              defaultValue: 'Close alert',
            })}
            onClick={onClose}
          />
        ) : null}
      </Alert>
    );
  },
);
