import {
  DependencyList,
  EffectCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

/**
 * @see https://github.com/react-hook-form/react-hook-form/issues/7068#issuecomment-973167261
 * @param value the value to be memoized (usually a dependency list)
 * @returns a memoized version of the value as long as it remains deeply equal
 */
function useDeepCompareMemoize<T>(value: T): T {
  const ref = useRef<T>(value);
  const signalRef = useRef<number>(0);

  if (!isEqual(value, ref.current)) {
    ref.current = cloneDeep(value);
    signalRef.current += 1;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => ref.current, [signalRef.current]);
}

export function useDeepCompareEffect(
  callback: EffectCallback,
  dependencies: DependencyList,
): void {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useEffect(callback, useDeepCompareMemoize(dependencies));
}

export function useDeepCompareMemo<T>(
  factory: () => T,
  dependencies: DependencyList,
): T {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo<T>(factory, useDeepCompareMemoize(dependencies));
}
