import React from 'react';
import { Icon } from '@deliveryhero/gfs-ui';
import { Link } from '@chakra-ui/next-js';
import { Text, Flex, Tooltip, CSSObject, Box } from '@chakra-ui/react';
import { motion, Variants } from 'framer-motion';
import { useGtm } from '../../hooks/gtm';
import type { ISidebarSectionItems } from './Sidebar';

export const groupStyles: CSSObject = {
  cursor: 'pointer',
  justifyContent: 'start',
  alignItems: 'center',
  margin: 1.5,
  marginStart: 1,
  borderRadius: 'md',
  _hover: {
    cursor: 'pointer',
    background: 'gray.200',
    textDecoration: 'none',
  },
  _active: {
    background: 'gray.300',
    textDecoration: 'none',
  },
};

export const activePageStyles: CSSObject = {
  paddingStart: 0,
  borderStart: '2px solid blue',
  borderColor: 'blue.500',
  borderRadius: 0,
  color: 'blue.500',
  fontWeight: 'bold',
};

export const overflowEllipses: CSSObject = {
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
};

export const sidebarItemAnimations: Variants = {
  open: {
    width: 'auto',
  },
  close: { width: 36 },
};

export const sidebarTextAnimations: Variants = {
  open: {
    x: 0,
    opacity: 1,
  },
  close: { x: -50, opacity: 0 },
};

const SidebarItem = ({
  name,
  'data-testid': testid,
  label,
  icon,
  href,
  onClick,
  isActive,
  isOpen,
}: {
  'data-testid'?: string;
  name: string;
  label?: string;
  icon: ISidebarSectionItems['icon'];
  href?: string;
  onClick?: () => void;
  isActive: boolean;
  isOpen: boolean;
}) => {
  const { pushGtm } = useGtm();
  if (!href && !onClick) {
    throw new Error('One of href or onClick is required');
  }

  return (
    <Link
      href={href ?? {}}
      target={href?.startsWith('/') ? undefined : '_blank'}
      shallow
      textDecoration="none !important"
      boxShadow="none !important"
      paddingStart={0.5}
      aria-current={isActive ? 'page' : undefined}
      _activeLink={activePageStyles}
      onClick={(e) => {
        if (onClick) {
          e.preventDefault();
          onClick();
        }
        pushGtm(`clicked`, { label: 'Sidebar Item', info: name });
      }}
    >
      <Tooltip
        label={label ?? (isOpen ? '' : name)}
        placement="right"
        offset={[0, 24]}
      >
        <Flex
          as={motion.div}
          variants={sidebarItemAnimations}
          initial="close"
          animate={isOpen ? 'open' : 'close'}
          sx={groupStyles}
          h={9}
          role="group"
          data-testid={testid ?? `sidebar-item-${name}`}
          data-active={isActive || undefined}
        >
          <Icon
            margin={2}
            color={isActive ? 'blue.500' : 'gray.900'}
            src={isActive && icon.active ? icon.active : icon.normal}
            alt={name}
          />
          {isOpen ? (
            <Text
              as={motion.p}
              variants={sidebarTextAnimations}
              initial="close"
              animate="open"
              exit="close"
              sx={overflowEllipses}
              color={isActive ? 'blue.500' : 'gray.900'}
              fontWeight={isActive ? 600 : 0}
              fontSize={14}
            >
              {name}
            </Text>
          ) : null}
        </Flex>
      </Tooltip>
    </Link>
  );
};

const SectionTitle = ({ name, isOpen }: { name: string; isOpen: boolean }) => {
  return (
    <Tooltip label={isOpen ? '' : name} placement="right" offset={[0, 24]}>
      <Box
        as="h4"
        sx={{
          height: 9,
          minHeight: 9,
          fontStyle: 'normal',
          fontWeight: '400',
          fontSize: '14px',
          lineHeight: 5,
          color: 'gray.800',
          textTransform: 'capitalize',
          padding: 2,
          margin: 1.5,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Text w={isOpen ? undefined : 5} sx={overflowEllipses}>
          {name}
        </Text>
      </Box>
    </Tooltip>
  );
};

const MemoizedSectionTitle = React.memo(SectionTitle);
const MemoizedSidebarItem = React.memo(SidebarItem);
export {
  MemoizedSidebarItem as SidebarItem,
  MemoizedSectionTitle as SectionTitle,
};
