import Head from 'next/head';
import NextLink from 'next/link';
import {
  config,
  useInitGtm,
  pushGtmTrackEvent,
  ErrorBoundary,
} from '@pepper/client';
import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  Link,
  Stack,
} from '@chakra-ui/react';
import { faSlack } from '@fortawesome/free-brands-svg-icons/faSlack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'next-i18next';
import { Logo } from './Logo';

export const Layout = ({ children }: { children?: React.ReactNode }) => {
  useInitGtm(config.GTM_ID);

  const { t } = useTranslation();

  return (
    <>
      <Head>
        <title>GFS Pepper</title>
        <link
          rel="icon"
          href={
            config.APP_ENV === 'prod'
              ? '/shared/favicon.ico'
              : `/shared/favicons/favicon-${config.APP_ENV}.svg`
          }
        />
      </Head>
      <Flex flexDirection="column" overflow="scroll">
        <Container justifyContent="center">
          <Stack mt={10} spacing={6}>
            <Box
              sx={{
                width: '380px',
                display: 'flex',
                flexDirection: 'column',
                background: 'white',
                alignItems: 'center',
                gap: 6,
                padding: 6,
                borderRadius: 'base',
                boxShadow: 'md',
              }}
            >
              <Center
                as={NextLink}
                href="/"
                onClick={() =>
                  pushGtmTrackEvent('layout.clicked', {
                    label: 'Logo',
                  })
                }
              >
                <Logo />
              </Center>
              <ErrorBoundary>{children}</ErrorBoundary>
            </Box>

            <Center>
              <Button
                as={Link}
                target="_blank"
                href={config.SLACK_SUPPORT_LINK}
                rel="noopener noreferrer"
                name="contact_us"
                leftIcon={<FontAwesomeIcon icon={faSlack} width="15" />}
                colorScheme="black"
                variant="outline"
                onClick={() =>
                  pushGtmTrackEvent('layout.clicked', {
                    label: 'Contact Us',
                  })
                }
              >
                {t('common:support.contact_us')}
              </Button>
            </Center>

            <Center>
              <Link
                onClick={() =>
                  pushGtmTrackEvent('layout.downloaded', {
                    label: 'Download file for TV setup',
                  })
                }
                href="/downloads/launch.bat"
              >
                {t('admin.download_tv_file')}
              </Link>
            </Center>

            <Center
              textColor="gray.900"
              fontSize="smaller"
            >{`v${config.APP_VERSION}`}</Center>
          </Stack>
        </Container>
      </Flex>
    </>
  );
};
