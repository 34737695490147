/* eslint-disable */
/* This is a generated file (see scripts/schema.js), don't edit manually */
export const leadSchemaFields = [
  {
    "name": "id",
    "type": "STRING",
    "description": "Pepper lead ID"
  },
  {
    "name": "leaseId",
    "type": "STRING",
    "description": "Pepper lease ID",
    "mode": "NULLABLE"
  },
  {
    "name": "brandId",
    "type": "STRING",
    "description": "Pepper brand ID"
  },
  {
    "name": "facilityId",
    "type": "STRING",
    "description": "Pepper facility ID"
  },
  {
    "name": "status",
    "type": "STRING",
    "description": "Lead current status(Stage): New / Suspect / Prospect / Negotiation / WaitingList / Signed / Lost"
  },
  {
    "name": "assigneeId",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Lead assignee user id in Pepper"
  },
  {
    "name": "reason",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "**DEPRECATED**: Use events.comment instead. Lost reason text for lost status change"
  },
  {
    "name": "events",
    "type": "RECORD",
    "mode": "REPEATED",
    "fields": [
      {
        "name": "status",
        "type": "STRING",
        "description": "Lead event status: Suspect / Prospect / Negotiation / WaitingList / Signed / Lost"
      },
      {
        "name": "comment",
        "type": "STRING",
        "mode": "NULLABLE",
        "description": "Comment related to lead status update. Will be lost reason when status is Lost"
      },
      {
        "name": "createdBy",
        "type": "STRING",
        "mode": "NULLABLE",
        "description": "Pepper user ID of who updated status"
      },
      {
        "name": "createdAt",
        "type": "TIMESTAMP",
        "description": "Time of event"
      }
    ]
  },
  {
    "name": "createdAt",
    "type": "TIMESTAMP",
    "description": "When lead was added in Pepper"
  },
  {
    "name": "updatedAt",
    "type": "TIMESTAMP",
    "description": "When lead was last updated in Pepper"
  },
  {
    "name": "exportedAt",
    "type": "TIMESTAMP",
    "description": "When lead was last exported from Pepper"
  }
] as const;
export type LeadSchema = typeof leadSchemaFields;