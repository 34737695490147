import { nullableFilterPredicate } from '@pepper/types';
import languageDetector from 'next-language-detector';

const isServer = typeof window === 'undefined';
const AVAILABLE_LOCALES = ['ar', 'en', 'es', 'fil', 'ku', 'th'];

const defaultLocale = isServer
  ? 'en'
  : languageDetector({
      supportedLngs: AVAILABLE_LOCALES,
      fallbackLng: 'en',
    }).detect() ?? 'en';

export const ALL_NAMESPACES = [
  'common',
  'gfs-cooking-app',
  'gfs-growth-suite',
  'gfs-kitchen-screens',
  'gfs-order-management',
  'gfs-pepper-admin',
  'gfs-pepper-auth',
  'gfs-sales-dashboard',
] as const;

export type AllNamespaces = typeof ALL_NAMESPACES[number];

export const i18nConfig = {
  // https://www.i18next.com/overview/configuration-options#logging
  debug:
    process.env.DEBUG_LOGS === 'true' ||
    process.env.DEBUG_LOGS?.includes('i18n'),
  reloadOnPrerender: process.env.NODE_ENV === 'development',

  i18n: {
    defaultLocale,
    locales: AVAILABLE_LOCALES,
  },

  lng: defaultLocale,
  fallbackLng: 'en',
  supportedLngs: AVAILABLE_LOCALES,

  defaultNS: process.env.SERVICE_NAME || 'common',
  fallbackNS: ['common'],
  ns: ['common', process.env.SERVICE_NAME].filter(nullableFilterPredicate),

  localePath: isServer
    ? // eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
      require('path').resolve('./public/shared/locales')
    : '/shared/locales',
};
