import { GetServerSidePropsContext } from 'next';
import { accountsLink } from '@accounts/apollo-link';
import { AccountsClient } from '@accounts/client';
import { AccountsClientPassword } from '@accounts/client-password';
import {
  split,
  ApolloClient,
  HttpLink,
  NormalizedCacheObject,
  ApolloLink,
} from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { CrunchedData, uncrunch } from 'graphql-crunch';
import { initAccounts } from '../accounts/accountsClient';
import { config } from '../config';
import { getApolloCache } from './apolloCache';
import { errorLink } from './errorLink';
import { languageLink } from './languageLink';
import { networkStateVar } from './reactiveVariables';
import { createPeriodicVersionCheck, versionLink } from './versionLink';
import { getWebsocketLink } from './websocketLink';

export type CustomApolloClient = ApolloClient<NormalizedCacheObject> & {
  restartWs: () => void;
  accountsClient: AccountsClient;
  accountsClientPassword: AccountsClientPassword;
};

export function createApolloClient(
  ctx?: GetServerSidePropsContext,
): CustomApolloClient {
  const ssrMode = typeof window === 'undefined';
  let accountsClient: AccountsClient;
  let accountsClientPassword: AccountsClientPassword;

  const accountsClientFactory = (): AccountsClient => {
    // We cache this again so as to not create multiple instances of accounts per apollo client on SSR
    if (accountsClient) {
      return accountsClient;
    }
    const accounts = initAccounts(
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      client,
      ctx,
    );
    accountsClientPassword = accounts.accountsClientPassword;
    accountsClient = accounts.accountsClient;
    return accountsClient;
  };

  const httpLink = new HttpLink({
    uri: `${config.GRAPHQL_SERVER}${
      config.APP_ENV === 'prod' ? '?crunch=1' : ''
    }`,
    credentials: 'include',
    fetch,
  });

  const {
    link: wsLink,
    restart: restartWs,
    onReconnected: onReconnectedWs,
  }: Omit<ReturnType<typeof getWebsocketLink>, 'link'> & {
    link: GraphQLWsLink | null;
  } = ssrMode
    ? { link: null, restart: () => {}, onReconnected: () => {} }
    : getWebsocketLink(accountsClientFactory);

  const httpPlusWsLink = wsLink
    ? split(
        // split based on operation type
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
          );
        },
        wsLink,
        httpLink,
      )
    : httpLink;

  const uncruncher = new ApolloLink((operation, forward) =>
    forward(operation).map((response) => {
      const { data } = response;

      if (data && (data.crunched || Array.isArray(data))) {
        response.data = uncrunch(data as CrunchedData) as Record<
          string,
          unknown
        >;
      }
      return response;
    }),
  );

  /**
   * NOTE: We need this so that accessToken is automatically regenerated from refresh token
   */
  const authLink = accountsLink(accountsClientFactory);

  const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    ssrMode,
    connectToDevTools: config.APP_ENV !== 'prod',
    link: errorLink
      .concat(versionLink)
      .concat(languageLink(ctx))
      .concat(uncruncher)
      .concat(authLink)
      .concat(httpPlusWsLink), // HTTP must be kept last
    cache: getApolloCache(),
  });

  if (!ssrMode) {
    onReconnectedWs(async () => {
      await client.refetchQueries({
        include: 'active',
      });
    });

    window.addEventListener('online', async () => {
      networkStateVar({
        isConnected: true,
        type: 'internet',
      });
      await client.refetchQueries({
        include: 'active',
      });
    });

    window.addEventListener('offline', () => {
      networkStateVar({
        isConnected: false,
        type: 'internet',
      });
    });

    /**
     * Remove old cache entries periodically
     * @see https://www.apollographql.com/docs/react/caching/garbage-collection/
     */
    setInterval(() => {
      client.cache.gc();
    }, 30 * 60_000);

    // Init interval only once
    createPeriodicVersionCheck(5 * 60 * 1000);
  }

  // Initiate accounts once
  accountsClientFactory();

  return Object.assign(client, {
    restartWs,
    // FIXME: Use ts-expect-error after enabling strict mode in OMS
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore defined when fn called above
    accountsClient,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore defined when fn called above
    accountsClientPassword,
  });
}
