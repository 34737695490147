import { makeVar } from '@apollo/client';

interface NetworkState {
  isConnected: boolean;
  type: 'internet' | 'socket';
}

export const networkStateVar = makeVar<NetworkState>({
  isConnected: true,
  type: 'internet',
});
