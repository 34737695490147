/* eslint-disable */

import * as Types from '@pepper/types/graphql';

import { gql } from '@apollo/client';
import { CountrySelectorFragmentDoc } from './countrySelector.fragment.generated';
export type FacilitySelectorFragment = { _id: any, name: string, facilityType: Types.FacilityType, timezone: string, status: Types.FacilityStatus, country: { _id: string, facilityType: Types.FacilityType, name: string, locales?: Array<string> | null, countryCode: string, globalEntityIds: Array<string>, salesAppSettings?: { partnerContactEmailRequired?: boolean | null } | null }, ordersManagement: { announcement?: string | null } };

export const FacilitySelectorFragmentDoc = gql`
    fragment FacilitySelector on Facility {
  _id
  name
  facilityType
  timezone
  status
  country {
    _id
    facilityType
    ...CountrySelector
  }
  ordersManagement {
    announcement
  }
}
    ${CountrySelectorFragmentDoc}`;