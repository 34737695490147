import { useCallback, useEffect, useState } from 'react';
import { logger } from '@pepper/logger';
import { config } from '../config';

const useIsTabActive = () => {
  const [isTabVisible, setIsTabVisible] = useState(true);

  const handleVisibilityChange = useCallback(() => {
    setIsTabVisible(document.visibilityState === 'visible');
  }, []);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [handleVisibilityChange]);

  return isTabVisible;
};

const isProd = config.APP_ENV === 'prod';

const chromeExtensionIds = [
  // Local development manual installation
  'dkomndnckecdaglholbmfogjmcgdkjha',
  // marketplace extension - accessible only to `dh_gfs_tech@deliveryhero.com`
  // https://chromewebstore.google.com/detail/pepper-create-orders/dhbeecdmnoplfmkngmcbhoiiibigpjpj?hl=en&authuser=0&pli=1
  'dhbeecdmnoplfmkngmcbhoiiibigpjpj',
];

/* 
  This hook is used to send vendors to the chrome extension 
  @see poc/order-test-extension
*/
export const useTestExtension = (
  vendors?: {
    vendorId: string;
    globalEntityId: string;
  }[],
) => {
  const isActive = useIsTabActive();
  const [isExtensionLoaded, setExtensionLoaded] = useState(false);

  useEffect(() => {
    if (isProd) {
      return;
    }
    const cb = () => {
      setExtensionLoaded(true);
      logger.info('Started extension communications');
    };
    document.addEventListener('pepper-extension-loaded', cb);
    logger.info('Setup extension listener');

    return () => {
      document.removeEventListener('pepper-extension-loaded', cb);
    };
  }, []);

  const isChromeCompatible = !!globalThis.chrome?.runtime;
  const shouldSendVendors = isExtensionLoaded || isChromeCompatible;

  useEffect(() => {
    if (!isActive || !shouldSendVendors) {
      return;
    }
    if (!vendors?.length || isProd) {
      return;
    }
    let interval: NodeJS.Timer;

    const detail = {
      vendors: vendors.map(({ vendorId, globalEntityId }) => ({
        vendorId,
        globalEntityId,
      })),
    };

    // Firefox doesn't support the API for externally_connectable so we use Content Script and Custom Events
    // @see https://bugzilla.mozilla.org/show_bug.cgi?id=1319168
    const storeEvent = new CustomEvent('pepper-vendors-event', {
      detail,
    });
    document.dispatchEvent(storeEvent);

    const sendVendorData = async () => {
      logger.debug({ vendors }, 'Sending vendors');
      for (const extId of chromeExtensionIds) {
        try {
          await globalThis.chrome?.runtime.sendMessage?.(extId, detail);
        } catch (err) {
          logger.warn({ err, extId }, 'Could not send vendors to extension');
        }
      }
    };

    // For backwards compatibility with old extension, remove later
    if (globalThis.chrome?.runtime) {
      void sendVendorData();
      interval = setInterval(() => {
        void sendVendorData();
      }, 3_000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isActive, shouldSendVendors, vendors]);
};
