/* eslint-disable */

import * as Types from '@pepper/types/graphql';

import { gql } from '@apollo/client';
export type CountrySelectorFragment = { _id: string, name: string, locales?: Array<string> | null, countryCode: string, facilityType: Types.FacilityType, globalEntityIds: Array<string>, salesAppSettings?: { partnerContactEmailRequired?: boolean | null } | null };

export const CountrySelectorFragmentDoc = gql`
    fragment CountrySelector on Country {
  _id
  name
  locales
  countryCode
  facilityType
  globalEntityIds
  salesAppSettings {
    partnerContactEmailRequired
  }
}
    `;