import { useContext } from 'react';
import { namedOperations } from '../helpers/operations';
import { FacilitySelectorFragment } from '../providers/userStateProvider/graphql/facilitySelector.fragment.generated';
import { UserStateFallbackQuery } from '../providers/userStateProvider/graphql/userState.query.generated';
import { UserStateContext } from '../providers/userStateProvider/userStateProvider';

/**
 * @package
 */
export const getFacilityFromData = (
  data: UserStateFallbackQuery | undefined,
  defaultValue?: FacilitySelectorFragment | null,
): FacilitySelectorFragment => {
  const facility =
    data?.facility ??
    data?.defaultFacilityList?.nodes[0] ??
    data?.inactiveFacilityList?.nodes[0] ??
    defaultValue;

  if (facility) {
    return facility;
  }

  throw new Error(
    `You don't have any facility assigned to you, please contact support.`,
  );
};

/**
 * Get the selected facility globally in all pages.
 * Returns a dummy facility in static pages and loads it on client side on client init
 *
 * @example Usage in pages/components
 * ```ts
 * const facility = useFacility();
 * ```
 */
export const useFacility = () => {
  const { facility } = useContext(UserStateContext);
  return facility;
};

useFacility.queryName = namedOperations.Query.userStateFallback;
