import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { useApollo } from '../apollo/apolloSsr';

/**
 * Apollo client provider with hydration for SSR client cache passed using pageProps
 * Use with generated `getServerSideProps` from  `graphql-codegen-apollo-next-ssr` to make use of SSR cache
 */
export const ApolloClientProvider: React.FC<{
  pageProps: Record<string, unknown>;
  children?: React.ReactNode;
}> = ({ children, pageProps }) => {
  const apolloClient = useApollo(pageProps);

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};
