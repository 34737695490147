/**
 * NOTE: Also update `helm/common/development.yaml` if changing file
 */

export const config = {
  APP_ENV: 'dev' as const,
  DD_CLIENT_ENV: 'dev' as const,
  DISABLE_PASSWORD_LOGIN: false,
  FWF_ENV_TOKEN: '4fc03930-69f9-4e04-8989-985a0e133d5e',
  GTM_ID: 'GTM-NZ8RKC6',
  ADMIN_CLIENT_URL: 'https://admin.dev.honestfoodtech.com',
  AUTH_CLIENT_URL: 'https://pepper.dev.honestfoodtech.com',
  COOK_CLIENT_URL: 'https://cook.dev.honestfoodtech.com',
  SALES_CLIENT_URL: 'https://sales.dev.honestfoodtech.com',
  OMS_CLIENT_URL: 'https://orders.dev.honestfoodtech.com',
  GRAPHQL_SERVER: '/graphql',
  GRAPHQL_WEBSOCKET_SERVER:
    'wss://kitchen-screens.dev.honestfoodtech.com/graphql',
  SERVER_URL: 'https://kitchen-screens.dev.honestfoodtech.com',
};
