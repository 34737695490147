import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useRouter } from 'next/router';
import { useApolloClient } from '@apollo/client';
import {
  MenuItem,
  Image,
  Modal,
  useDisclosure,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Button,
  ButtonGroup,
  ModalCloseButton,
  ModalHeader,
  VStack,
  ModalFooter,
} from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { getDomainFromHost } from '../accounts';
import { CustomApolloClient } from '../apollo';
import { useCookie, useGtm, useLocale } from '../hooks';
import { LANGUAGE_COOKIE } from '../hooks/useLocale';

const languageNames = new Intl.DisplayNames(['en'], {
  type: 'language',
});

const defaultLanguage = 'en';

export const LanguageSelector: React.FC<{
  locales?: string[] | null;
}> = ({ locales: allowedLocales }) => {
  const { pushGtm } = useGtm();
  const { reload } = useRouter();
  const apollo = useApolloClient() as CustomApolloClient;
  const [localeCookie, setLocaleCookie] = useCookie(LANGUAGE_COOKIE);

  const { t, i18n } = useTranslation();
  const { locale, locales, isRtl } = useLocale();

  const initialFocusRef = useRef(null);
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [selectedLocale, setLocaleState] = useState(locale);

  const setLocale = useCallback(
    async (newLocale: string) => {
      setLocaleCookie(newLocale, {
        domain: getDomainFromHost(),
        expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000),
      });
      await i18n.changeLanguage(newLocale);
      apollo.restartWs();
      await apollo.refetchQueries({
        include: 'active',
      });
      onClose();
      // force a reload for it to work correctly.
      reload();
    },
    [apollo, i18n, onClose, reload, setLocaleCookie],
  );

  const languageOptions = useMemo(() => {
    return (
      locales?.filter(
        (l: string) => l === defaultLanguage || !!allowedLocales?.includes(l),
      ) ?? []
    );
  }, [allowedLocales, locales]);

  useEffect(() => {
    if (!localeCookie && locale !== defaultLanguage) {
      void setLocale(locale);
    }
  }, [locale, localeCookie, setLocale]);

  return (
    <>
      <MenuItem
        onClick={() => {
          // Reset state
          setLocaleState(locale);
          onOpen();
          pushGtm('opened', {
            label: 'Language Selector',
          });
        }}
        icon={<Image src="/shared/icons/language-select.svg" sx={{ w: 4 }} />}
        data-testid="language-selector"
      >
        {languageNames.of(locale)}
      </MenuItem>
      <Modal
        isCentered
        initialFocusRef={initialFocusRef}
        size="xs"
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('common:language_change.heading')}</ModalHeader>
          <ModalCloseButton
            left={isRtl ? 'unset' : undefined}
            right={isRtl ? 'unset' : undefined}
            insetStart={isRtl ? '2.5' : undefined}
            insetEnd={isRtl ? '2.5' : undefined}
          />
          <ModalBody display="flex">
            <ButtonGroup gap="4" margin="auto" width="100%">
              <VStack width="100%" spacing="4" py={2}>
                {languageOptions.map((l: string, index: number) => {
                  return (
                    <Button
                      role="button"
                      data-testid={`language-selector-${l}`}
                      colorScheme="whiteAlpha"
                      sx={{
                        _active: {
                          color: 'pepperBlack',
                        },
                        _hover: {
                          color: l === selectedLocale ? 'white' : 'pepperBlack',
                        },
                        h: 12,
                        width: '100%',
                        borderColor: 'gray.500',
                        borderWidth: '1px',
                        background: l === selectedLocale ? 'gray.900' : 'white',
                        color: l === selectedLocale ? 'white' : 'pepperBlack',
                      }}
                      ref={index === 0 ? initialFocusRef : null}
                      key={l}
                      onClick={() => {
                        setLocaleState(l);
                        pushGtm('changed', {
                          label: 'Language Selector',
                          info: l,
                        });
                      }}
                    >
                      {languageNames.of(l)}
                    </Button>
                  );
                })}
              </VStack>
            </ButtonGroup>
          </ModalBody>
          <ModalFooter justifyContent="space-between">
            <Button
              mx={4}
              size="lg"
              variant="link"
              colorScheme="black"
              data-testid="cancel-button"
              onClick={() => {
                pushGtm('cancelled', {
                  label: 'Language Selector',
                });
                onClose();
              }}
            >
              {t('common:language_change.cancel')}
            </Button>
            <Button
              data-testid="save-button"
              size="lg"
              onClick={async () => {
                const info = languageNames.of(selectedLocale);
                pushGtm('submitted', {
                  label: 'Language Selector',
                  info,
                });
                try {
                  await setLocale(selectedLocale);
                  pushGtm('succeeded', {
                    label: 'Language Selector',
                    info,
                  });
                } catch (e) {
                  pushGtm('errored', {
                    label: 'Language Selector',
                    info,
                    error: e,
                  });
                }
              }}
            >
              {t('common:language_change.save')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
