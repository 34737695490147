import pino, { LoggerOptions } from 'pino';
import { browser, isBrowser } from './browser';
import { AdditionalProperties, LogAttributes } from './interfaces';

/**
 * @see https://nextjs.org/docs/going-to-production#logging
 * @see https://github.com/Logflare/next-pino-logflare-logging-example/blob/main/logger/logger.js
 */
const defaultPinoConf: LoggerOptions = {
  /**
   * This is done for DataDog
   * @see https://docs.datadoghq.com/logs/processing/processors/?tab=ui#log-message-remapper
   */
  messageKey: 'message',
  level:
    process.env.DEBUG_LOGS && process.env.DEBUG_LOGS !== 'false'
      ? 'trace'
      : 'info',
  enabled: !(process.env.NO_LOG === 'true'),
  transport:
    process.env.PRETTY_LOGS === 'true'
      ? {
          target: 'pino-pretty',
          options: {
            messageKey: 'message',
            translateTime: 'SYS:HH:MM:ss.l',
            ignore:
              'name,hostname,pid,req,res,id,levelInt,prefix,responseTime,severity,resources,metadata',
          },
        }
      : undefined,
  /**
   * @see https://github.com/pinojs/pino/blob/master/docs/redaction.md
   */
  redact: {
    paths: [
      'customer.notifications.*',
      'customer.payment.*',
      'customer.profile.*',
      'delivery.location.*',
      'content.customer.notifications.*',
      'content.customer.payment.*',
      'content.customer.profile.*',
      'content.delivery.location.*',
      'payload.content.customer.notifications.*',
      'payload.content.customer.payment.*',
      'payload.content.customer.profile.*',
      'payload.content.delivery.location.*',
      'order.customer.notifications.*',
      'order.content.customer.payment.*',
      'order.content.customer.profile.*',
      'order.content.delivery.location.*',
      'req.headers.authorization',
      'req.headers.cookie',
      'graphql.variables.accessToken',
      'graphql.variables.refreshToken',
      'graphql.variables.params.password',
      'graphql.variables.newPassword',
      'graphql.variables.oldPassword',
      'order.customer.*',
      'order.payment.*',
      'order.localInfo.phone',
      'order.localInfo.email',
      'order.delivery.address.*',
    ],
    censor: '**REDACTED**',
  },
  browser: isBrowser ? browser : undefined,
  formatters: {
    // TODO: Fix/Remove this
    level(
      label: string,
    ): ReturnType<
      NonNullable<NonNullable<LoggerOptions['formatters']>['level']>
    > {
      return {
        level: label,
      };
    },
  },
};

interface LogFn<T> {
  (obj: AdditionalProperties<T>, msg?: string, ...args: any[]): void;
  (msg: string, ...args: any[]): void;
}

interface BaseLogger<T extends object = LogAttributes> {
  trace: LogFn<T>;
  debug: LogFn<T>;
  info: LogFn<T>;
  warn: LogFn<T>;
  error: LogFn<T>;
  fatal: LogFn<T>;
  /**
   * @see https://github.com/pinojs/pino/blob/master/docs/child-loggers.md
   */
  child<ChildOptions extends pino.ChildLoggerOptions>(
    bindings: AdditionalProperties<Omit<T, 'name'> & { name?: string }>,
    options?: ChildOptions,
  ): Logger<T>;
}

export type Logger<T extends object = LogAttributes> = Omit<
  pino.Logger,
  keyof BaseLogger
> &
  BaseLogger<T>;

export type LoggerExcept<T extends keyof LogAttributes = never> = Logger<
  Omit<LogAttributes, T>
>;

export function createLogger(options?: LoggerOptions): Logger {
  return pino({ ...defaultPinoConf, ...options });
}

/**
 * Default logger, base for all other loggers
 */
export const logger = createLogger({
  name: `default`,
});

/**
 * Log any payload as `payload` field and return it
 * @param payload
 * @returns payload
 */
export const logThru = <A>(payload: A, message?: string): A => {
  if (message == null) {
    logger.info({ payload });
  } else {
    logger.info({ payload }, message);
  }
  return payload;
};
