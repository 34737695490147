import type { AppProps } from 'next/app';
import { appWithTranslation } from 'next-i18next';
import { i18nConfig } from './next-i18next.config';

export function withTranslations(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.ComponentType<AppProps<any>>,
): ReturnType<typeof appWithTranslation> {
  return appWithTranslation(component, i18nConfig);
}
