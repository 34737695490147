/* eslint-disable */
/* This is a generated file (see scripts/schema.js), don't edit manually */
export const facilitySchemaFields = [
  {
    "name": "id",
    "type": "STRING",
    "description": "Pepper Facility ID"
  },
  {
    "name": "name",
    "type": "STRING",
    "description": "Name of the facility"
  },
  {
    "name": "officialName",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "officialName of the facility"
  },
  {
    "name": "facilityType",
    "type": "STRING",
    "description": "Enum: 'Store'/'Kitchen'"
  },
  {
    "name": "status",
    "type": "STRING",
    "description": "Enum: 'ACTIVE'/'INACTIVE'"
  },
  {
    "name": "countryCode",
    "type": "STRING",
    "description": "ISO 2 letter Country Code"
  },
  {
    "name": "timezone",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Local timezone of facility, follows 'Area/Location' format"
  },
  {
    "name": "location",
    "type": "RECORD",
    "mode": "NULLABLE",
    "fields": [
      {
        "name": "city",
        "type": "STRING",
        "mode": "NULLABLE",
        "description": "City name of the facility"
      }
    ]
  },
  {
    "name": "ordersManagement",
    "type": "RECORD",
    "mode": "NULLABLE",
    "fields": [
      {
        "name": "announcement",
        "type": "STRING",
        "mode": "NULLABLE",
        "description": "Announcement text used in Orders Dashboard"
      },
      {
        "name": "usePickupStation",
        "type": "BOOLEAN",
        "mode": "NULLABLE",
        "description": "If facility is using pickup station or windows"
      }
    ]
  },
  {
    "name": "createdAt",
    "type": "TIMESTAMP",
    "description": "When facility was added in Pepper"
  },
  {
    "name": "updatedAt",
    "type": "TIMESTAMP",
    "description": "When facility was last updated in Pepper. Is the closing date if status is INACTIVE"
  },
  {
    "name": "exportedAt",
    "type": "TIMESTAMP",
    "description": "When Facility was last exported from Pepper"
  }
] as const;
export type FacilitySchema = typeof facilitySchemaFields;