import { Theme } from '@chakra-ui/react';
import { colors } from './colors';

export const styles: Theme['styles'] = {
  /**
   * @see https://gist.github.com/dmurawsky/d45f068097d181c733a53687edce1919
   */
  global: {
    body: {
      bg: 'gray.50',
      color: colors.pepperBlack,
    },
    'body > .form-select__menu-portal': {
      zIndex: 'modal',
    },
    '.fullscreen-enabled': {
      background: 'gray.50',
    },
    'html, body, div#__next, div#__next > div, div#__next > div > div': {
      height: '100%',
      display: 'flex',
      flexGrow: 1,
    },
  },
};
