import { useCallback } from 'react';
import { useCookies } from 'react-cookie';

// @see https://httpwg.org/http-extensions/draft-ietf-httpbis-rfc6265bis.html#name-the-max-age-attribute
export const MAX_COOKIE_AGE = 400 * 24 * 60 * 60; // 400 days

export const useCookie = <T extends string = string>(
  cookieName: string,
  defaultVal?: T,
): [
  T,
  (
    val: T | null,
    options?: {
      domain?: string;
      expires?: Date;
      maxAge?: number;
    },
  ) => void,
] => {
  const [cookies, setCookieFn, removeCookie] = useCookies([cookieName]);

  const setCookie = useCallback(
    (val: string | null, options?: object) => {
      if (val === null) {
        removeCookie(cookieName, {
          ...options,
          path: '/',
          sameSite: 'strict',
        });
      } else {
        setCookieFn(cookieName, val, {
          ...options,
          path: '/',
          sameSite: 'strict',
        });
      }
    },
    [cookieName, setCookieFn, removeCookie],
  );

  return [cookies[cookieName] ?? defaultVal, setCookie];
};
